import styled from "styled-components";

export const AddAddressStyled = styled.div`
  .add-address-modal-header {
    h2 {
      font-family: "BentonSansBold";
      font-size: 20px;
      font-weight: 500;
      line-height: 27.24px;
    }
    svg {
      cursor: pointer;
    }
  }
  input,
  select {
    height: 44px;
  }
  label {
    font-family: "BentonSansRegular";
    font-size: 14px;
    font-weight: 400;
    line-height: 14.14px;
  }
  .phone-control-fields-sec-left {
    width: 50%;
  }

  .phone-control-fields-sec {
    gap: 24px;
  }
  .primary-checkbox {
    display: flex;
    align-items: center;
    label {
      margin-top: 3px;
      font-family: "BentonSansRegular";
      font-size: 14px;
      font-weight: 400;
      line-height: 14.14px;
      margin-left: 12px;
    }
    input {
      width: 24px;
      height: 24px;
    }
  }
  .add_address_form_btn {
    margin-top: 24px;
    justify-content: end;
    gap: 16px;
    .btn {
      font-family: "BentonSansMedium";
      font-size: 16px;
      font-weight: 400;
      line-height: 21.6px;
      min-width: 175px;
      color: #000;
      border: 1px solid #111111;
      min-height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .btn-primary {
      color: #ffffff;
      background: #000;
    }
    .btn-outline-primary:hover {
      background: #000;
      color: #fff;
    }
  }
  @media (max-width: 767px) {
    .add_address_form_btn {
      flex-direction: column;
    }
  }
`;
