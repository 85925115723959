import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";

import AccountSlideBar from "../../components/AccountSlideBar";
import PurchasesListcontent from "../../components/PurchasesListcontent";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import moment from "moment-timezone";

import "./css/Purchases.css";
import { getcurrenciesCall } from "./../../library/Requests";
import {
  getcustomerpurchasedbidsCall,
  getPaymentDetailsCall,
} from "./../../library/Requests/MyAccount";
import { LOGINID } from "./../../Scenes/common";
import Loader from "./../..//components/Common/Loader";
import { triggerMixpanelEvent } from "./../../Scenes/common";
import { convertSelectedCountryCurrency } from "../../utils";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
const MyPurchases = () => {
  const [currenciesList, setCurrenciesList] = useState([]);
  const [MyPurchasesLots, setMyPurchasesLots] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const { selectedSecondaryCurrency } = useSelector((state: any) => state);

  useEffect(() => {
    if (LOGINID) {
      getcurrenciesDataCall();
      getDataonLoad();
      triggerMixpanelEvent("purchases_page");
      document.title = "FAHH-myPurchases";
    } else {
      history.push(`/?returnUrl=${window.location?.pathname}`);
    }
  }, []);

  const getcurrenciesDataCall = async () => {
    const resp = await getcurrenciesCall();

    setCurrenciesList(resp?.data);
  };
  const getDataonLoad = async () => {
    setIsLoading(true);
    const resp = await getcustomerpurchasedbidsCall(LOGINID);
    setMyPurchasesLots(resp?.data);
    setIsLoading(false);
  };
  const makePaymentOrder = (id: any) => {
    history.push(`/itemPurchase/${id}`);
  };

  return (
    <>
      <div className="purchases_page_content account_all_page">
        {isLoading && <Loader />}
        <Container>
          <Row className="purchases_page_left_right_content">
            <AccountSlideBar />
            <Col lg={9}>
              <div className="purchases_page_right_content account_all_right_content  d-lg-block">
                <div className="purchases_page_active_bid account_all_right_section  purchase_right_section pb-0">
                  <div className="account_all_right_content_heading header_text_page">
                    <h4 className="mb-0 email-preferences-content-page-h3">
                      Purchases
                    </h4>
                  </div>
                  <div className="all-purchases-boxes-sec">
                    {(MyPurchasesLots || []).map((data: any, index: any) => {
                      return (
                        <>
                          {data?.items?.length > 0 && (
                            <div className="PurchaseDiv">
                              <div className="headingtext headingtext-header">
                                <p className="OrderIdText">
                                  Order ID: #{data?.orderNo}
                                </p>
                                <p className="DateText">
                                  {moment(data?.ordredDate).format(
                                    "MMMM D, YYYY"
                                  )}{" "}
                                  {/* {`XXXX${data?.orderId}`} */}
                                </p>
                                <p className="PaymentStatus">
                                  <span className="StatusText">Status:</span>{" "}
                                  {data?.orderStatus}
                                </p>
                              </div>
                              <div className="auction-mypur-auction-title">
                                <h4>Auction: {data?.collectionTitle} </h4>
                              </div>
                              {data?.items?.map((obJD: any, index1: any) => {
                                const ccIndex1 = index1 + 1;
                                return (
                                  <div className="ImageStatusDiv1">
                                    <div className="ImageStatusDiv">
                                      <div className="image-status-div-left">
                                        <Card className="ImageCardDiv">
                                          <img
                                            src={obJD?.imageUrl}
                                            className="paymentItemImage"
                                          />
                                        </Card>
                                        <div className="DetailsOfImages">
                                          <p className="PurchaseNameText">
                                            {obJD?.collectionItemTitle}
                                          </p>
                                          <p className="LotSoldUsdText mb-1">
                                            <span className="LotSoldText">
                                              Lot Sold:{" "}
                                            </span>
                                            {obJD?.amount?.toLocaleString()}{" "}
                                            {obJD?.currency}
                                          </p>
                                          <div className="sale-amount-text green-color-amount-text mt-1">
                                            {convertSelectedCountryCurrency(
                                              obJD?.amount,

                                              selectedSecondaryCurrency
                                            )}{" "}
                                            {selectedSecondaryCurrency?.base_code !==
                                              "USD" &&
                                              selectedSecondaryCurrency?.base_code}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="PaymentStatusDiv">
                                        {/* index === 0 && */}
                                        {data?.orderStatus ==
                                          "Waiting for Payment" &&
                                          ccIndex1 === data?.items?.length && (
                                            <Button
                                              onClick={() => {
                                                makePaymentOrder(data?.orderId);
                                              }}
                                              className="MakePaymentBtn"
                                            >
                                              MAKE PAYMENT
                                            </Button>
                                          )}
                                        {/* <Button className="DownloadInvoiceBtn">
                                Download Invoice
                              </Button> */}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </>
                      );
                    })}
                    {MyPurchasesLots?.length === 0 && !isLoading && (
                      <div className="noRecordsData">
                        You have not made any purchases in the last 12 months.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default MyPurchases;
