const DefaultProfileImage = ({ className, firstName, lastName }: any) => {
  const uName = firstName;
  const lName = lastName || uName?.split(" ")[1];
  return (
    <>
      <div className={`first-letter-text ${className}`}>
        {uName?.length > 0 ? uName[0] : ""}
        {""}
        {lName?.length > 0 ? lName[0] : ""}
      </div>
    </>
  );
};

export default DefaultProfileImage;
