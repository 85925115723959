import React, { useEffect, useRef, useState } from "react";
import "./css/DescriptionTabs.css";
import moment from "moment-timezone";
import { Tabs as AntdTabs } from "antd";
import MoreCollectionColum from "../MoreCollectionColum";
import { isMobile } from "react-device-detect";

interface Props {
  lotData: any;
  collectionItems?: any;
  currenciesList?: any;
  collectionData?: any;
  gotoBigPage?: any;
}
export const DescriptionTabs: React.FC<Props> = (Props) => {
  const [isSticky, setSticky] = useState(false);
  const [activeTab, setActiveTab] = useState("description-sec");
  const {
    lotData,
    collectionItems,
    currenciesList,
    collectionData,
    gotoBigPage,
  } = Props;
  const createMarkup = (data: any) => {
    return { __html: data };
  };
  const locD = lotData?.itemLogisticInfos?.[0];
  const locaAdd = locD?.itemCity
    ? `${locD?.itemCity}, ${locD?.itemState}, ${locD?.itemCountry}${
        locD?.itemPostalCode ? `, ${locD?.itemPostalCode}` : ""
      }`
    : "";

  const descriptionRef = useRef<HTMLDivElement>(null);
  const detailsRef = useRef<HTMLDivElement>(null);
  const conditionReportRef = useRef<HTMLDivElement>(null);
  const otherdetailsRef = useRef<HTMLDivElement>(null);
  const moreFromRef = useRef<HTMLDivElement>(null);

  const handleTabClick = (key: string) => {
    const sectionRefs: { [key: string]: React.RefObject<HTMLDivElement> } = {
      "description-sec": descriptionRef,
      "destails-sec": detailsRef,
      "condition-report-sec": conditionReportRef,
      "other-details-sec": otherdetailsRef,
      "more-from-sec": moreFromRef,
    };
    const targetRef = sectionRefs[key];
    if (targetRef?.current) {
      const offset = targetRef.current.offsetTop - (isSticky ? 100 : 100);
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const navbar = document.getElementById("lot-sticky-tabs");

    const handleScroll = () => {
      if (navbar) {
        const stickyOffset = navbar.offsetTop;

        if (window.pageYOffset - 130 >= stickyOffset) {
          setSticky(true);
        } else {
          setSticky(false);
        }
      }

      const scrollPosition = window.scrollY;
      const sectionRefs: { [key: string]: React.RefObject<HTMLDivElement> } = {
        "description-sec": descriptionRef,
        "destails-sec": detailsRef,
        "condition-report-sec": conditionReportRef,
        "other-details-sec": otherdetailsRef,
        "more-from-sec": moreFromRef,
      };

      Object.keys(sectionRefs).forEach((key) => {
        const currentSection = sectionRefs[key];
        if (currentSection?.current) {
          const sectionTop = currentSection.current.offsetTop - 200;
          const sectionBottom =
            sectionTop + currentSection.current.offsetHeight;
          if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
            setActiveTab(key);
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSticky]);

  const items = [
    {
      key: "description-sec",
      label: "Description",
    },
    {
      key: "destails-sec",
      label: "Details",
    },
    {
      key: "condition-report-sec",
      label: "Condition Report",
    },
    {
      key: "other-details-sec",
      label: "Other Details",
    },
    {
      key: "more-from-sec",
      label: "More From",
    },
  ];

  console.log(collectionItems, "collectionItems");
  return (
    <>
      <div className="description_tabs_content">
        <div className="tabs-content-section-view-main">Lot Details</div>

        <div className="lot-sticky-sec">
          {!isMobile && (
            <>
              <AntdTabs
                activeKey={activeTab}
                onChange={handleTabClick}
                items={items}
                // className="profile-tabs"
                tabPosition="right"
                id="lot-sticky-tabs"
                className={`tabs-container profile-tabs ${
                  isSticky ? "sticky" : ""
                }`}
                style={{
                  position: isSticky ? "fixed" : "relative",
                  top: isSticky ? 200 : "auto",
                  zIndex: 1000,
                  backgroundColor: "#fff",
                }}
              />
              {isSticky && <div></div>}
            </>
          )}

          <div>
            <div id={"description-sec"} ref={descriptionRef}>
              <div className="tabs-content-section-view">Description</div>
              <div className="lot-page-description1">
                <div
                  dangerouslySetInnerHTML={createMarkup(lotData?.description)}
                />
              </div>
            </div>
            <div id="destails-sec" ref={detailsRef}>
              <div className="tabs-content-section-view">Details</div>
              {lotData?.makerName?.length > 0 && (
                <>
                  <div className="tabs-content-section-view">
                    Artist/Designer
                  </div>
                  <div>{lotData?.makerName}</div>
                </>
              )}
              {lotData?.itemProvenances?.length > 0 && (
                <>
                  <div className="tabs-content-section-view">Provenance</div>
                  {lotData?.itemProvenances?.map((Itm: any) => {
                    return <div>{Itm?.provenanceOwner}</div>;
                  })}
                </>
              )}
            </div>
            <div
              id={"condition-report-sec"}
              ref={conditionReportRef}
              className="mt-5"
            >
              <div className="tabs-content-section-view">Condition Report</div>
              {lotData?.conditionalReport && (
                <div className="lot-page-description1">
                  <div
                    dangerouslySetInnerHTML={createMarkup(
                      lotData?.conditionalReport
                    )}
                  />
                </div>
              )}
            </div>

            <div id="other-details-sec" ref={otherdetailsRef} className="mt-5">
              <div className="tabs-content-section-view">Other Details</div>
              <ul className="other-items-list-by-sec">
                {lotData?.periodOfWork && (
                  <li>
                    <div>
                      <div className="other-item-key-val">
                        {" "}
                        Date<span className="d-none">:</span>{" "}
                      </div>{" "}
                      <div className="other-item-collen-val">:</div>{" "}
                      <div>
                        {" "}
                        {moment(lotData?.periodOfWork).format("YYYY")}{" "}
                      </div>
                    </div>
                  </li>
                )}
                {lotData?.restrictedMaterialDesc && (
                  <li>
                    <div>
                      <div className="other-item-key-val">
                        {" "}
                        Place of Origin<span className="d-none">:</span>
                      </div>{" "}
                      <div className="other-item-collen-val">:</div>{" "}
                      <div className="other-item-collen-val-val">
                        {lotData?.restrictedMaterialDesc}
                      </div>{" "}
                    </div>
                  </li>
                )}
                {/* <li>
            <div>
              <div className="other-item-key-val">Artist/Designer</div>{" "}
              <div className="other-item-collen-val">:</div>{" "}
              <div>{lotData?.makerName}</div>{" "}
            </div>
          </li> */}
                {lotData?.material && (
                  <li>
                    <div>
                      <div className="other-item-key-val">
                        Medium/Material<span className="d-none">:</span>
                      </div>{" "}
                      <div className="other-item-collen-val">:</div>{" "}
                      <div className="other-item-collen-val-val">
                        {lotData?.material}
                      </div>{" "}
                    </div>
                  </li>
                )}

                <li>
                  <div>
                    <div className="other-item-key-val">
                      Location<span className="d-none">:</span>
                    </div>{" "}
                    <div className="other-item-collen-val">:</div>{" "}
                    <div className="other-item-collen-val-val">{locaAdd}</div>{" "}
                  </div>
                </li>
              </ul>
            </div>
            <div ref={moreFromRef} id="more-from-sec">
              <MoreCollectionColum
                collectionItems={collectionItems}
                currenciesList={currenciesList}
                collectionData={collectionData}
                gotoBigPage={gotoBigPage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DescriptionTabs;
