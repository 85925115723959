import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Button } from "react-bootstrap";
import moment from "moment";
import { LOGINID } from "../../Scenes/common";

import filterListImg from "../../assets/image/slide1.jpg";
import bidBlack from "../../assets/image/bid-black.png";
import bidWhite from "../../assets/image/bid-white.png";
import noImage from "../../assets/image/noImage.jpg";
import PrevBidDetails from "../../components/PrevBidDetails/PrevBidDetails";

import "./css/BrowseTabGridContent.css";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { followeddata } from "../../actions";
import { getbusinessuserbyidCall } from "../../library/Requests";
import { convertSelectedCountryCurrency } from "../../utils";

interface Props {
  currenciesList?: any;
  collectionData?: any;
  collectionItems?: any;
  followBid?: any;
  unFollowBid?: any;
  isPastAuction?: any;
  onHovering?: any;
  isLoading?: any;
  bannerTimeZone?: any;
  registertoBid?: any;
  handleOpenModal?: any;
  registerButon?: any;
}

export const BrowseTabGridContent: React.FC<Props> = (Props) => {
  const { selectedSecondaryCurrency } = useSelector((state: any) => state);
  const [showPrevBids, setShowPrevBids] = useState(false);
  const [selectedLotId, setSelectedLotId] = useState("");

  const { collectionData } = Props;
  let currencyName = Props?.currenciesList.find(
    (i: any) => collectionData?.currency == i?.id
  )?.currencyCode;

  useEffect(() => {
    // handleRegisterCheck();
  }, []);

  const getmainImage = (item: any) => {
    if (item?.itemImages && item?.itemImages.length > 0) {
      let itemURRL = item?.itemImages.find(
        (i: any) => i.displayOrder == 1 && !i.deleted
      )?.vurtualPath;
      if (
        itemURRL === "string" ||
        itemURRL === null ||
        itemURRL === undefined ||
        itemURRL === ""
      ) {
        return noImage;
      } else {
        return itemURRL;
      }
    }
  };
  const handleShowPrevBids = (count: any, lId: any) => {
    if (count > 0) {
      setSelectedLotId(lId);
      setShowPrevBids(true);
    }
  };
  const hideModal = () => {
    setShowPrevBids(false);
  };

  const handleChecktime = (time: any) => {
    const expiresOnDate = new Date(time);
    // Get the current date and time
    const currentDate = new Date();

    // Check if the expiresOnDate is less than the current date
    if (currentDate > expiresOnDate) {
      return true;
    } else {
      return false;
    }
  };

  const handleCheckLocaltime = (time: any) => {
    if (time) {
      const formatedTime = moment.utc(time).local().format("YYYY-MM-DD HH:mm");

      const expiresOnDate = new Date(formatedTime);

      // Get the current date and time
      const currentDate = new Date();

      if (currentDate < expiresOnDate) {
        return true;
      } else {
        return false;
      }
    }
  };

  console.log(
    collectionData?.extentedExpiresOnUtc,
    "collectionData?.extentedExpiresOnUtc"
  );

  return (
    <>
      <div className="browse_listing_right_tab_inner_content">
        <PrevBidDetails
          ShowPrevBids={showPrevBids}
          hideModal={hideModal}
          lotId={selectedLotId}
          currencyName={currencyName}
          collectionData={collectionData}
        />
        <div className="browse_listing_inner_content">
          {Props?.collectionItems?.length > 0 ? (
            <Row className="filter_list_data_column">
              {(Props?.collectionItems || []).map((item: any, index: any) => {
                const currentBid1 =
                  item?.currentBid || item?.initialBiddingPrice;

                return (
                  <Col className="data_column_box grid-view-com" md={4} sm={6}>
                    <div className="lot_number d-inline-block">
                      Lot {item.sno}
                    </div>
                    <div className="lots_slider_img lot-page-border">
                      <Link to={`/lotDetails/${item.id}`}>
                        <img
                          src={getmainImage(item)}
                          alt="filterListImg"
                          className="w-100"
                        />
                      </Link>
                    </div>
                    <div className="lots_slider_cont_details">
                      <h3 className="makers-name">{item?.makerName}</h3>
                      <Link to={`/lotDetails/${item.id}`}>
                        <h4 className="lineClampLot">{item?.title}</h4>
                      </Link>
                      {item?.bidsCount > 0 &&
                        item?.status !== 6 &&
                        item?.status !== 7 &&
                        item?.myBidsCount > 0 && (
                          <>
                            {!item?.outFromBid ? (
                              <div className="card-box-top-right-new card-box-top-right-new-cd green-colo-new">
                                You Have the Highest Bid
                              </div>
                            ) : (
                              <div className="card-box-top-right-new card-box-top-right-new-cd">
                                You Have Been Outbid
                              </div>
                            )}
                          </>
                        )}
                      {!item?.myBidsCount &&
                        !handleCheckLocaltime(collectionData?.startOnUtc) &&
                        item?.status !== 6 &&
                        item?.status !== 7 && (
                          <div className="bidding-open-text">Bidding Open</div>
                        )}
                      {handleCheckLocaltime(collectionData?.startOnUtc) ? (
                        <div className="bidding-not-started">
                          Bidding Not Started
                        </div>
                      ) : null}

                      <div className="lots_cost lots_cost_lat lots_cost_lat_coll_details">
                        {item?.status === 6 ? (
                          <>
                            <div className="bidding-not-started">
                              Bidding is Closed
                            </div>
                            {LOGINID && (
                              <>
                                <span className="d-block lotDetailsTextRightSide">
                                  Lot Sold:
                                </span>
                                <span className="strong d-block">
                                  {item?.soldFor?.toLocaleString()}{" "}
                                  {currencyName}
                                </span>
                                <div className="sale-amount-text green-color-amount-text mt-1">
                                  {convertSelectedCountryCurrency(
                                    item?.soldFor,
                                    selectedSecondaryCurrency
                                  )}{" "}
                                  {selectedSecondaryCurrency?.base_code !==
                                    "USD" &&
                                    selectedSecondaryCurrency?.base_code}
                                </div>
                              </>
                            )}
                          </>
                        ) : item?.status === 7 ? (
                          <div className="d-block bidding-not-started">
                            Bidding is Closed
                          </div>
                        ) : (
                          <>
                            <span className="d-block lotDetailsTextRightSide lot-currentbid-text">
                              {handleChecktime(collectionData?.startOnUtc)
                                ? "Current Bid: "
                                : "Starting Bid: "}{" "}
                              {handleChecktime(collectionData?.startOnUtc) ? (
                                <span className="d-block">
                                  {" "}
                                  <span className="grid-price-block1 mr-1">
                                    {currentBid1?.toLocaleString()}{" "}
                                    {currencyName}
                                  </span>
                                  {` (`}
                                  <span
                                    className={`bids-count-no  ${
                                      item?.bidsCount > 0 && "bids-count"
                                    }`}
                                    onClick={() => {
                                      handleShowPrevBids(
                                        item?.bidsCount,
                                        item?.id
                                      );
                                    }}
                                  >
                                    {item?.bidsCount}{" "}
                                    {item?.bidsCount == 1 ? "Bid" : "Bids"}
                                  </span>
                                  , Reserve
                                  {item?.lotMetReservice ? "" : " Not"} Met{`)`}
                                </span>
                              ) : (
                                <span className="d-block">
                                  <span className="grid-price-block1 mr-1">
                                    {currentBid1?.toLocaleString()}{" "}
                                    {currencyName}
                                    {/* {highestBidDetails?.amount?.toLocaleString()} */}
                                  </span>
                                  (Reserve, Yes)
                                </span>
                              )}
                            </span>
                            <div className="sale-amount-text green-color-amount-text">
                              {convertSelectedCountryCurrency(
                                currentBid1,
                                selectedSecondaryCurrency
                              )}{" "}
                              {selectedSecondaryCurrency?.base_code !== "USD" &&
                                selectedSecondaryCurrency?.base_code}
                            </div>
                            <div
                              className={
                                collectionData?.onlineEndDate
                                  ? "lotCloseContainer"
                                  : ""
                              }
                            ></div>
                          </>
                        )}
                      </div>

                      <div className="price_list_btn d-flexjustify-content-between lot-grid-view-sec-estimate-follow">
                        <div className="lots_cost ">
                          <span className="d-block">{`Estimate:`}</span>
                          <span className=" grid-price-block d-block">
                            <p className="mb-1">
                              {item?.estimatedPrice?.toLocaleString()}{" "}
                              {currencyName}
                              {item?.estimatedPriceTo && (
                                <>
                                  {" "}
                                  - {item?.estimatedPriceTo?.toLocaleString()}{" "}
                                  {currencyName}
                                </>
                              )}
                            </p>
                            <div className="sale-amount-text green-color-amount-text mb-3">
                              {convertSelectedCountryCurrency(
                                item?.estimatedPrice,
                                selectedSecondaryCurrency
                              )}{" "}
                              {selectedSecondaryCurrency?.base_code !== "USD" &&
                                selectedSecondaryCurrency?.base_code}
                              {item?.estimatedPriceTo && (
                                <>
                                  {" "}
                                  {selectedSecondaryCurrency?.base_code &&
                                    selectedSecondaryCurrency?.base_code !==
                                      "USD" &&
                                    " - "}
                                  {convertSelectedCountryCurrency(
                                    item?.estimatedPriceTo,
                                    selectedSecondaryCurrency
                                  )}{" "}
                                  {selectedSecondaryCurrency?.base_code !==
                                    "USD" &&
                                    selectedSecondaryCurrency?.base_code}
                                </>
                              )}
                            </div>
                          </span>
                        </div>

                        {(item?.status === 6 || item?.status === 7) && (
                          <div className="bidding-date-closed-text">
                            {moment
                              .utc(item?.extentedExpiresOnUtc)
                              .tz("America/New_York")
                              .format("MMMM D, YYYY [(]h:mm A")}{" "}
                            {"ET)"}
                          </div>
                        )}

                        {item?.status !== 6 && item?.status !== 7 ? (
                          <div className="bidding-start-text mb-3">
                            <span className="">
                              {!handleCheckLocaltime(collectionData?.startOnUtc)
                                ? "Lot Begins Closing: "
                                : "Bidding Starts: "}
                            </span>

                            {!handleCheckLocaltime(
                              collectionData?.startOnUtc
                            ) ? (
                              <span className="strong fullWidth textSize">
                                {moment
                                  .utc(item?.expiresOnUtc)
                                  .tz("America/New_York")
                                  .format("MMMM D, YYYY [(]h:mm A")}{" "}
                                {"ET)"}
                              </span>
                            ) : (
                              <span className="strong fullWidth textSize">
                                {moment
                                  .utc(collectionData?.startOnUtc)
                                  .tz("America/New_York")
                                  .format("MMMM D, YYYY [(]h:mm A")}{" "}
                                {"ET)"}
                              </span>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {Props?.isPastAuction && (
                      <div className="btn_lots btn_lots_font_issue ">
                        {item?.ifollowed ? (
                          <Button
                            onClick={() => {
                              Props?.unFollowBid(item?.id);
                            }}
                            className="lots_btn_same onHover d-inline-flex followingThisBid text-center align-items-center text-decoration-none"
                          >
                            FOLLOWING
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              Props?.followBid(item?.id);
                            }}
                            className="lots_btn_same btnHover d-inline-flex text-center bg-white align-items-center text-decoration-none"
                          >
                            FOLLOW
                          </Button>
                        )}
                      </div>
                    )}
                    {!Props?.isPastAuction && (
                      <div className="btn_lots btn_lots_font_issue ">
                        {item?.ifollowed ? (
                          <Button
                            onClick={() => {
                              Props?.unFollowBid(item?.id);
                            }}
                            className="lots_btn_same onHover d-inline-flex followingThisBid text-center align-items-center text-decoration-none"
                          >
                            FOLLOWING
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              Props?.followBid(item?.id);
                            }}
                            className="lots_btn_same btnHover d-inline-flex text-center bg-white align-items-center text-decoration-none"
                          >
                            FOLLOW
                          </Button>
                        )}
                        {/* {isMobile ? (
                              ""
                            ) : ( */}

                        <>
                          {collectionData?.registredToCollection ||
                          Props.registerButon ? (
                            <div
                              onMouseEnter={() =>
                                Props?.onHovering(item?.id, true)
                              }
                              onMouseLeave={() =>
                                Props?.onHovering(item?.id, false)
                              }
                              className={`lots_btn_same btnHover d-inline-flex text-center bg-white align-items-center text-decoration-none
                                  ${
                                    handleChecktime(collectionData?.startOnUtc)
                                      ? "registered-button-text bid-button-collec"
                                      : "registered-button-text"
                                  }
                                  `}
                            >
                              <>
                                {!handleCheckLocaltime(
                                  collectionData?.startOnUtc
                                ) ? (
                                  <span
                                    onClick={() => {
                                      window.location.href = `/lotDetails/${item.id}`;
                                    }}
                                  >
                                    <img
                                      src={bidWhite}
                                      className="bid_icon mr-1 "
                                    />
                                    BID
                                  </span>
                                ) : (
                                  <span>REGISTERED</span>
                                )}
                              </>
                            </div>
                          ) : (
                            <div
                              onMouseEnter={() =>
                                Props?.onHovering(item?.id, true)
                              }
                              onMouseLeave={() =>
                                Props?.onHovering(item?.id, false)
                              }
                              className={`lots_btn_same btnHover d-inline-flex text-center bg-white align-items-center text-decoration-none`}
                            >
                              <span
                                onClick={() => {
                                  Props.handleOpenModal();
                                }}
                              >
                                REGISTER TO BID
                              </span>
                            </div>
                          )}
                        </>
                      </div>
                    )}
                  </Col>
                );
              })}
            </Row>
          ) : !Props?.isLoading ? (
            <Row>
              <div className="no-results-sec-coll-details">
                Oops! No results found. Please try adjusting your search or
                filters and try again.
              </div>
            </Row>
          ) : null}
          {/* <div className="load_more_list_btn text-center">
            <Link to="/collectionDetails/1" className="text-white d-inline-block text-decoration-none">Load More</Link>
          </div> */}
        </div>
      </div>
    </>
  );
};
export default BrowseTabGridContent;
