import { Button, Modal } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "../../assets/close-icon.svg";
import { BidRegistrationStyled } from "./BidRegisrationStyled.styled";
import "./bidRegis.css";
import { useEffect, useState } from "react";
import CustomModal from "../CustomModel";
import RegisterToBid from "../RegisterToBid";
import { bidregistrationCall } from "../../library/Requests/PlaceBid";
import { triggerMixpanelEvent } from "../../Scenes/common";
import { useDispatch, useSelector } from "react-redux";
import {
  afterLoginTriggerPageCall,
  loginSuccessData,
  LopinPopUpPage,
} from "../../actions";
import BidSuccessModal from "./BidSuccessModal";
import LoginNewModal from "./LoginNewModal";
import {
  getAllActiveAuctions,
  getbusinessuserbyidCall,
} from "../../library/Requests";

const BidRegistrationModal = (props: any) => {
  const { userAddress, user } = useSelector((state: any) => state);
  const {
    showRegistrationModal,
    setShowRegistrationModal,
    collectionId,
    setRegisterButon,
    toPage,
  } = props;
  const [warningModelPopup, setWarningModelPopup] = useState(false);
  const [uploadId, setUploadId] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [userRejectedAddressPopup, setUserRejectedAddressPopup] =
    useState(false);
  const [registerToBidPopUp, setRegisterToBidPopUp] = useState(false);
  const [errorModelPopup, setErrorModelPopup] = useState(false);
  const [errorTextData, setErrorTextData] = useState("");
  const [registerButtonData, setRegisterButtonData]: any = useState({
    ...collectionId,
  });
  const [registerBidSuccess, setRegisterBidSuccess] = useState(false);

  const [bidMySelf, setBidMySelf] = useState(false);
  const [bidForSomeOne, setBidForSomeOne] = useState(false);
  const [conditionsCheck, setConditionCheck] = useState(false);
  const [paddleNumber, setPaddleNumber] = useState("");
  const [data, setData] = useState(() => {
    // Initial data from local storage
    return JSON.parse(localStorage.getItem("customerInfoDetails")!);
  });

  const dispatch = useDispatch();

  useEffect(() => {
    // Function to handle storage change
    const handleStorageChange = () => {
      setData(JSON.parse(localStorage.getItem("customerInfoDetails")!) || null);
    };

    // Listen to local storage changes
    window.addEventListener("storage", handleStorageChange);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  console.log(data, "data");

  useEffect(() => {
    handleUserData();
  }, []);
  useEffect(() => {
    if (data?.id) {
      if (!data?.billingAddressId && !data?.active) {
        setWarningModelPopup(true);
      } else if (!data?.addressProof && !data?.identityProof) {
        setWarningModelPopup(true);
        setUploadId(true);
      } else if (!data?.active && !data?.isVerified) {
        setIsPending(true);
      } else if (!data?.active && data?.isVerified) {
        setUserRejectedAddressPopup(true);
      } else {
        setUserRejectedAddressPopup(false);
        setWarningModelPopup(false);
      }
    }
  }, [data]);

  const registertoBid = async (e: any) => {
    e.preventDefault();
    let userInfo: any = localStorage.getItem("customerInfoDetails");
    const userIn: any = JSON.parse(userInfo);

    if (userIn?.id) {
      dispatch(afterLoginTriggerPageCall({}));
      if (collectionId?.auctionType == 1) {
        registoBidValue("Online");
      } else {
        registoBidValue("Online");
        // this.setState({registerToBidPopUp: true});
      }
    } else {
      const jsonD = {
        pageName: "registerBid",
        lotId: 0,
      };
      dispatch(afterLoginTriggerPageCall(jsonD));
    }
  };

  const registoBidValue = async (value: any) => {
    let userInfo: any = localStorage.getItem("customerInfoDetails");
    const userIn: any = JSON.parse(userInfo);
    if (value != "" && userIn?.id) {
      if (userInfo != undefined && userInfo != null && userInfo != "") {
        if (!userIn?.billingAddressId) {
          //  !userIn?.identityProof ||  !userIn?.addressProof ||
          setWarningModelPopup(true);
        } else if (!data?.addressProof && !data?.identityProof) {
          setWarningModelPopup(true);
        } else if (!userIn?.active && userIn?.isVerified) {
          setUserRejectedAddressPopup(true);
        } else {
          setRegisterToBidPopUp(false);
          const body = {
            bidRegistration: {
              collectionId: collectionId?.id,
              customerId: userIn?.id,
              type: value,
              registred: true,
            },
          };
          const resp = await bidregistrationCall(body);
          setPaddleNumber(resp.id);
          if (resp?.success) {
            triggerMixpanelEvent("bid_registration_success", {
              lotId: collectionId?.id,
            });
            setRegisterBidSuccess(true);
            let obj = { ...registerButtonData, isRegistredToAuction: true };
            setRegisterButtonData(obj);
            if (toPage === "top-banner") {
              setRegisterButon();
            } else {
              setRegisterButon(true);
            }
          } else if (resp?.errors?.length > 0) {
            setErrorModelPopup(true);
            setErrorTextData(resp?.errors[0]);
            triggerMixpanelEvent("bid_registration_error", {
              lotId: collectionId.id,
              error_message: resp?.errors[0],
            });
          } else if (resp?.message) {
            triggerMixpanelEvent("bid_registration_error", {
              lotId: collectionId.id,
              error_message: resp?.message,
            });
            setErrorModelPopup(true);
            setErrorTextData(resp?.message);
          }
          setShowRegistrationModal();
        }
      }
    }
  };

  const gotoUploadPhotoPage = () => {
    let userInfo = localStorage.getItem("customerInfoDetails");
    if (userInfo != undefined && userInfo != null && userInfo != "") {
      const userIn = JSON.parse(userInfo);
      if (!userIn?.billingAddressId) {
        window.location.href = `/sign-up/step2?returnUrl=/collectionDetails/${collectionId.id}`;
      } else {
        window.location.href = `/sign-up/step3?returnUrl=/collectionDetails/${collectionId.id}`;
      }
    }
  };

  const getfullName = (user: any) => {
    let fullname = user?.title ? `${user?.title} ` : "";
    fullname += user?.firstName ? user?.firstName : "";
    fullname += user?.lastName ? ` ${user?.lastName}` : "";
    return fullname;
  };
  const getfullAddress = (user: any) => {
    let fullname = user?.address1 ? user?.address1 : "";
    fullname += user?.address2 ? `, ${user?.address2}` : "";
    fullname += user?.city ? `, ${user?.city}` : "";
    fullname += user?.state ? `, ${user?.state}` : "";
    fullname += user?.county ? `, ${user?.county}` : "";
    fullname += user?.zipPostalCode ? `, ${user?.zipPostalCode}` : "";
    return fullname;
  };

  const handleUserData = async () => {
    const userD = localStorage.getItem("customerInfoDetails");
    if (userD != undefined && userD != null && userD != "") {
      const userIn = JSON.parse(userD);
      if (userIn?.id) {
        const resp = await getbusinessuserbyidCall(userIn?.id);

        if (resp.customer.id) {
          dispatch(loginSuccessData(resp.customer));
        }
      }
    }
  };
  return (
    <>
      {!(warningModelPopup || userRejectedAddressPopup || isPending) && (
        <>
          {!data?.id ? (
            <LoginNewModal
              show={showRegistrationModal}
              onhide={setShowRegistrationModal}
              toPage={toPage}
              collectionId={collectionId}
            />
          ) : (
            <Modal
              show={showRegistrationModal}
              centered
              className="bid-rgisration-new-modal"
            >
              <BidRegistrationStyled>
                <Modal.Header className="bid-regis-modal-body">
                  Select an Account to Bid and Pay
                  <CloseIcon onClick={setShowRegistrationModal} />
                </Modal.Header>
                <Modal.Body>
                  <p className="bid-info-text">
                    Your paddle registers you for bidding and invoicing. The
                    name on payment method must match the account name.{" "}
                    <span>The account cannot be changed.</span>
                  </p>
                  <div className="address-sec">
                    <p className="address-header">
                      Your FineArt HipHop. Account
                    </p>
                    <div>
                      <div>
                        <p className="user-fullname">
                          {getfullName(userAddress)}
                          <span> | {userAddress?.id}</span>{" "}
                        </p>
                        <p className="address-text">
                          {getfullAddress(userAddress)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="declartion-labels">
                    <p>I declare that:</p>
                    <div className="declare-inputs mb-3">
                      <input
                        type="radio"
                        onChange={(e: any) => {
                          setBidMySelf(e.target.checked);
                          setBidForSomeOne(false);
                        }}
                        checked={bidMySelf}
                      />
                      <label htmlFor="">
                        I am bidding for myself or my company
                      </label>
                    </div>
                    <div className="declare-inputs">
                      <input
                        type="radio"
                        onChange={(e: any) => {
                          setBidMySelf(false);
                          setBidForSomeOne(e.target.checked);
                        }}
                        checked={bidForSomeOne}
                      />
                      <label htmlFor="">I am bidding for someone else</label>
                    </div>
                  </div>
                  <div className="conditions-checkbox">
                    <input
                      type="checkbox"
                      onChange={(e: any) => {
                        setConditionCheck(e.target.checked);
                      }}
                    />
                    <label>
                      By checking this box you confirm that you have read and
                      agree to be bound by the{" "}
                      <span>Conditions of Business</span> and any applicable
                      Terms of Guarantee for this bid and any later bids you
                      place on this or any other lot in this sale. Bids placed
                      in Online-Only sales are final and cannot be cancelled
                      except for the limited reasons set out in the Conditions
                      of Business.
                    </label>
                  </div>
                  <div className="footer-butns">
                    <Button
                      className="cancel-butn"
                      onClick={setShowRegistrationModal}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={
                        !(conditionsCheck && (bidMySelf || bidForSomeOne))
                      }
                      className="acc-btn"
                      onClick={registertoBid}
                    >
                      use this Account
                    </Button>
                  </div>
                </Modal.Body>
              </BidRegistrationStyled>
            </Modal>
          )}
        </>
      )}
      <CustomModal
        show={warningModelPopup}
        className="identify-verification-modal"
        titleText="Submit your identification"
        subTitleText="In order to place a bid on a lot in this auction, please complete FAHH’s verification process."
        deleteText={`${uploadId ? "UPLOAD ID" : "CONTINUE"}`}
        cancelText={`${uploadId ? "MAY BE LATER" : ""}`}
        onCancel={() => {
          setShowRegistrationModal();
          setWarningModelPopup(false);
        }}
        onDelete={() => {
          setWarningModelPopup(false);
          setShowRegistrationModal();
          gotoUploadPhotoPage();
        }}
      />{" "}
      <CustomModal
        show={isPending}
        // titleText="Submit your identification "
        subTitleText="Your profile is pending verification by the administration. Once approved, you will be able to start bidding"
        deleteText="OKAY"
        className={"edit-address-modal"}
        cancelText=""
        onDelete={() => {
          setIsPending(false);
          setShowRegistrationModal();
        }}
      />{" "}
      <CustomModal
        show={userRejectedAddressPopup}
        // titleText="Submit your identification "
        subTitleText="Your ID proof document has been rejected, It appears that there were issues with the provided ID. To proceed, please resubmit a clear and valid ID image."
        deleteText="REUPLOAD"
        cancelText="OK"
        className={"edit-address-modal"}
        showCancelBtn={true}
        onCancel={() => {
          setUserRejectedAddressPopup(false);
          setShowRegistrationModal();
        }}
        onDelete={() => {
          setShowRegistrationModal();
          setUserRejectedAddressPopup(false);
          gotoUploadPhotoPage();
        }}
      />
      <RegisterToBid
        show={registerToBidPopUp}
        titleText=""
        deleteText="OK"
        cancelText=""
        onCancel={() => {}}
        onRegisterBid={registoBidValue}
      />
      <CustomModal
        show={errorModelPopup}
        titleText={errorTextData}
        deleteText="OK"
        className={"edit-address-modal"}
        cancelText=""
        longText={true}
        onCancel={() => {}}
        onDelete={() => {
          setErrorModelPopup(false);
        }}
      />
      {/* <CustomModal
        show={registerBidSuccess}
        titleText={`You have been successfully registered for the ${
          collectionId?.auctionType === "Online Auction"
            ? "Online Only Auction"
            : "Live Auction"
        }`}
        deleteText="OK"
        cancelText=""
        onCancel={() => {}}
        onDelete={() => {
          setRegisterBidSuccess(false);
        }}
      /> */}
      <BidSuccessModal
        userAddress={userAddress}
        getfullAddress={getfullAddress}
        getfullName={getfullName}
        paddleNumber={paddleNumber}
        setRegisterBidSuccess={setRegisterBidSuccess}
        show={registerBidSuccess}
      />
    </>
  );
};

export default BidRegistrationModal;
