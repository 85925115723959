import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Col, Row, Button } from "react-bootstrap";

import Calend from "../../assets/image/calend.png";
import Share from "../../assets/image/share.png";
import Print from "../../assets/image/print.png";
import Download from "../../assets/image/download.png";
import ShareModal from "../../components/ShareModal/ShareModal";
import { DOMAIN_URL } from "./../../config";
// import moment from 'moment';
import moment from "moment-timezone";
import TopBannerTime from "./../../pages/collectionDetails/TopBannerTime";
import "./css/BrowseListTopBanner.css";
import { convertUTCToLMilleSec, convertLMilleSec } from "./../../Scenes/common";
import { useSelector } from "react-redux";
import BidRegistrationModal from "../BidRegistrationPopUp/BidRegisrationModal";

const BrowseListTopBanner = (props: any) => {
  const { user } = useSelector((state: any) => state);
  const [bannerListOpen, setBannerListOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [collectionId, setCollectionId] = useState("");

  const addToCalendar = () => {
    console.log("this.addToCalendar");
  };
  const handleShareAction = (flag: any) => {
    setShareOpen(flag);
  };
  const {
    collectionData,
    registertoBid,
    gotoAuctionCalenderPage,
    gotoAuctionResultsPage,
    isPastAuction,
    registerButon,
    handleLoginBtn,
    handleOpenModal,
  } = props;

  let bannerTimeZone = props.timezonesList?.find(
    (i: any) => collectionData?.timeZoneId == i?.id
  )?.abbrivation;

  const gmtDateTime = moment.utc(
    collectionData?.startOnUtc,
    "YYYY-MM-DD h:mm:ss a"
  );
  let endtimeLink = "";
  if (collectionData?.endOnUtc && collectionData?.endOnUtc != "") {
    const endtimeLink1 = moment.utc(
      collectionData?.endOnUtc,
      "YYYY-MM-DD h:mm:ss a"
    );
    endtimeLink = `${endtimeLink1.local().format("YYYYMMDD")}T${endtimeLink1
      .local()
      .format("HHMMSS")}`;
  }
  const timesLink = `${gmtDateTime.local().format("YYYYMMDD")}T${gmtDateTime
    .local()
    .format("HHMMSS")}/${endtimeLink}`;

  // console.log("shareOpen", shareOpen);
  const lotPageUrl = `${DOMAIN_URL}collectionDetails/${collectionData?.id}`;
  console.log("top banner top banner");
  const collSLocalTim = convertUTCToLMilleSec(collectionData?.startOnUtc);
  const currentTime = convertLMilleSec();
  let userInfo: any = localStorage.getItem("customerInfoDetails");
  const userIn: any = JSON.parse(userInfo);

  console.log(currentTime > collSLocalTim, "this?.props?.user?.isVerified");

  const handleCloseModal = () => {
    setShowRegistrationModal(false);
  };

  return (
    <>
      <ShareModal
        show={shareOpen}
        handleClose={() => handleShareAction(false)}
        url={lotPageUrl}
        marketplaceId={collectionData?.id}
        title={collectionData?.title}
        onCopyClick={() => {
          navigator.clipboard.writeText(lotPageUrl);
        }}
      />

      {showRegistrationModal && (
        <BidRegistrationModal
          showRegistrationModal={showRegistrationModal}
          setShowRegistrationModal={handleCloseModal}
          collectionId={collectionId}
          toPage={"top-banner"}
          setRegisterButon={props.getRegisterButton}
        />
      )}

      <div className="brows_banner_content">
        <Container>
          <Row className="inner_banner_content">
            <Col lg={9}>
              <div className="brows_banner_left_content">
                <div
                  className="brows_banner_back_btn d-inline-flex align-items-center"
                  onClick={() => {
                    isPastAuction
                      ? gotoAuctionResultsPage()
                      : gotoAuctionCalenderPage();
                  }}
                >
                  <svg
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M7 13L1 7L7 1"
                      stroke="#111111"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {isPastAuction ? "Auction Results" : "Auction Calendar"}
                </div>
                <div className="auctions_details">
                  {collectionData?.title && (
                    <ul className="d-flex p-0 auction-detaials-month-text">
                      <li className="position-relative">
                        {!isPastAuction
                          ? collectionData?.auctionType == 1
                            ? "Online Only"
                            : "Live"
                          : "Past"}{" "}
                        Auction
                      </li>
                      <li className="strong position-relative collection-deatils-date-text">
                        {collectionData?.auctionType == "1" ? (
                          <>
                            {!collectionData?.onlineStartDate
                              ? moment(collectionData?.onlineStartDate).format(
                                  "MMMM D, YYYY"
                                )
                              : moment(collectionData?.onlineStartDate).format(
                                  "MMMM D "
                                )}
                            {collectionData?.onlineEndDate && (
                              <>
                                {` `}-{" "}
                                {moment(collectionData?.onlineEndDate).format(
                                  "MMMM D, YYYY"
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {moment(collectionData?.liveStartDate).format(
                              "MMMM D, YYYY"
                            )}{" "}
                          </>
                        )}
                      </li>
                      {/* <li className="position-relative">
                          {collectionData?.auctionType == "1" ? (
                            <>
                              {moment(
                                collectionData?.onlineStartTime,
                                "HHmmss"
                              ).format("h:mm A")}{" "}
                              {bannerTimeZone === "EST" ? "ET" : bannerTimeZone}{" "}
                            </>
                          ) : (
                            <>
                              {moment(
                                collectionData?.liveStartTime,
                                "HHmmss"
                              ).format("h:mm A")}{" "}
                              {bannerTimeZone === "EST" ? "ET" : bannerTimeZone}{" "}
                            </>
                          )}{" "}
                          <InfoIcon className="bannerInfoIcon" />
                          <div className="hoverInfo">
                            <p className="yourLocalTime">Your Local Time</p>
                            <p> {local_date} </p>
                            <p>
                              {" "}
                              {local_time} {local_timezone}{" "}
                            </p>
                          </div>
                        </li> */}
                    </ul>
                  )}
                  <h3>{collectionData?.title}</h3>
                  <div className="coll-top-banner-time-sec">
                    <TopBannerTime
                      collectionData={collectionData}
                      bannerTimeZone={bannerTimeZone}
                      handleLoginBtn={handleLoginBtn}
                      formPage="collection-page"
                    />
                  </div>

                  {!isPastAuction && collectionData?.title && (
                    <>
                      {collectionData?.registredToCollection ||
                      registerButon ? (
                        <>
                          {currentTime > collSLocalTim ? (
                            ""
                          ) : (
                            <Button
                              disabled
                              className="auction_btn_banner registeredbtns d-inline-block text-decoration-none registered-button"
                            >
                              REGISTERED
                            </Button>
                          )}
                        </>
                      ) : (
                        <>
                          <Button
                            onClick={(e: any) => {
                              e.stopPropagation();
                              setShowRegistrationModal(true);
                              setCollectionId(collectionData);
                            }}
                            className="auction_btn_banner registeredbtns onHoverRegister d-inline-block text-decoration-none"
                          >
                            REGISTER TO BID
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </Col>
            <Col lg={3}>
              <div
                className={`brows_banner_right_content show-srch ${
                  bannerListOpen ? "active" : ""
                }`}
              >
                <div
                  className="mobile_open_browse_list_background"
                  onClick={() => setBannerListOpen(false)}
                ></div>
                <div
                  className="mobile_open_browse_list d-block d-lg-none"
                  onClick={() => setBannerListOpen(false)}
                ></div>
                <ul className="m-0 p-0">
                  <li>
                    <a
                      href={`https://www.google.com/calendar/event?action=TEMPLATE&text=${collectionData?.title}&dates=${timesLink}&details`}
                      className="browse_banner_list text-decoration-none d-flex align-items-center"
                    >
                      <Button
                        onClick={() => {
                          addToCalendar();
                        }}
                        className="browse_banner_list browse_banner_list_AddC text-decoration-none d-flex align-items-center"
                      >
                        <span className="icon_share mr-2 text-center position-relative bg-white rounded-pill">
                          <img src={Calend} className="position-absolute" />
                        </span>
                        <h5 className="mb-0">Add to Calendar</h5>
                      </Button>
                    </a>
                  </li>
                  <li>
                    <Link
                      to={`/collectionDetails/${collectionData?.id}`}
                      className="browse_banner_list text-decoration-none d-flex align-items-center"
                    >
                      <span className="icon_share mr-2 text-center position-relative bg-white rounded-pill">
                        <img
                          onClick={() => {
                            handleShareAction(true);
                          }}
                          src={Share}
                          className="icon_share position-absolute"
                        />
                      </span>
                      <h5
                        onClick={() => {
                          handleShareAction(true);
                        }}
                        className="mb-0"
                      >
                        Share
                      </h5>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/collectionDataPrint/${collectionData?.id}`}
                      className="browse_banner_list text-decoration-none d-flex align-items-center"
                    >
                      <span className="icon_share mr-2 text-center position-relative bg-white rounded-pill">
                        <img
                          src={Print}
                          className="icon_share position-absolute"
                        />
                      </span>
                      <h5 className="mb-0">Print</h5>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/collectionDataDownload/${collectionData?.id}`}
                      target="_blank"
                      className="browse_banner_list text-decoration-none d-flex align-items-center"
                    >
                      <span className="icon_share mr-2 text-center position-relative bg-white rounded-pill">
                        <img
                          src={Download}
                          className="icon_share position-absolute"
                        />
                      </span>
                      <h5 className="mb-0">Download Auction Catalog (PDF)</h5>
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default BrowseListTopBanner;
