import React from "react";
import { Link } from "react-router-dom";

import { Container, Row, Col, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Pass_hide from "./../../assets/password-hide.png";
import Pass_show from "./../../assets/password-show.png";

import { ReactComponent as PasswordCrossIcon } from "./../../assets/image/passwordCross.svg";
import { ReactComponent as PasswordTickIcon } from "./../../assets/image/passwordTick.svg";

interface Props {
  currentStep: any;
  handleChange: any;
  handlePasswordChange: any;
  // email:any;
  errorsFields: any;
  errorsData: any;
  userAccountData: any;
  charLength: any;
  specialChar: any;
  numberLength: any;
  password: any;
  handleValuesSelect: any;
  emailError: any;
  onChangeInputBox?: any;
  passwordType?: any;
  togglePassword?: any;
}

// function Step1(props: any) {
export const Step1: React.FC<Props> = (props: any) => {
  // const [footerCollapseOne, setFooterCollapseOne] = useState(false);
  if (props.currentStep !== 1) {
    return null;
  }
  const {
    errorsFields,
    errorsData,
    userAccountData,
    handleValuesSelect,
    emailError,
    passwordType,
    togglePassword,
  } = props;
  console.log("emailError", emailError);
  return (
    <>
      {/* <label htmlFor="email">Email address</label>
      <input
        className="form-control"
        id="email"
        name="email"
        type="text"
        placeholder="Enter email"
        value={props.email}
        onChange={props.handleChange}
      /> */}
      <Row>
        <Col sm={12} xs={3}>
          <Form.Group>
            <Form.Label>
              Title <span className="asterisk-symbol-red">*</span>
            </Form.Label>
            <Form.Control
              name="title"
              as="select"
              value={userAccountData.title}
              onChange={props.handleChange}
            >
              <option value="Mr."> Mr.</option>
              <option value="Mrs."> Mrs.</option>
              <option value="Miss"> Miss</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md={6} xs={9}>
          <Form.Group>
            <Form.Label>
              First Name<span className="asterisk-symbol-red">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              value={userAccountData.firstName}
              onChange={props.handleChange}
              isInvalid={errorsFields?.firstName}
            />
            <Form.Control.Feedback type="invalid">
              {errorsFields?.firstName ? <>{errorsData?.firstName}</> : null}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group>
            <Form.Label>
              Last Name<span className="asterisk-symbol-red">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              value={userAccountData.lastName}
              onChange={props.handleChange}
              isInvalid={errorsFields?.lastName}
            />
            <Form.Control.Feedback type="invalid">
              {errorsFields?.lastName ? <>{errorsData?.lastName}</> : null}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={12}>
          <Form.Group>
            <Form.Label>
              Email Address<span className="asterisk-symbol-red">*</span>
            </Form.Label>
            <Form.Control
              type="email"
              // id="email"
              name="email"
              value={userAccountData.email}
              onChange={props.handleChange}
              isInvalid={errorsFields?.email}
            />
            <Form.Control.Feedback type="invalid">
              {errorsFields?.email ? <>{errorsData?.email}</> : null}
            </Form.Control.Feedback>
          </Form.Group>
          {emailError != "" && !errorsFields?.email && (
            <div className="emailErrorText">{emailError}</div>
          )}
        </Col>
        {/* <Col md={12}>
          <Form.Group>
            <Form.Label>Confirm Email Address</Form.Label>
            <Form.Control
              type="email"
              name="confirmEmail"
              value={userAccountData.confirmEmail}
              onChange={props.handleChange}
              isInvalid={errorsFields?.confirmEmail}
            />

            <Form.Control.Feedback type="invalid">
              {errorsFields?.confirmEmail ? (
                <>{errorsData?.confirmEmail}</>
              ) : null}
            </Form.Control.Feedback>
          </Form.Group>
          {emailError != "" && (
            <div className="emailErrorText">{emailError}</div>
          )}
        </Col> */}
        <Col md={12}>
          <Form.Group>
            <Form.Label>
              Password<span className="asterisk-symbol-red">*</span>
            </Form.Label>
            <div
              className={
                errorsFields?.password
                  ? "create-password-sec create-password-sec-error"
                  : "create-password-sec"
              }
            >
              <Form.Control
                type={passwordType}
                name="password"
                value={userAccountData.password}
                onChange={props.handleChange}
                isInvalid={errorsFields?.password}
                maxLength={30}
              />
              <div className="input-group-btn">
                <span className="btn" onClick={togglePassword}>
                  {passwordType === "password" ? (
                    <img className="password-hide-img" src={Pass_show} />
                  ) : (
                    <img className="password-hide-img" src={Pass_hide} />
                  )}
                </span>
              </div>
            </div>

            <Form.Control.Feedback type="invalid">
              {errorsFields?.password ? <>{errorsData?.password}</> : null}
            </Form.Control.Feedback>
            {/* <div onClick={() => setFooterCollapseOne(!footerCollapseOne)}>123</div> */}
            <Form.Text className="">
              {/* <Link to="/sign-up" className="close-icon mr-1">
                
              </Link> */}
              <span className="close-icon mr-1">
                {props.charLength ? (
                  <PasswordTickIcon />
                ) : (
                  <PasswordCrossIcon />
                )}
              </span>
              At least 10 characters
            </Form.Text>
            <Form.Text className="">
              <span className="close-icon mr-1">
                {props.numberLength ? (
                  <PasswordTickIcon />
                ) : (
                  <PasswordCrossIcon />
                )}
              </span>
              At least one number
            </Form.Text>
            {/* <Form.Text className="">
              <span className="close-icon mr-1">
                {props.specialChar ? (
                  <PasswordTickIcon />
                ) : (
                  <PasswordCrossIcon />
                )}
              </span>
              One special character(!@#$%^&*_+)
            </Form.Text> */}
          </Form.Group>
        </Col>
        <Col md={12}>
          <Form.Group>
            <Form.Label>
              Confirm Password<span className="asterisk-symbol-red">*</span>
            </Form.Label>
            <Form.Control
              onChange={props.handleChange}
              type="password"
              value={userAccountData.confirmPassword}
              isInvalid={errorsFields?.confirmPassword}
              name="confirmPassword"
              maxLength={30}
            />
            <Form.Control.Feedback type="invalid">
              {errorsFields?.confirmPassword ? (
                <>{errorsData?.confirmPassword}</>
              ) : null}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={12} className="signup_info_text d-md-block ">
          <h5>Marketing Preferences</h5>
          <div className="pt-2">
            <div className="select_address_content select_address_content1">
              <div className="radioWrap">
                <Form.Check
                  type="radio"
                  aria-label="Address 1"
                  onClick={() => {
                    handleValuesSelect(true);
                  }}
                  label="Yes, I would like to receive email updates including sale updates and alerts, news, event invites and offers from FineArt.HipHop. (You can unsubscribe at any time by clicking the unsubscribe link in our emails)."
                  name="formHorizontalRadios"
                  id="formHorizontalRadios1"
                />
              </div>
            </div>
            <div className="select_address_content select_address_content1">
              <div className="radioWrap">
                <Form.Check
                  type="radio"
                  onClick={() => {
                    handleValuesSelect(false);
                  }}
                  aria-label="Address 2"
                  name="formHorizontalRadios"
                  label="No, please exclude me from all future email communications. (Please note that you will not receive email alerts including information on items that may be of interest to you in forthcoming auctions. However, if you transact with us, or inquire about services, we may email you in connection with the specific transaction or inquiry)."
                  id="formHorizontalRadios2"
                />
              </div>
            </div>
          </div>
          <h5>Privacy Statement</h5>
          <p className="text-signup-aggr-sec">
            <input
              type="checkbox"
              onChange={(e) => {
                props?.onChangeInputBox("privacy", e);
              }}
            />{" "}
            We will never pass your personal information to anyone outside of
            FineArt.HipHop for marketing purposes without consent. For more
            information about how FineArt.HipHop processes your data, please
            click here to read our{" "}
            <a target="_blank" href="/privacypolicy">
              Privacy Notice
            </a>
            .
          </p>
          <h5>Terms &amp; Conditions</h5>
          <p className="text-signup-aggr-sec">
            <input
              type="checkbox"
              onChange={(e) => {
                props?.onChangeInputBox("terms", e);
              }}
            />{" "}
            By clicking you agree to the{" "}
            <a target="_blank" href="/termsandconditions">
              Terms &amp; Conditions
            </a>{" "}
            for use of FineArt.HipHop website. All site content is confidential
            and proprietary and is protected by FineArt.HipHop and third party
            copyrights. By accessing this site you agree not to reproduce,
            redistribute, display or publish any of the content.
          </p>
          <p>By clicking submit you confirm you are at least 18 or older.</p>
        </Col>
        {emailError != "" && !errorsFields?.email && (
          <div className="emailErrorText">{emailError}</div>
        )}
        {/* <Col sm={12} className="signup_info_mobile d-md-none d-block ">
          <Form.Group className="mb-2">
            <div className="form-check link_input_checkbox">
              <input
                type="checkbox"
                onClick={() => {
                  handleValuesSelect(true);
                }}
                id="formBasicCheckbox2"
                className="form-check-input"
              />
              <label className="form-check-label d-md-none d-block">
                Yes, I would like to receive email communications from
                Fineart.hiphop1
              </label>
            </div>

            
          </Form.Group>
          <Form.Group className="mb-0">
            <div className="form-check link_input_checkbox">
              <input
                type="checkbox"
                onClick={() => {
                  handleValuesSelect(false);
                }}
                id="formBasicCheckbox2"
                className="form-check-input"
              />
              <label className="form-check-label d-md-none d-block">
                I have read and agree to the
                <Link to="/sign-up" className="text-dark font-weight-bold">
                  {" "}
                  Terms & Conditions
                </Link>{" "}
                and
                <Link to="/sign-up" className="text-dark font-weight-bold">
                  {" "}
                  Privacy Policy.
                </Link>
              </label>
            </div>
          </Form.Group>
        </Col> */}
      </Row>
    </>
  );
};

export default Step1;
