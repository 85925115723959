import React from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { GOOGLE_MAP_URL } from "./../../library/constants";

import {
  addcustomeraddressCall,
  editcustomeraddressCall,
} from "./../../library/Requests/MyAccount";
import { LOGINID } from "./../../Scenes/common";
import { getcustomeraddressCall } from "./../../library/Requests/MyAccount";
import MobileNumbersList from "./../../components/Common/MobileNumbersList";
import "./css/AddAddress.css";
import { formatPhoneNumber } from "./../../Scenes/common";
import Loader from "./../../components/Common/Loader";
import { ReactComponent as CloseIcon } from "../../assets/close-icon.svg";
import { AddAddressStyled } from "./AddAddressStyled.styled";
class AddAddress extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      userId: "",
      userData: {
        title: "Mr.",
        firstName: "",
        lastName: "",
        phoneCode: "",
        phoneNumber: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        county: "",
        zipPostalCode: "",
        isPrimary: false,
      },
      addressErrorFields: {
        firstName: false,
        lastName: false,
        state: false,
        city: false,
        county: false,
        address1: false,
        zipPostalCode: false,
        phoneCode: false,
        phoneNumber: false,
      },
      loginData: {},
      editUser: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const searchLo = this.props.location.search || this.props.location.pathname;
    console.log(searchLo);
    if (searchLo.indexOf("edit-address") > 0) {
      const collID = searchLo.replace("/edit-address/", "");
      this.setState({ userId: collID, editUser: true });
      this.getAllAddress(collID);
    }
    let userInfo = localStorage.getItem("customerInfoDetails");
    if (userInfo != null) {
      let newPP = userInfo;
      const daran = JSON.parse(newPP);
      this.setState({ loginData: daran });
    }
    this.onLoadGoogleMap();
  }
  getAllAddress = async (id: any) => {
    const resp1 = await getcustomeraddressCall(LOGINID);
    const listAdd = resp1?.address;
    const index = listAdd.findIndex((item: any) => item?.id == id);
    this.setState({ userData: listAdd[index] });
  };
  handleChange = (e: any) => {
    const { value, name } = e.target;
    const state = this.state.userData;
    if (name == "firstName" || name === "lastName") {
      let resFlag = /^[a-zA-Z]+$/.test(value);
      if (resFlag || value === "") {
        state[name] = value;
      }
    } else if (name == "phoneNumber") {
      const re = /^[0-9-\b]+$/;
      if (value === "" || re.test(value)) {
        state[name] = value?.replace(/[\D]/g, "");
      }
    } else {
      state[name] = value;
    }
    let errorsList = this.state.addressErrorFields;
    errorsList[name] = false;
    this.setState({ userData: state, addressErrorFields: errorsList });

    if (name === "address1") {
      const pacContainer: any = document.querySelector(".pac-container");
      if (pacContainer) {
        pacContainer.style.zIndex = "9999999"; // Ensure dropdown appears above other elements
      }
    }
  };

  handleScriptLoad = () => {
    const autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("user_add_address_Line1") as HTMLInputElement
    );
    autocomplete.setFields(["address_components", "formatted_address"]);
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      this.handlePlaceSelect(place);
    });
  };

  onLoadGoogleMap = () => {
    const _this = this;
    const script = document.createElement("script");
    script.src = GOOGLE_MAP_URL;
    script.id = "googleMaps";
    document.body.appendChild(script);
    script.onload = () => {
      _this.handleScriptLoad();
    };
  };
  handlePlaceSelect = (addressObject: any) => {
    var address1 = "";
    let postcode = "";
    let city = "";
    let state = "";
    let address2 = "";
    let country = "";
    let short_name_country = "";
    let short_name_state = "";
    for (const component of addressObject?.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number": {
          address1 = `${component.long_name}`;
          break;
        }
        case "route": {
          address2 = component.short_name;
          break;
        }
        case "postal_code": {
          postcode = `${component.long_name}${postcode}`;
          break;
        }
        case "locality":
          city = component.long_name;
          break;
        case "country":
          country = component.long_name;
          short_name_country = component.short_name;

          break;
        case "administrative_area_level_1": {
          state = component.long_name;
          short_name_state = component.short_name;
          break;
        }
      }
    }

    const AddressLine1 = address1 + " " + address2;

    const stateD = this.state.userData;
    stateD["address1"] = AddressLine1;
    stateD["address2"] = "";
    stateD["city"] = city;
    stateD["state"] = state;
    stateD["county"] = country;
    stateD["zipPostalCode"] = postcode;
    stateD["StateCode"] = short_name_state;
    stateD["CountryCode"] = short_name_country;

    this.setState({ userData: stateD });
  };

  handleChangeCheckbox = (e: any) => {
    const state = this.state.userData;
    if (e.target.checked) {
      state.isPrimary = true;
    } else {
      state.isPrimary = false;
    }
    this.setState({ userData: state });
  };

  saveAddress = async () => {
    const { isLoading } = this.state;
    if (this.validateAddress() && !isLoading) {
      const { userData, editUser } = this.state;
      const body = {
        costomerId: LOGINID,
        address: {
          id: userData?.id,
          title: userData?.title,
          firstName: userData.firstName,
          lastName: userData.lastName,
          county: userData.county,
          state: userData.state,
          city: userData.city,
          address1: userData.address1,
          address2: userData.address2,
          zipPostalCode: userData.zipPostalCode,
          isPrimary: userData.isPrimary,
          phoneCode: userData.phoneCode,
          phoneNumber: userData.phoneNumber,
          StateCode: userData.StateCode,
          CountryCode: userData.CountryCode,
        },
      };
      this.setState({ isLoading: true });
      if (editUser) {
        const res1p = await editcustomeraddressCall(body);
        this.setState({ isLoading: false });
        if (res1p?.success) {
          this.props.history.push("/profile");
        }
      } else {
        let userInfo = localStorage.getItem("customerInfoDetails");
        if (userInfo != undefined && userInfo != null && userInfo != "") {
          const userIn = JSON.parse(userInfo);
          if (!userIn?.billingAddressId) {
            body.address.isPrimary = true;
          }
        }
        const resp = await addcustomeraddressCall(body);
        this.setState({ isLoading: false });
        if (resp?.success) {
          this.props.history.push("/profile");
        }
      }
    }
  };
  validateAddress = () => {
    let returnFlag = true;
    let errorsList = this.state.addressErrorFields;
    for (var key in errorsList) {
      const Pvalue = this.state.userData[key];
      if (
        Pvalue == "" ||
        Pvalue == null ||
        Pvalue == undefined ||
        Pvalue?.trim() == ""
      ) {
        errorsList[key] = true;
        returnFlag = false;
      }
      if (key === "phoneNumber" && Pvalue?.length < 10) {
        errorsList[key] = true;
        returnFlag = false;
      }
      if (key === "zipPostalCode" && Pvalue?.length < 5) {
        errorsList[key] = true;
        returnFlag = false;
      }
    }
    this.setState({ addressErrorFields: errorsList });
    return returnFlag;
  };

  render() {
    const { userData, editUser, addressErrorFields, isLoading } = this.state;

    return (
      <>
        <div className="add_address_page_content">
          {isLoading && <Loader />}
          <Modal show={true} className="add-address-modal">
            <AddAddressStyled>
              <Modal.Header className="add-address-modal-header">
                <h2>Add Address</h2>
                <CloseIcon
                  onClick={() => {
                    this.props.history.push("/profile");
                  }}
                />
              </Modal.Header>
              <Modal.Body>
                <Form className="add_address_form_container add_address_form_Page_mobile mx-auto">
                  <Row>
                    <Col sm={12}>
                      <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          isInvalid={addressErrorFields?.title}
                          name="title"
                          as="select"
                          value={userData?.title}
                          onChange={this.handleChange}
                        >
                          <option value="Mr."> Mr.</option>
                          <option value="Mrs."> Mrs.</option>
                          <option value="Miss"> Miss</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          isInvalid={addressErrorFields?.firstName}
                          name="firstName"
                          value={userData?.firstName}
                          onChange={this.handleChange}
                          type="txt"
                          placeholder="First Name"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          isInvalid={addressErrorFields?.lastName}
                          name="lastName"
                          value={userData?.lastName}
                          onChange={this.handleChange}
                          type="text"
                          placeholder="Last Name"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12}>
                      <Form.Group>
                        <Form.Label>Address Line 1</Form.Label>
                        <Form.Control
                          isInvalid={addressErrorFields?.address1}
                          name="address1"
                          id="user_add_address_Line1"
                          value={userData?.address1}
                          onChange={this.handleChange}
                          type="address"
                          placeholder="Address"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Address Line 2</Form.Label>
                        <Form.Control
                          isInvalid={addressErrorFields?.address2}
                          name="address2"
                          value={userData?.address2}
                          onChange={this.handleChange}
                          type="address"
                          placeholder="Address"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          isInvalid={addressErrorFields?.city}
                          name="city"
                          value={userData?.city}
                          onChange={this.handleChange}
                          type="text"
                          placeholder="City"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          isInvalid={addressErrorFields?.state}
                          name="state"
                          value={userData?.state}
                          onChange={this.handleChange}
                          type="text"
                          placeholder="State"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Country</Form.Label>
                        <Form.Control
                          isInvalid={addressErrorFields?.county}
                          name="county"
                          value={userData?.county}
                          onChange={this.handleChange}
                          type="text"
                          placeholder="Country"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Postal Code</Form.Label>
                        <Form.Control
                          isInvalid={addressErrorFields?.zipPostalCode}
                          name="zipPostalCode"
                          value={userData?.zipPostalCode}
                          onChange={this.handleChange}
                          type="text"
                          placeholder="Postal Code"
                          maxLength={6}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12} className="signup-flow-phone-pe">
                      <div className="mobile-number-col">
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Label> Mobile Number </Form.Label>
                          <div className="phone-control-fields-sec">
                            <div className="phone-control-fields-sec-left">
                              <Form.Control
                                className="phoneCodeSelect"
                                isInvalid={addressErrorFields?.phoneCode}
                                value={userData.phoneCode}
                                onChange={this.handleChange}
                                name="phoneCode"
                                as="select"
                              >
                                <MobileNumbersList />
                              </Form.Control>
                            </div>
                            <div className="phone-control-fields-sec-right">
                              <Form.Control
                                className="phoneNumberStyles"
                                isInvalid={addressErrorFields?.phoneNumber}
                                type="text"
                                value={formatPhoneNumber(userData?.phoneNumber)}
                                maxLength={12}
                                name="phoneNumber"
                                placeholder="Mobile Number"
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                        </Form.Group>
                      </div>
                    </Col>

                    <Col sm={12}>
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          className="primary-checkbox"
                          type="checkbox"
                          label="Set as Primary Address"
                          checked={userData?.isPrimary}
                          onClick={this.handleChangeCheckbox}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} className="d-flex add_address_form_btn">
                      <Button href="/profile" variant="outline-primary">
                        CANCEL
                      </Button>
                      <Button
                        onClick={this.saveAddress}
                        variant="primary"
                        type="button"
                      >
                        {editUser ? "SAVE" : "ADD"} ADDRESS
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
            </AddAddressStyled>
          </Modal>
        </div>
      </>
    );
  }
}

export default AddAddress;
