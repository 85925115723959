import styled from "styled-components";

export const AccountSliderStyled = styled.div`
  .more_collection_colum .custom_account_list_sidebar .list-group-item {
    padding: 8px 10px;
    font-family: "MercuryDisplayBold";
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
  }

  .profile_page_content
    .more_collection_colum
    .custom_account_list_sidebar
    .list-group-item[title="profile"] {
    font-family: "MercuryDisplayBold";
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
  }
  .more_collection_colum .custom_account_list_sidebar .list-group-item img {
    height: 24px;
    width: 24px;
  }
  .account_all_right_content .account_all_right_section {
    padding: 0px;
  }
  .basic-info-header {
    background: #f6f6f6;
    padding: 15px 18px;
    border-radius: 8px 8px 0px 0px;
  }
  .account_all_right_content
    .account_all_right_section
    .account_all_right_content_heading
    h4 {
    font-family: "BentonSansMedium";
    font-size: 24px;
    font-weight: 400;
    line-height: 26px;
  }
  .profile-card {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    .acc-profile-card {
      padding: 25px 20px;
      border: 3px solid #0000001a;
      background: #f3f3f3;
      border-radius: 50px;
      text-transform: uppercase;
      font-family: "BentonSansBold";
      font-size: 22px;
      font-weight: 400;
      line-height: 21px;
    }
  }
  .profile_no_activity .form-group-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    .full-name {
      font-family: "BentonSansMedium";
      font-size: 20px;
      font-weight: 400;
      line-height: 21px;
      margin-bottom: 10px;
      span {
        font-family: "BentonSansMedium";
        color: #6d6d6d;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .data {
      font-family: "BentonSansRegular";
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      margin-bottom: 10px;
    }
  }
  .profile_no_activity .form-group-wrap .acc-profile-buttons {
    display: flex;
    gap: 24px;
    margin: 14px auto 24px auto;
    .btn {
      border-color: #111111;
      font-family: "BentonSansMedium";
      font-size: 14px;
      font-weight: 400;
      line-height: 14.14px;
      padding: 12px 16px;
      text-transform: uppercase;
      min-width: 180px;
    }
    .btn:hover {
      svg path {
        fill: #fff;
      }
    }
    .change-password-header {
    }
  }
  .user-profile-addreses-sec {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  .select_address_content {
    margin-top: 0px;
    padding: 20px;
    border: 1px solid #0000001f;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      font-family: "BentonSansRegular";
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 12px;
    }
    .user-full-name {
      font-family: "BentonSansMedium";
      font-size: 18px;
      font-weight: 400;
      line-height: 21px;
      margin-bottom: 12px;
    }
  }
  .user-profile-edit-sec {
    display: flex;
    gap: 12px;
    margin-top: 6px;
    .btn {
      color: #111111;
      background: #fff;
      border: 1px solid #111111;
      font-family: "BentonSansMedium";
      font-size: 12px;
      font-weight: 400;
      line-height: 12.12px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      gap: 4px;
      min-width: 120px;
      justify-content: center;
      min-height: 32px;
      svg {
        width: 16px;
        height: 16px;
      }
      .btn:hover {
      }
    }
  }
  .user-full-name {
    span {
      background: #000;
      color: #fff;
      padding: 3px;
      font-family: "BentonSansMedium";
      font-size: 12px;
      font-weight: 400;
      line-height: 13px;
      border-radius: 4px;
      margin-left: 12px;
    }
  }
  .address-book-sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media (max-width: 767px) {
    .profile_no_activity .form-group-wrap .acc-profile-buttons {
      .btn {
        min-width: 126px;
        font-size: 10px;
        padding: 8px 10px;
      }
    }
    .user-profile-addreses-sec {
      grid-template-columns: repeat(1, 100%);
      padding: 10px;
    }
    .select_address_content {
      max-width: 100%;
    }
    .user-profile-edit-sec {
      flex-direction: column;
    }
    .address-book-sec {
      margin: 16px;
      h4 {
        font-family: "BentonSansMedium";
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
      }
      .btn-outline-primary {
        color: #000;
        border-color: #000;
        font-size: 12px;
      }
    }
  }
`;
