import { Button, Modal } from "react-bootstrap";
import { ReactComponent as Close } from "../../assets/close-icon.svg";
import styled from "styled-components";

const BidSuccessModal = (props: any) => {
  const {
    userAddress,
    getfullAddress,
    getfullName,
    paddleNumber,
    setRegisterBidSuccess,
    show,
  } = props;
  return (
    <>
      <Modal show={show} centered>
        <BidSuccessStyled>
          <Modal.Header className="modal-paddle-header">
            You are registered with paddle number{" "}
            <span className="mr-4">({paddleNumber})</span>
            <Close
              onClick={() => {
                setRegisterBidSuccess(false);
              }}
            />
          </Modal.Header>
          <Modal.Body className="paddle-body">
            <p>You are bidding in this sale with FineArtHipHop Account:</p>
            <div>
              <div>
                <p className="user-fullname">
                  {getfullName(userAddress)}
                  <span> | JD {userAddress?.id}</span>{" "}
                </p>
                <p className="address-text">{getfullAddress(userAddress)}</p>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={() => {
                setRegisterBidSuccess(false);
              }}
            >
              Okay
            </Button>
          </Modal.Footer>
        </BidSuccessStyled>
      </Modal>
    </>
  );
};

export default BidSuccessModal;

export const BidSuccessStyled = styled.div`
  .modal-paddle-header {
    font-family: "BentonSansMedium";
    font-size: 20px;
    font-weight: 500;
    line-height: 27.24px;
    align-items: center;
  }
  .paddle-body {
    p {
      font-family: "BentonSansRegular";
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #11111180;
    }
  }

  .paddle-body .user-fullname {
    font-family: "BentonSansMedium";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 4px;
    color: #000;
    span {
      font-family: "BentonSansRegular";
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .paddle-body .address-text {
    font-family: "BentonSansRegular";
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 0px;
    color: #000;
  }
  .modal-footer {
    border: none;
    .btn {
      min-width: 140px;
      min-height: 46px;
      background: #000;
      font-family: BentonSansMedium;
      font-size: 16px;
      font-weight: 400;
      line-height: 21.6px;
      text-transform: uppercase;
    }
  }
`;
