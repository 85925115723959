import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MobileNumbersList from "./../../components/Common/MobileNumbersList";
// import Script from "react-load-script";
// import makeAsyncScriptLoader from "react-async-script";
import { formatPhoneNumber } from "./../../Scenes/common";

import { GOOGLE_MAP_URL } from "./../../library/constants";
import {
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { isMobile } from "react-device-detect";

interface Props {
  currentStep: any;
  handleChange: any;
  // username: any;
  errorsFields: any;
  errorsData: any;
  userAccountData: any;
  userAddressData: any;
  handleScriptLoad?: any;
  errorsAddressData?: any;
  errorsAddressFields?: any;
  handleChangeDate?: any;
}

// function Step2(props: any) {
export const Step2: React.FC<Props> = (props: any) => {
  const [startDate, setStartDate] = useState<any>(new Date());
  if (props.currentStep !== 2) {
    return null;
  }
  // useEffect(() => {
  //   onLoadGoogleMap();
  // }, []);

  const {
    errorsFields,
    errorsData,
    userAccountData,
    userAddressData,
    errorsAddressFields,
    handleChangeDate,
    errorsAddressData,
  } = props;
  // console.log("userAddressDatauserAddressData", userAddressData);
  // console.log("userAccountData.address2", userAccountData.address2);
  // console.log("userAccountData.address2", userAccountData.city);
  console.log("userAccountData?.dateOfBirth", userAccountData?.dateOfBirth);
  console.log("moment().subtract", moment().subtract(18, "years"));
  const newminData = moment().subtract(18, "years") as any;
  const newmaxData = moment().subtract(100, "years") as any;
  return (
    // <div className="form-group">
    //   <label htmlFor="username">Username</label>
    //   <input
    //     className="form-control"
    //     id="username"
    //     name="username"
    //     type="text"
    //     placeholder="Enter username"
    //     value={props.username}
    //     onChange={props.handleChange}
    //   />
    // </div>
    <Row>
      {isMobile ? (
        ""
      ) : (
        <>
          {/* <Script url={GOOGLE_MAP_URL} onLoad={props?.handleScriptLoad} /> */}
          <Col sm={12}>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Title</Form.Label>
              <Form.Control
                disabled
                name="title"
                as="select"
                value={userAccountData.title}
              >
                <option value="Mr."> Mr.</option>
                <option value="Mrs."> Mrs.</option>
                <option value="Miss"> Miss</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                disabled
                value={userAccountData.firstName}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                disabled
                value={userAccountData.lastName}
              />
            </Form.Group>
          </Col>
          <Col sm={12}>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                disabled
                value={userAccountData.email}
              />
            </Form.Group>
          </Col>
        </>
      )}
      <Col md={6}>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>
            Date of Birth <span className="asterisk-symbol-red">*</span>
          </Form.Label>
          {/* <Form.Control
            // value={LotData?.periodOfWork}
            name="dateOfBirth"
            onChange={props.handleChange}
            type="date"
            min={moment().subtract(500, "years").format("YYYY-MM-DD")}
            max={moment().subtract(18, "years").format("YYYY-MM-DD")}
            placeholder="Date of Birth"
            isInvalid={errorsAddressFields?.dateOfBirth}
          /> */}
          <DatePicker
            value={userAddressData?.dateOfBirth}
            name="dateOfBirth"
            placeholderText={"Enter Date of Birth"}
            autoComplete="off"
            onChange={handleChangeDate}
            // showMonthDropdown={true}
            // showYearDropdown={true}
            maxDate={newminData._d}
            minDate={newmaxData._d}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            // scrollableYearDropdown={true}
            className={
              errorsAddressFields?.dateOfBirth
                ? "error-date-sec-dateof-birth date-of-birth-field-step2"
                : "date-of-birth-field-step2"
            }
            dateFormat="MM/dd/yyyy"
            onKeyDown={(e: any) => {
              e.preventDefault();
            }}
          />

          <Form.Control.Feedback type="invalid">
            {errorsAddressFields?.dateOfBirth ? (
              <>{errorsAddressData?.dateOfBirth}</>
            ) : null}
          </Form.Control.Feedback>
        </Form.Group>
      </Col>

      <Col md={6} className="signup-flow-phone-pe">
        <div className="mobile-number-col">
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>
              Mobile Number<span className="asterisk-symbol-red">*</span>
            </Form.Label>
            <div className="phone-control-fields-sec">
              <div className="phone-control-fields-sec-left">
                <Form.Control
                  className="phoneCodeSelect"
                  value={userAddressData.phoneCode}
                  onChange={props.handleChange}
                  name="phoneCode"
                  as="select"
                  isInvalid={errorsAddressFields?.phoneNumber}
                >
                  <MobileNumbersList />
                </Form.Control>
              </div>
              <div className="phone-control-fields-sec-right">
                <Form.Control
                  className="phoneNumberStyles"
                  type="text"
                  maxLength={12}
                  value={formatPhoneNumber(userAddressData?.phoneNumber)}
                  name="phoneNumber"
                  placeholder="Mobile Number"
                  onChange={props.handleChange}
                  isInvalid={errorsAddressFields?.phoneNumber}
                />
              </div>
            </div>

            {/* <Form.Control.Feedback type="invalid">
              {errorsAddressFields?.phoneNumber ? (
                <>{errorsAddressData?.phoneNumber}</>
              ) : null}
            </Form.Control.Feedback> */}
          </Form.Group>
        </div>
        {/* <div className="mobile-number-col-sec">
          <Form.Group
            controlId="exampleForm.ControlSelect1"
            className="twoBoxStyles"
          >
            <Form.Label className="emptyLabelAddress"></Form.Label>
          </Form.Group>
        </div> */}
      </Col>
      {/* <Col md={6}>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label htmlFor="inlineFormInputGroup">Username</Form.Label>
          <InputGroup className="mb-2">
            <InputGroup.Prepend>
              <InputGroup.Text className="bg-white">+91</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              id="inlineFormInputGroup"
              type="number"
              placeholder="Username"
            />
          </InputGroup>
        </Form.Group>
      </Col> */}
      <Col sm={12}>
        <Form.Group>
          <Form.Label>
            Address Line 1<span className="asterisk-symbol-red">*</span>
          </Form.Label>
          <Form.Control
            type="address"
            name="address1"
            id="signup_addres_line1"
            placeholder="Address line 1"
            onChange={props.handleChange}
            value={userAddressData.address1}
            isInvalid={errorsAddressFields?.address1}
          />

          {/* <Form.Control.Feedback type="invalid">
            {errorsAddressFields?.address1 ? (
              <>{errorsAddressData?.address1}</>
            ) : null}
          </Form.Control.Feedback> */}
        </Form.Group>
      </Col>
      <Col md={12}>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>Address Line 2</Form.Label>
          <Form.Control
            type="address"
            name="address2"
            placeholder="Address line 2"
            onChange={props.handleChange}
            value={userAddressData.address2}
            isInvalid={errorsAddressFields?.address2}
          />

          {/* <Form.Control.Feedback type="invalid">
            {errorsAddressFields?.address2 ? (
              <>{errorsAddressData?.address2}</>
            ) : null}
          </Form.Control.Feedback> */}
        </Form.Group>
      </Col>

      <Col md={6}>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>
            City<span className="asterisk-symbol-red">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            name="city"
            placeholder="City"
            onChange={props.handleChange}
            value={userAddressData.city}
            isInvalid={errorsAddressFields?.city}
          />

          {/* <Form.Control.Feedback type="invalid">
            {errorsAddressFields?.city ? <>{errorsAddressData?.city}</> : null}
          </Form.Control.Feedback> */}
        </Form.Group>
      </Col>

      <Col md={6}>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>
            State<span className="asterisk-symbol-red">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            name="state"
            placeholder="State"
            onChange={props.handleChange}
            value={userAddressData.state}
            isInvalid={errorsAddressFields?.state}
          />

          {/* <Form.Control.Feedback type="invalid">
            {errorsAddressFields?.state ? (
              <>{errorsAddressData?.state}</>
            ) : null}
          </Form.Control.Feedback> */}
        </Form.Group>
      </Col>

      <Col md={6}>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>
            Country<span className="asterisk-symbol-red">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            name="county"
            placeholder="Country"
            onChange={props.handleChange}
            value={userAddressData.county}
            isInvalid={errorsAddressFields?.county}
          />

          {/* <Form.Control.Feedback type="invalid">
            {errorsAddressFields?.county ? (
              <>{errorsAddressData?.county}</>
            ) : null}
          </Form.Control.Feedback> */}
        </Form.Group>
      </Col>

      <Col md={6}>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>
            Zip / Postal Code<span className="asterisk-symbol-red">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            maxLength={6}
            name="zipPostalCode"
            placeholder="Zip / Postal Code"
            onChange={props.handleChange}
            value={userAddressData.zipPostalCode}
            isInvalid={errorsAddressFields?.zipPostalCode}
          />
          {/* 
          <Form.Control.Feedback type="invalid">
            {errorsAddressFields?.zipPostalCode ? (
              <>{errorsAddressData?.zipPostalCode}</>
            ) : null}
          </Form.Control.Feedback> */}
        </Form.Group>
      </Col>
    </Row>
  );
};

export default Step2;
