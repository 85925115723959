import styled from "styled-components";

export const BidRegistrationStyled = styled.div`
  .bid-regis-modal-body {
    font-family: "MercuryDisplayBold";
    font-size: 20px;
    font-weight: 700;
    line-height: 23.34px;
    align-items: center;
    margin: 24px 24px 0px 24px;
    padding: 0px;
    padding-bottom: 16px;
    svg {
      cursor: pointer;
    }
  }
  .modal-body {
    padding: 24px;
  }
  .bid-info-text {
    font-family: "BentonSansRegular";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 20px;
    span {
      font-family: "BentonSansMedium";
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .address-sec {
    border: 1px solid #0000001f;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 12px;
    .address-header {
      font-family: "BentonSansRegular";
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: #00000080;
      margin-bottom: 4px;
    }
    .user-fullname {
      font-family: "BentonSansMedium";
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 4px;
      span {
        font-family: "BentonSansRegular";
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .address-text {
      font-family: "BentonSansRegular";
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 0px;
    }
  }
  .declartion-labels {
    margin-bottom: 24px;
    p {
      font-family: "BentonSansRegular";
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #11111180;
      margin-bottom: 12px;
    }
    label {
      font-family: "BentonSansRegular";
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #111111bf;
    }
    input[type="radio"] {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 20px;
      height: 20px;
      border: 2px solid black; /* Outer circle border */
      border-radius: 50%;
      background-color: white; /* White center */
      cursor: pointer;
      position: relative;
    }

    /* Checked state with inner black circle */
    input[type="radio"]:checked::after {
      content: "";
      width: 10px; /* Adjust size for the inner black circle */
      height: 10px;
      background-color: black;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); /* Center the inner circle */
    }
  }
  .declare-inputs {
    display: flex;
    align-items: center;
    gap: 12px;
    label {
      margin-bottom: 0px;
    }
  }
  .conditions-checkbox {
    margin-top: 16px;
    gap: 16px;
    display: grid;
    grid-template-columns: 5% 92%;
    align-items: flex-start;
    input {
      width: 20px;
      height: 20px;
    }
    label {
      font-family: "BentonSansRegular";
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      span {
        font-family: "BentonSansMedium";
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        text-decoration-line: underline;
        text-decoration-style: solid;
      }
    }
  }
  .footer-butns {
    display: flex;
    justify-content: end;
    gap: 16px;
    margin-top: 24px;
    .btn {
      width: 185px;
      height: 46px;
      font-family: "BentonSansMedium";
      font-size: 16px;
      font-weight: 400;
      line-height: 21.6px;
      text-transform: uppercase;
      border-color: #000;
    }
    .cancel-butn {
      background: #fff;
      color: #000;
    }
    .acc-btn {
      background: #000;
      color: #fff;
    }
    .cancel-butn:hover {
      background: #000;
      color: #fff;
    }
  }
  @media (max-width: 767px) {
    .footer-butns .btn {
      font-size: 12px;
      width: 100%;
      min-width: auto;
    }
  }
`;
