import React, { Component } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
// import { Link } from "react-router-dom";
import { Link, useHistory, withRouter } from "react-router-dom";
import MobileNumbersList from "./../../components/Common/MobileNumbersList";
// import { ReactComponent as PlusAddress } from "./../../assets/image/PlusAddress.svg";

// import Slide2 from "./../../assets/image/slide2.jpg";
// import Script from "react-load-script";
import { GOOGLE_MAP_URL } from "./../../library/constants";
import { isMobile } from "react-device-detect";
import "./css/ItemPurchase.css";
import { getcurrenciesCall } from "./../../library/Requests";
import {
  getPaymentDetailsCall,
  getcustomeraddressCall,
  makeOrderCall,
  payNowCall,
  addcustomeraddressCall,
  updateSalesTaxCall,
  updateShippingChargesCall,
} from "./../../library/Requests/MyAccount";
import { LOGINID } from "./../../Scenes/common";
import { formatPhoneNumber } from "./../../Scenes/common";

import { connect } from "react-redux";
import * as actionCreators from "./../../actions/index.js";
import {
  ApplePay,
  GooglePay,
  CreditCard,
  PaymentForm,
  Ach,
} from "react-square-web-payments-sdk";
import Loader from "./../../components/Common/Loader";
import PaymentButton from "./../../components/PaymentButton/PaymentButton";
import { triggerMixpanelEvent, displayAmount } from "./../../Scenes/common";
import {
  DOMAIN_URL,
  square_applicationId,
  square_locationId,
} from "./../../config";
import { convertSelectedCountryCurrency } from "../../utils";

class ItemPurchases extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      currenciesList: [],
      orderDetails: {},
      JsonData: {},
      addAddress: false,
      addCard: false,
      prevCards: [],
      isErrorCard: "",
      isaddressEmpty: "",
      NewAddressData: {},
      isphoneNumberEmpty: false,
      addressErrorFields: {
        title: false,
        firstName: false,
        lastName: false,
        state: false,
        city: false,
        county: false,
        address1: false,
        // address2: false,
        zipPostalCode: false,
        phoneCode: false,
        phoneNumber: false,
      },
      isLoading: true,
      paymentError: "",
      isPageloading: false,
      showPaymentPopup: false,
      activeTab: "personal", //personal shipping payment
      phoneCodeError: false,
      phoneNumberError: false,
      isShipToMe: true,
      shippingErrorMessage: "",
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const orderID = this.props?.match?.params?.id;
    this.getcurrenciesDataCall();
    this.getDataonLoad(orderID, "onLoad");
    triggerMixpanelEvent("item_purchase_page", { orderId: orderID });
  }
  onLoadGoogleMap = () => {
    const _this = this;
    const script = document.createElement("script");
    script.src = GOOGLE_MAP_URL;
    script.id = "googleMaps";
    document.body.appendChild(script);
    script.onload = () => {
      _this.handleScriptLoad();
    };
  };
  getcurrenciesDataCall = async () => {
    const resp = await getcurrenciesCall();
    this.setState({ currenciesList: resp?.data });
  };
  getcustomeraddressCall = async (pType: any) => {
    const resp1 = await getcustomeraddressCall(LOGINID);
    this.setState({ userAddress: resp1?.address });
    if (pType === "onLoad") {
      const ppAddress = resp1?.address.find((itmm: any) => itmm.isPrimary);
      if (ppAddress?.id) {
        this.selectAddressBox(ppAddress);
      }
    }
  };
  getDataonLoad = async (orderID: any, pType: any) => {
    if (pType === "onLoad") {
      this.setState({ isLoading: true, isPageloading: true });
    }
    // this.setState({ isLoading: true, isPageloading: true });
    const resp = await getPaymentDetailsCall(orderID);
    const orderDetails = resp?.data;

    this.setState({
      isLoading: false,
      isPageloading: false,
      orderDetails: resp?.data,
      prevCards: resp?.cards,
    });
    if (pType === "onLoad") {
      window.scrollTo(0, 0);
      this.getcustomeraddressCall(pType);
    }

    console.log("this.props?.user", this.props?.user);
    console.log("orderDetails", orderDetails);
    const { JsonData, NewAddressData } = this.state;
    JsonData.orderId = orderDetails?.orderId;
    JsonData.orderNo = orderDetails?.orderNo;
    JsonData.firstName = orderDetails?.firstName;
    JsonData.lastName = orderDetails?.lastName;
    JsonData.email = orderDetails?.email;
    JsonData.phoneNumber =
      this.props?.user?.phoneNumber || orderDetails?.phoneNumber;
    JsonData.phoneCode = this.props?.user?.phoneCode || orderDetails?.phoneCode;
    JsonData.currency = orderDetails?.currency;
    JsonData.amount = orderDetails?.totalAmount;
    JsonData.customerId = orderDetails?.customerId;
    JsonData.salesTax = orderDetails?.salesTax;
    JsonData.shippingFee = orderDetails?.shippingFee;

    NewAddressData.title = orderDetails?.title;
    NewAddressData.firstName = orderDetails?.firstName;
    NewAddressData.lastName = orderDetails?.lastName;

    JsonData.paywithExistingCard = false;
    JsonData.last4digits = 0;

    this.setState({ JsonData: JsonData, NewAddressData });
  };

  makePaymentOrder = (id: any) => {
    this.props.history.push(`/itemPurchase/${id}`);
  };
  getfullName = (user: any) => {
    let fullname = user?.title ? user?.title : "";
    fullname += user?.firstName ? ` ${user?.firstName}` : "";
    fullname += user?.lastName ? ` ${user?.lastName}` : "";
    return fullname;
  };
  getfullAddress = (user: any) => {
    let fullname = user?.address1 ? user?.address1 : "";
    fullname += user?.address2 ? `, ${user?.address2}` : "";
    fullname += user?.city ? `, ${user?.city}` : "";
    fullname += user?.state ? `, ${user?.state}` : "";
    fullname += user?.county ? `, ${user?.county}` : "";
    fullname += user?.zipPostalCode ? `, ${user?.zipPostalCode}` : "";
    return fullname;
  };
  changeValue = (e: any) => {
    this.setState({ phoneCodeError: false, phoneNumberError: false });
    const { name, value } = e.target;
    const { JsonData } = this.state;
    if (
      name == "cardNumber" ||
      name == "expMonth" ||
      name == "expYear" ||
      name == "cvv"
    ) {
      const re = /^[0-9 \b]+$/;
      if (re.test(value) || value == "") {
        if (name == "cardNumber") {
          JsonData[name] = value.replace(/\W/gi, "").replace(/(.{4})/g, "$1 ");
        } else {
          JsonData[name] = value;
        }
      } else {
        JsonData[name] = JsonData[name] || "";
      }
    } else if (name == "phoneNumber") {
      const re = /^[0-9-\b]+$/;
      if (re.test(value) || value == "") {
        JsonData[name] = value?.replace(/[\D]/g, "");
      } else {
        JsonData[name] = JsonData[name] || "";
      }
    } else {
      JsonData[name] = value;
    }
    this.setState({
      JsonData: JsonData,
      isErrorCard: "",
      isphoneNumberEmpty: false,
    });
  };

  handleScriptLoad = () => {
    const options = {
      // componentRestrictions: { country: "us" },
    };
    // var google = {} as any;
    const autocomplete = new google.maps.places.Autocomplete(
      document.getElementById(
        "user_item_purchase_add_address_Line1"
      ) as HTMLInputElement,
      options
    ) as any;
    autocomplete.setFields(["address_components", "formatted_address"]);
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      console.log("placeplace", place);
      this.handlePlaceSelect(place);
    });
  };

  handlePlaceSelect = (addressObject: any) => {
    var address1 = "";
    let postcode = "";
    let city = "";
    let state = "";
    let address2 = "";
    let country = "";
    let short_name_country = "";
    let short_name_state = "";

    console.log(
      "addressObject?.address_components",
      addressObject?.address_components
    );
    for (const component of addressObject?.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number": {
          address1 = `${component.long_name}`;
          break;
        }
        case "route": {
          address2 = component.short_name;
          break;
        }
        case "postal_code": {
          postcode = `${component.long_name}${postcode}`;
          break;
        }
        case "locality":
          city = component.long_name;
          break;
        case "country":
          country = component.long_name;
          short_name_country = component.short_name;
          break;
        case "administrative_area_level_1": {
          state = component.long_name;
          short_name_state = component.short_name;
          break;
        }
      }
    }
    const AddressLine1 = address1 + " " + address2;

    const stateD = this.state.NewAddressData;
    stateD["address1"] = AddressLine1;
    stateD["address2"] = "";
    stateD["city"] = city;
    stateD["state"] = state;
    stateD["county"] = country;
    stateD["zipPostalCode"] = postcode;
    stateD["StateCode"] = short_name_state;
    stateD["CountryCode"] = short_name_country;
    this.setState({ NewAddressData: stateD });
  };
  selectAddressBox = (address: any) => {
    const { JsonData } = this.state;
    JsonData.billingAddressId = address?.id;
    JsonData.shippingAddressId = address?.id;
    JsonData.phoneNumber = address?.phoneNumber;
    JsonData.phoneCode = address?.phoneCode;

    this.setState({
      JsonData: JsonData,
      isaddressEmpty: "",
      addAddress: false,
    });
    if (address?.id) {
      this.updateAddressIdCall(address?.id);
    }
  };
  updateAddressIdCall = async (aId: any) => {
    this.setState({ shippingErrorMessage: "" });
    const { orderDetails } = this.state;
    // if (aId) {
    const jsonBB = {
      orderId: orderDetails?.orderId,
      addressId: aId,
    };
    this.setState({ isLoading: true });
    const resp = await updateSalesTaxCall(jsonBB);
    const resp1 = await updateShippingChargesCall(jsonBB);
    if (resp1?.message || resp1?.errors?.length > 0) {
      var errM = "Specified address is not supported.";
      if (resp1?.errors?.length > 1) {
        errM = resp1?.errors[0];
      }
      this.setState({
        shippingErrorMessage: errM,
      });
      // this.setState({ isLoading: false });
      this.getDataonLoad(orderDetails?.orderId, "");
      triggerMixpanelEvent("checkout_select_address_error", {
        orderId: orderDetails?.orderId,
        message: errM,
      });
    } else {
      this.getDataonLoad(orderDetails?.orderId, "");
    }
    // this.setState({ isLoading: false });
    console.log("resp1", resp1);
    // }
  };
  selectPrevCard = (card: any) => {
    const { JsonData } = this.state;
    JsonData.last4digits = card?.cardLast4digits;
    JsonData.paywithExistingCard = true;
    // DELETE JsonData.cardNumber;
    try {
      delete JsonData.cardNumber;
      delete JsonData.nameOnCard;
      delete JsonData.expMonth;
      delete JsonData.expYear;
      delete JsonData.cvv;
    } catch (e) {
      console.log(e);
    }
    // (!JsonData?.cardNumber || !JsonData?.nameOnCard || !JsonData?.expMonth || !JsonData?.expYear || !JsonData?.cvv)){

    this.setState({ JsonData: JsonData, isErrorCard: "", addCard: false });
  };
  addNewAddress = () => {
    const { JsonData } = this.state;
    JsonData.billingAddressId = 0;
    JsonData.shippingAddressId = 0;
    this.setState({ JsonData: JsonData, isaddressEmpty: "" });

    this.setState({ addAddress: !this.state.addAddress });
    this.onLoadGoogleMap();
  };
  addNewCardDetails = (cards: any) => {
    this.setState({ addCard: !this.state.addCard });
    const { JsonData } = this.state;
    JsonData.last4digits = 0;
    JsonData.paywithExistingCard = false;
    this.setState({ JsonData: JsonData, isErrorCard: "" });
  };
  payNowClick = async () => {
    this.setState({ showPaymentPopup: true });
    if (this.validateData()) {
      this.setState({ showPaymentPopup: true });

      // const { JsonData } = this.state;
      // JsonData.cardNumber = JsonData?.cardNumber?.replace(/ /g, "");
      // this.setState({ isLoading: true });
      // const resp = await makeOrderCall(JsonData);
      // this.setState({ isLoading: false });
      // if (resp?.success) {
      //   this.props.history.push(`/PaymentConfirmation/${JsonData.orderId}`);
      // } else if (resp?.message?.length > 0) {
      //   this.setState({ paymentError: resp.message });
      // }
    }
  };
  makePayNowAPICall = async (token: any) => {
    const { JsonData } = this.state;
    JsonData.SourceId = token;
    this.setState({ isLoading: true });
    const resp = await payNowCall(JsonData);
    this.setState({ isLoading: false });
    triggerMixpanelEvent("item_purchase_confirmation", {
      orderId: JsonData.orderId,
    });

    if (resp?.success) {
      triggerMixpanelEvent("item_purchase_confirmation_success", {
        orderId: JsonData.orderId,
      });
      this.props.history.push(`/PaymentConfirmation/${JsonData.orderId}`);
    } else if (resp?.message?.length > 0) {
      triggerMixpanelEvent("item_purchase_confirmation_error", {
        orderId: JsonData.orderId,
        message: resp.message,
      });
      this.setState({ paymentError: resp.message });
    }
  };
  validateData = () => {
    let returnFlag = true;
    const { JsonData } = this.state;
    let notrequired = false;
    if (!JsonData.billingAddressId && !JsonData.shippingAddressId) {
      this.setState({
        isaddressEmpty: "Please select the address or Add new address.",
      });
      returnFlag = false;
    }

    if (!JsonData?.paywithExistingCard || JsonData?.last4digits == 0) {
      if (
        !JsonData?.cardNumber ||
        !JsonData?.nameOnCard ||
        !JsonData?.expMonth ||
        !JsonData?.expYear ||
        !JsonData?.cvv
      ) {
        this.setState({
          isErrorCard:
            "Please select the card details or Add new card details.",
        });
        returnFlag = false;
      } else {
        // this.setState({isErrorCard: "Please select the card details or Add new card details."});
        // returnFlag = false;
      }
    } else {
      // notrequired = true;
    }

    if (!JsonData?.phoneNumber) {
      this.setState({ isphoneNumberEmpty: true });
      returnFlag = false;
    }

    return returnFlag;
  };
  saveCardFaster = (e: any) => {
    const { JsonData } = this.state;
    if (e.target.checked) {
      JsonData.saveCard = true;
    } else {
      JsonData.saveCard = false;
    }
    this.setState({ JsonData: JsonData });
  };
  saveNewAddress = async () => {
    const { orderDetails, NewAddressData, JsonData } = this.state;
    if (this.validateAddress()) {
      const body = {
        costomerId: LOGINID,
        address: {
          title: orderDetails?.title,
          firstName: NewAddressData?.firstName,
          lastName: NewAddressData?.lastName,
          email: orderDetails?.email,
          county: NewAddressData?.county,
          state: NewAddressData?.state,
          city: NewAddressData?.city,
          address1: NewAddressData?.address1,
          address2: NewAddressData?.address2,
          zipPostalCode: NewAddressData?.zipPostalCode,
          phoneNumber: JsonData?.phoneNumber,
          phoneCode: JsonData?.phoneCode,
          StateCode: NewAddressData?.StateCode,
          CountryCode: NewAddressData?.CountryCode,
          isPrimary: true,
        },
      };
      this.setState({ isLoading: true });
      const resp = await addcustomeraddressCall(body);
      this.setState({ isLoading: false });
      if (resp?.success) {
        window.scrollTo(0, 200);
        this.getcustomeraddressCall("");
        const { JsonData } = this.state;
        JsonData.billingAddressId = resp?.addressId;
        JsonData.shippingAddressId = resp?.addressId;
        JsonData.phoneNumber = resp?.phoneNumber;
        JsonData.phoneCode = resp?.phoneCode;
        const stateD = this.state.NewAddressData;
        stateD["address1"] = "";
        stateD["address2"] = "";
        stateD["city"] = "";
        stateD["state"] = "";
        stateD["county"] = "";
        stateD["zipPostalCode"] = "";
        this.updateAddressIdCall(resp?.addressId);
        this.setState({
          addAddress: !this.state.addAddress,
          JsonData: JsonData,
          isaddressEmpty: "",
          NewAddressData: stateD,
        });
      }
    }
  };
  validateAddress = () => {
    let returnFlag = true;
    const { NewAddressData } = this.state;
    let errorsList = this.state.addressErrorFields;
    for (var key in errorsList) {
      const Pvalue = this.state.NewAddressData[key];
      if (Pvalue == "" || Pvalue == null || Pvalue == undefined) {
        errorsList[key] = true;
        returnFlag = false;
      }
    }
    this.setState({ addressErrorFields: errorsList });
    return returnFlag;
  };
  changeAddressValue = (e: any) => {
    const { name, value } = e.target;
    const { NewAddressData } = this.state;
    if (name == "phoneNumber") {
      const re = /^[0-9-\b]+$/;
      if (value === "" || re.test(value)) {
        NewAddressData[name] = value?.replace(/[\D]/g, "");
      }
    } else if (name == "firstName" || name === "lastName") {
      let resFlag = /^[a-zA-Z]+$/.test(value);
      if (resFlag || value === "") {
        NewAddressData[name] = value;
      }
    } else {
      NewAddressData[name] = value;
    }
    let errorsList = this.state.addressErrorFields;
    errorsList[name] = false;
    this.setState({
      NewAddressData: NewAddressData,
      isaddressEmpty: "",
      addressErrorFields: errorsList,
    });
  };
  handleContinuePersonal = (valueG: any) => {
    this.setState({ activeTab: valueG });
  };
  handleContinuePersonalBtn = (valueG: any) => {
    const { JsonData } = this.state;
    console.log("JsonData", JsonData);
    this.setState({ phoneNumberError: false, phoneCodeError: false });
    if (JsonData?.phoneNumber && JsonData?.phoneCode) {
      this.handleContinuePersonal(valueG);
    }
    if (!JsonData?.phoneNumber) {
      this.setState({ phoneNumberError: true });
    }
    if (!JsonData?.phoneCode) {
      this.setState({ phoneCodeError: true });
    }
  };
  handleContinueDeliver = (valueG: any) => {
    const { JsonData, isShipToMe } = this.state;
    console.log("JsonData", JsonData);
    if (JsonData?.shippingAddressId && isShipToMe) {
      this.handleContinuePersonal(valueG);
    } else if (!isShipToMe) {
      const { JsonData } = this.state;
      JsonData.billingAddressId = 0;
      JsonData.shippingAddressId = 0;
      this.setState({
        JsonData: JsonData,
      });
      this.handleContinuePersonal(valueG);
    }
    const _this = this;
    if (valueG === "payment") {
      _this.updateButtonText();
      setTimeout(() => {
        _this.updateButtonText();
      }, 1000);
      setTimeout(() => {
        _this.updateButtonText();
      }, 2000);
      setTimeout(() => {
        _this.updateButtonText();
      }, 3000);
      setTimeout(() => {
        _this.updateButtonText();
      }, 4000);
      setTimeout(() => {
        _this.updateButtonText();
      }, 5000);
    }
  };
  updateButtonText = () => {
    try {
      const newJon = document.getElementById(
        "rswp-card-button"
      ) as HTMLInputElement;
      if (newJon) {
        newJon.innerHTML = "PAY WITH CREDIT / DEBIT CARDS";
      }
    } catch (e) {
      console.log("e", e);
    }
  };
  handleClickShopToMe = (flag: any) => {
    this.setState({ isShipToMe: flag });
    if (!flag) {
      const { JsonData } = this.state;
      JsonData.billingAddressId = 0;
      JsonData.shippingAddressId = 0;
      this.setState({
        JsonData: JsonData,
      });
      this.updateAddressIdCall(0);
    }
  };
  render() {
    const {
      currenciesList,
      prevCards,
      orderDetails,
      NewAddressData,
      isPageloading,
      userAddress,
      isErrorCard,
      isaddressEmpty,
      JsonData,
      isphoneNumberEmpty,
      addressErrorFields,
      isLoading,
      paymentError,
      showPaymentPopup,
      activeTab,
      isShipToMe,
      shippingErrorMessage,
    } = this.state;
    let bidDetails = null;
    if (orderDetails?.orderedBids?.length > 0) {
      bidDetails = orderDetails?.orderedBids[0];
    }
    console.log("JsonData", JsonData);
    console.log("NewAddressData", this.props);
    console.log("orderDetails", orderDetails);
    console.log("paymentStatus", orderDetails?.paymentStatus);
    const isMultipleLots = orderDetails?.orderedBids?.length > 1 ? true : false;
    const totalText = isMultipleLots ? "Total " : "";
    const lotAmountS = 3434444.8777;

    const estimatedText =
      JsonData.shippingAddressId || !isShipToMe ? "" : "Estimated";
    return (
      <>
        {isLoading && <Loader />}
        {isPageloading ? (
          <>
            <Row className="spinner_Loading_iconRow">
              <div className="spinner_Loading_icon"></div>
            </Row>
          </>
        ) : (
          <>
            {orderDetails?.paymentStatus == "Paid" ? (
              <>
                <Row className="itempurchaseContainer item-status-completed-sec">
                  <Col md="8" className="noPadding">
                    <span className="paymentOrderTitle noPadding">
                      Payment For Order Id : {orderDetails?.orderNo}
                    </span>

                    <p className="paymentOrderTitle noPadding item-status-sec-p">
                      Status : {` `}
                      {orderDetails?.orderStatus}
                    </p>
                  </Col>
                </Row>
              </>
            ) : (
              <Row className="itempurchaseContainer">
                <Col md="8" className="noPadding left-side-box-content">
                  <span className="paymentOrderTitle noPadding">
                    Payment For Order Id: {orderDetails?.orderNo}
                  </span>
                  <div>
                    <div className="box-header-sec">
                      <span>1. Personal Details</span>
                      <span>
                        {activeTab !== "personal" && (
                          <button
                            onClick={() => {
                              this.handleContinuePersonal("personal");
                            }}
                          >
                            CHANGE
                          </button>
                        )}
                      </span>
                    </div>
                    {activeTab === "personal" && (
                      <div className="box-content-sec">
                        <Col md="12" className="pdStyles user-details-sec-info">
                          <Col md="12" className="noPadding pFormRow">
                            <Col md="12" className="pForm">
                              <Form.Label>Title</Form.Label>
                              <Form.Control
                                disabled={true}
                                name="title"
                                className="paymentTitleField"
                                as="select"
                              >
                                <option value="Select"> Select</option>
                                <option
                                  selected={
                                    orderDetails?.title == "Mr" ||
                                    orderDetails?.title == "Mr."
                                      ? true
                                      : false
                                  }
                                  value="Mr."
                                >
                                  {" "}
                                  Mr.
                                </option>
                                <option
                                  selected={
                                    orderDetails?.title == "Mrs" ||
                                    orderDetails?.title == "Mrs."
                                      ? true
                                      : false
                                  }
                                  value="Mrs."
                                >
                                  {" "}
                                  Mrs.
                                </option>
                                <option
                                  selected={
                                    orderDetails?.title == "Miss" ||
                                    orderDetails?.title == "Miss."
                                      ? true
                                      : false
                                  }
                                  value="Miss"
                                >
                                  {" "}
                                  Miss
                                </option>
                              </Form.Control>
                            </Col>
                          </Col>
                          <Col
                            md="12"
                            className={
                              isMobile ? "noPadding " : "noPadding pFormRow"
                            }
                          >
                            <Col md="6" className="pForm ">
                              <Form.Label>First Name</Form.Label>
                              <Form.Control
                                type="text"
                                name="firstName"
                                disabled={true}
                                value={orderDetails?.firstName}
                              />
                            </Col>
                            <Col md="6" className="pForm ">
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control
                                type="text"
                                name="lastName"
                                disabled={true}
                                value={orderDetails?.lastName}
                              />
                            </Col>
                          </Col>
                          <Col
                            md="12"
                            className={
                              isMobile ? "noPadding " : "noPadding pFormRow"
                            }
                          >
                            <Col md="6" className="pForm ">
                              <Form.Label>Email Address</Form.Label>
                              <Form.Control
                                type="email"
                                name="email"
                                disabled={true}
                                value={orderDetails?.email}
                              />
                            </Col>
                            <Col md={6} className="signup-flow-phone-pe">
                              <div className="mobile-number-col">
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                  <Form.Label>Mobile Number </Form.Label>
                                  <div className="phone-control-fields-sec">
                                    <div
                                      className={
                                        this.state?.phoneCodeError
                                          ? "phone-control-fields-sec-left error_field_sec_item_P"
                                          : "phone-control-fields-sec-left"
                                      }
                                    >
                                      <Form.Control
                                        className="phoneCodeSelect"
                                        isInvalid={
                                          addressErrorFields?.phoneCode
                                        }
                                        value={JsonData.phoneCode}
                                        onChange={this.changeValue}
                                        name="phoneCode"
                                        as="select"
                                      >
                                        <MobileNumbersList />
                                      </Form.Control>
                                    </div>
                                    <div
                                      className={
                                        this.state?.phoneNumberError
                                          ? "phone-control-fields-sec-right error_field_sec_item_P"
                                          : "phone-control-fields-sec-right"
                                      }
                                    >
                                      <Form.Control
                                        type="text"
                                        maxLength={12}
                                        className={
                                          isphoneNumberEmpty
                                            ? "emptyPhoneNumberField"
                                            : ""
                                        }
                                        name="phoneNumber"
                                        onChange={this.changeValue}
                                        value={formatPhoneNumber(
                                          JsonData?.phoneNumber
                                        )}
                                      />
                                    </div>
                                  </div>
                                </Form.Group>
                              </div>
                            </Col>
                          </Col>
                        </Col>
                        <div className="continue-shipping-details-sec">
                          <button
                            onClick={() => {
                              this.handleContinuePersonalBtn("shipping");
                            }}
                          >
                            CONTINUE TO SHIPPING DETAILS
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    <div className="box-header-sec">
                      <span>2. Shipping Details</span>
                      <span>
                        {(activeTab === "payment" || activeTab === "lots") && (
                          <button
                            onClick={() => {
                              this.handleContinuePersonal("shipping");
                            }}
                          >
                            CHANGE
                          </button>
                        )}
                      </span>
                    </div>
                    {activeTab === "shipping" && (
                      <div className="box-content-sec box-content-sec-shipping">
                        <div className="ship-to-me-inperson-sec">
                          <div
                            onClick={() => {
                              this.handleClickShopToMe(true);
                            }}
                            className={
                              isShipToMe
                                ? "ship-to-me-sec selected"
                                : "ship-to-me-sec "
                            }
                          >
                            <span>Ship to Me</span>
                          </div>
                          <div
                            onClick={() => {
                              this.handleClickShopToMe(false);
                            }}
                            className={
                              !isShipToMe
                                ? "in-person-sec-sec selected"
                                : "in-person-sec-sec "
                            }
                          >
                            {" "}
                            <span>In-Person Delivery</span>
                          </div>
                        </div>
                        {isShipToMe ? (
                          <Row className="ppdRow">
                            <Col md="12" className="pdStyles shippingDetails">
                              {(userAddress || []).map(
                                (user: any, index: any) => {
                                  return (
                                    <>
                                      <div
                                        onClick={() => {
                                          this.selectAddressBox(user);
                                        }}
                                        className="rowSelectedAddress row-address-list-single-row"
                                      >
                                        <div className="address-row-name-info">
                                          <Form.Check
                                            inline
                                            // onClick={() => {
                                            //   this.selectAddressBox(user);
                                            // }}
                                            type="radio"
                                            checked={
                                              JsonData?.billingAddressId ==
                                              user?.id
                                                ? true
                                                : false
                                            }
                                            label={this.getfullName(user)}
                                            name="select_address"
                                            className="paymentFormRadio"
                                          />
                                          {/* <span className="paymentAddressStyles">
                                      {this.getfullName(user)} ,&nbsp;
                                    </span> */}
                                        </div>
                                        <div className="address-row-address-info">
                                          <span className="paymentAddressStyles">
                                            {this.getfullAddress(user)}
                                          </span>
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            </Col>
                            <div className="item-purchase-add-new-info">
                              <Button
                                variant="custom"
                                className="addNewAddressBtn noBoxShadow"
                                onClick={this.addNewAddress}
                              >
                                {/* <PlusAddress /> */}+ Add a new address
                              </Button>
                              {this.state.addAddress && (
                                <>
                                  {/* <Script
                                  url={GOOGLE_MAP_URL}
                                  onLoad={this.handleScriptLoad}
                                /> */}

                                  <Col
                                    md="12"
                                    className="pdStyles add-new-addess-sec-con-item"
                                  >
                                    <Col md="12" className="noPadding pFormRow">
                                      <Col
                                        md="12"
                                        className="pForm title-sec-add-address"
                                      >
                                        <Form.Label>Title</Form.Label>
                                        <Form.Control
                                          isInvalid={addressErrorFields?.title}
                                          name="title "
                                          onChange={this.changeAddressValue}
                                          className="paymentTitleField noBoxShadow"
                                          as="select"
                                        >
                                          <option value="Select">
                                            {" "}
                                            Select
                                          </option>
                                          <option
                                            selected={
                                              NewAddressData?.title == "Mr"
                                                ? true
                                                : false
                                            }
                                            value="Mr."
                                          >
                                            {" "}
                                            Mr.
                                          </option>
                                          <option
                                            selected={
                                              NewAddressData?.title == "Mrs"
                                                ? true
                                                : false
                                            }
                                            value="Mrs."
                                          >
                                            {" "}
                                            Mrs.
                                          </option>
                                          <option
                                            selected={
                                              NewAddressData?.title == "Miss"
                                                ? true
                                                : false
                                            }
                                            value="Miss"
                                          >
                                            {" "}
                                            Miss
                                          </option>
                                        </Form.Control>
                                      </Col>
                                    </Col>
                                    <Col md="12" className="noPadding pFormRow">
                                      <Col md="6" className="pForm ">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                          isInvalid={
                                            addressErrorFields?.firstName
                                          }
                                          type="text"
                                          name="firstName"
                                          placeholder="First name"
                                          onChange={this.changeAddressValue}
                                          value={NewAddressData?.firstName}
                                        />
                                      </Col>
                                      <Col md="6" className="pForm ">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                          isInvalid={
                                            addressErrorFields?.lastName
                                          }
                                          type="text"
                                          name="lastName"
                                          placeholder="Last name"
                                          onChange={this.changeAddressValue}
                                          value={NewAddressData?.lastName}
                                        />
                                      </Col>
                                    </Col>
                                    <Col md="12" className="noPadding pFormRow">
                                      <Col md="12" className="pForm ">
                                        <Form.Label>Address line 1 </Form.Label>
                                        <Form.Control
                                          isInvalid={
                                            addressErrorFields?.address1
                                          }
                                          type="text"
                                          name="address1"
                                          id="user_item_purchase_add_address_Line1"
                                          onChange={this.changeAddressValue}
                                          placeholder="Address line 1"
                                          value={NewAddressData?.address1}
                                        />
                                      </Col>
                                    </Col>
                                    <Col md="12" className="noPadding pFormRow">
                                      <Col md="12" className="pForm ">
                                        <Form.Label>Address line 2 </Form.Label>
                                        <Form.Control
                                          isInvalid={
                                            addressErrorFields?.address2
                                          }
                                          type="text"
                                          name="address2"
                                          onChange={this.changeAddressValue}
                                          placeholder="Address line 2"
                                          value={NewAddressData?.address2}
                                        />
                                      </Col>
                                    </Col>
                                    <Col md="12" className="noPadding pFormRow">
                                      <Col md="6" className="pForm ">
                                        <Form.Label>City</Form.Label>
                                        <Form.Control
                                          isInvalid={addressErrorFields?.city}
                                          type="text"
                                          name="city"
                                          onChange={this.changeAddressValue}
                                          value={NewAddressData?.city}
                                        />
                                      </Col>
                                      <Col md="6" className="pForm ">
                                        <Form.Label>State</Form.Label>
                                        <Form.Control
                                          isInvalid={addressErrorFields?.state}
                                          type="text"
                                          name="state"
                                          onChange={this.changeAddressValue}
                                          value={NewAddressData?.state}
                                        />
                                      </Col>
                                    </Col>
                                    <Col md="12" className="noPadding pFormRow">
                                      <Col md="6" className="pForm ">
                                        <Form.Label>Country</Form.Label>
                                        <Form.Control
                                          isInvalid={addressErrorFields?.county}
                                          type="text"
                                          name="county"
                                          onChange={this.changeAddressValue}
                                          value={NewAddressData?.county}
                                        />
                                      </Col>
                                      <Col md="6" className="pForm ">
                                        <Form.Label>Postal Code</Form.Label>
                                        <Form.Control
                                          isInvalid={
                                            addressErrorFields?.zipPostalCode
                                          }
                                          maxLength={6}
                                          type="text"
                                          name="zipPostalCode"
                                          onChange={this.changeAddressValue}
                                          value={NewAddressData?.zipPostalCode}
                                        />
                                      </Col>
                                    </Col>

                                    <Col
                                      md={6}
                                      className="signup-flow-phone-pe"
                                    >
                                      <div className="mobile-number-col">
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                          <Form.Label>
                                            Mobile Number{" "}
                                          </Form.Label>
                                          <div className="phone-control-fields-sec">
                                            <div className="phone-control-fields-sec-left">
                                              <Form.Control
                                                className="phoneCodeSelect"
                                                isInvalid={
                                                  addressErrorFields?.phoneCode
                                                }
                                                value={NewAddressData.phoneCode}
                                                onChange={
                                                  this.changeAddressValue
                                                }
                                                name="phoneCode"
                                                as="select"
                                              >
                                                <MobileNumbersList />
                                              </Form.Control>
                                            </div>
                                            <div className="phone-control-fields-sec-right">
                                              <Form.Control
                                                className="phoneNumberStylesItem"
                                                isInvalid={
                                                  addressErrorFields?.phoneNumber
                                                }
                                                type="text"
                                                value={formatPhoneNumber(
                                                  NewAddressData?.phoneNumber
                                                )}
                                                maxLength={12}
                                                name="phoneNumber"
                                                placeholder="Mobile Number"
                                                onChange={
                                                  this.changeAddressValue
                                                }
                                              />
                                            </div>
                                          </div>
                                        </Form.Group>
                                      </div>
                                    </Col>
                                    {/* <Col md={4} className="pForm ">
                                      <Form.Group
                                        controlId="exampleForm.ControlSelect1"
                                        className="twoBoxItem"
                                      >
                                        <Form.Label className="emptyLabelITem"></Form.Label>
                                        <Form.Control
                                          className="phoneNumberStylesItem"
                                          isInvalid={
                                            addressErrorFields?.phoneNumber
                                          }
                                          type="text"
                                          value={NewAddressData?.phoneNumber}
                                          name="phoneNumber"
                                          placeholder="Mobile Number"
                                          onChange={this.changeAddressValue}
                                        />
                                      </Form.Group>
                                    </Col> */}
                                    {/* </Col> */}
                                    <Button
                                      variant="custom"
                                      onClick={this.saveNewAddress}
                                      className="addAddressBtn user-add-address-btn-sec"
                                    >
                                      ADD ADDRESS
                                    </Button>
                                  </Col>
                                </>
                              )}
                            </div>
                          </Row>
                        ) : (
                          <Row className="ppdRow">
                            <div className="in-person-sec-sec-content">
                              We will only deliver in-persons in the New York
                              Metropolitan area. if you are interested in our
                              New Metropolitan area delivery option, please
                              contact{" "}
                              <a href="mailto:shipping@fineart.hiphop">
                                shipping@fineart.hiphop
                              </a>{" "}
                              to make arrangements.
                            </div>
                          </Row>
                        )}
                        {isaddressEmpty != "" && (
                          <Row className="ppdRow">
                            <div className="warning_text">{isaddressEmpty}</div>
                          </Row>
                        )}
                        {shippingErrorMessage && (
                          <p className="error_message">
                            {shippingErrorMessage}
                          </p>
                        )}

                        <div className="continue-shipping-details-sec">
                          <button
                            className="delivery-here-add-address"
                            disabled={
                              this.state.addAddress ||
                              userAddress?.length === 0 ||
                              shippingErrorMessage
                                ? true
                                : false
                            }
                            onClick={() => {
                              this.handleContinueDeliver("lots");
                              window.scrollTo(0, 0);
                            }}
                          >
                            {isShipToMe
                              ? "DELIVER HERE"
                              : "CONTINUE TO LOT DETAILS"}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    <div className="box-header-sec">
                      <span>3. Lot Details</span>
                      <span>
                        {activeTab === "payment" && (
                          <button
                            onClick={() => {
                              this.handleContinuePersonal("lots");
                            }}
                          >
                            CHECK LOTS
                          </button>
                        )}
                      </span>
                    </div>
                    {activeTab === "lots" && (
                      <div className="box-content-sec box-content-sec-shipping">
                        <div className="item-purchase-lot-details">
                          <div className="item-purchase-lot-details-con">
                            <div className="auction-title-header">
                              Auction: {orderDetails?.collectionTitle}
                            </div>
                            {orderDetails?.orderedBids?.map((lotItem: any) => {
                              return (
                                <>
                                  <div className="item-purchase-lot-details-row">
                                    <div className="purchase-lot-row-top">
                                      <span>Lot Details</span>
                                      <span>Order Summary </span>
                                    </div>

                                    <div className="purchase-lot-row-bottom">
                                      <div className="purchase-lot-row-bottom-left">
                                        <div className="purchase-lot-row-img">
                                          <img src={lotItem?.imageUrl}></img>{" "}
                                        </div>
                                        <div className="purchase-lot-row-title-con">
                                          <p>
                                            <span>{`Lot ${lotItem?.sno}`}</span>
                                          </p>
                                          <p className="item-pur-lot-title-con">
                                            {lotItem?.collectionItemTitle}{" "}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="purchase-lot-row-bottom-right">
                                        <div className="total-itmes-list-row">
                                          <div className="namesColumn">
                                            <span>Hammer Price:</span>
                                          </div>
                                          <div className="valuesColumn">
                                            <span>
                                              {displayAmount(lotItem?.amount)}{" "}
                                              {lotItem?.currency}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="total-itmes-list-row order-checkout-green-text">
                                          <div className="sale-amount-text green-color-amount-text mt-1">
                                            {convertSelectedCountryCurrency(
                                              lotItem?.amount,
                                              this.props
                                                .selectedSecondaryCurrency
                                            )}{" "}
                                            {this.props
                                              .selectedSecondaryCurrency
                                              ?.base_code !== "USD" &&
                                              this.props
                                                .selectedSecondaryCurrency
                                                ?.base_code}
                                          </div>
                                        </div>
                                        <div className="total-itmes-list-row">
                                          <div className="namesColumn">
                                            <span>Buyer’s Premium:</span>
                                          </div>
                                          <div className="valuesColumn">
                                            <span>
                                              {/* {parseInt(
                                              lotItem?.buyerPremium?.toFixed(2)
                                            )?.toLocaleString()} */}
                                              {displayAmount(
                                                lotItem?.buyerPremium
                                              )}{" "}
                                              {lotItem?.currency}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="total-itmes-list-row order-checkout-green-text">
                                          <div className="sale-amount-text green-color-amount-text mt-1">
                                            {convertSelectedCountryCurrency(
                                              lotItem?.buyerPremium,
                                              this.props
                                                .selectedSecondaryCurrency
                                            )}{" "}
                                            {this.props
                                              .selectedSecondaryCurrency
                                              ?.base_code !== "USD" &&
                                              this.props
                                                .selectedSecondaryCurrency
                                                ?.base_code}
                                          </div>
                                        </div>
                                        {/* {isShipToMe && (
                                        <div className="total-itmes-list-row">
                                          <div className="namesColumn">
                                            <span>
                                              {" "}
                                              {estimatedText} Shipping Fee:
                                            </span>
                                          </div>
                                          <div className="valuesColumn">
                                            <span>
                                              {displayAmount(
                                                lotItem?.shippingFee
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      )} */}
                                        <div className="total-itmes-list-row">
                                          <div className="namesColumn">
                                            <span>
                                              {estimatedText} Sales Tax:
                                            </span>
                                          </div>
                                          <div className="valuesColumn">
                                            <span>
                                              {displayAmount(lotItem?.salesTax)}{" "}
                                              {lotItem?.currency}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="total-itmes-list-row order-checkout-green-text">
                                          <div className="sale-amount-text green-color-amount-text mt-1">
                                            {this.props
                                              .selectedSecondaryCurrency
                                              .base_code !== "USD" &&
                                              this.props
                                                .selectedSecondaryCurrency
                                                ?.base_code && (
                                                <>
                                                  {convertSelectedCountryCurrency(
                                                    lotItem?.salesTax,
                                                    this.props
                                                      .selectedSecondaryCurrency
                                                  )
                                                    ? convertSelectedCountryCurrency(
                                                        lotItem?.salesTax,
                                                        this.props
                                                          .selectedSecondaryCurrency
                                                      )
                                                    : 0}{" "}
                                                </>
                                              )}
                                            {this.props
                                              .selectedSecondaryCurrency
                                              ?.base_code !== "USD" &&
                                              this.props
                                                .selectedSecondaryCurrency
                                                ?.base_code}
                                          </div>
                                        </div>
                                        {/* <div className="total-itmes-list-row">
                                        <div className="namesColumn">
                                          <span>Administration Fee:</span>
                                        </div>
                                        <div className="valuesColumn">
                                          <span>
                                            {displayAmount(
                                              lotItem?.transactionFee
                                            )}
                                          </span>
                                        </div>
                                      </div> */}
                                        <div className="total-itmes-list-row text-bold-item-purchase total-itmes-list-row-total">
                                          <div className="namesColumn">
                                            <span>
                                              Total Lot Purchase Price:
                                            </span>
                                          </div>
                                          <div className="valuesColumn">
                                            <span className="totalPayableAmt">
                                              {displayAmount(
                                                lotItem?.totalAmount
                                              )}{" "}
                                              {lotItem?.currency}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="total-itmes-list-row order-checkout-green-text">
                                          <div className="sale-amount-text green-color-amount-text mt-1">
                                            {convertSelectedCountryCurrency(
                                              lotItem?.totalAmount,
                                              this.props
                                                .selectedSecondaryCurrency
                                            )}{" "}
                                            {this.props
                                              .selectedSecondaryCurrency
                                              ?.base_code !== "USD" &&
                                              this.props
                                                .selectedSecondaryCurrency
                                                ?.base_code}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>

                        <div className="continue-shipping-details-sec">
                          <button
                            className="delivery-here-add-address"
                            disabled={
                              this.state.addAddress || userAddress?.length === 0
                                ? true
                                : false
                            }
                            onClick={() => {
                              this.handleContinueDeliver("payment");
                              window.scrollTo(0, 0);
                            }}
                          >
                            {"CONTINUE TO PAYMENT DETAILS"}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    <div className="box-header-sec">
                      <span>4. Payment Details</span>
                      <span>{/* <button >Change</button> */}</span>
                    </div>
                    {activeTab === "payment" && (
                      <div className="box-content-sec box-content-sec-payment">
                        <div>
                          {" "}
                          <div className="payment-details-terms-sec">
                            <p>
                              By clicking the “PAY” button, you confirm that you
                              have read, understand, and accept our{" "}
                              <a target="_blank" href="/termsandconditions">
                                Terms &amp; Conditions
                              </a>{" "}
                              and{" "}
                              <a target="_blank" href="/privacypolicy">
                                Privacy Policy
                              </a>
                            </p>
                          </div>
                          <div className="main-pay-now-btn">
                            <PaymentForm
                              applicationId={square_applicationId}
                              locationId={square_locationId}
                              cardTokenizeResponseReceived={async (
                                token,
                                verifiedBuyer
                              ) => {
                                console.log("tokentoken", token);
                                console.log("verifiedBuyer", verifiedBuyer);
                                this.makePayNowAPICall(token.token);
                              }}
                              createPaymentRequest={() => ({
                                countryCode: "US",
                                currencyCode: "USD",
                                total: {
                                  // amount: "100",
                                  amount: `${orderDetails?.totalAmount}`,
                                  label: "Total",
                                },
                              })}
                            >
                              <CreditCard
                                buttonProps={{
                                  css: {
                                    backgroundColor: "#111111",
                                    fontSize: "14px",
                                    color: "#fff",
                                    "&:hover": {
                                      backgroundColor: "#111111",
                                    },
                                  },
                                }}
                              />
                              <div className="ach-bank-btn">
                                <p className="ach-or-button">OR</p>
                                <Ach
                                  accountHolderName={`${orderDetails?.firstName} ${orderDetails?.lastName}`}
                                  redirectURI={`${DOMAIN_URL}/paymentSuccess`}
                                  transactionId={orderDetails?.orderId}
                                />
                              </div>
                            </PaymentForm>
                          </div>{" "}
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    {/* <PaymentForm>
                      <Ach
                        accountHolderName="John Doe"
                        redirectURI="https://example.com/"
                        transactionId="12345"
                      />
                    </PaymentForm> */}
                  </div>
                  {isErrorCard != "" && (
                    <Row>
                      <div className="warning_text">{isErrorCard}</div>
                    </Row>
                  )}
                </Col>
                <Col md="4" className="noPadding">
                  <span className="paymentOrderSummary">Order Summary</span>
                  <div className="orderSummaryStyles">
                    {/* {!isMultipleLots && (
                      <>
                        <Row>
                          <Col md="3">
                            <img
                              src={bidDetails?.imageUrl}
                              className="paymentItemImage"
                            />
                          </Col>
                          <Col className="itemTitleAmount">
                            <p className="paymentItemTitle">
                              {" "}
                              {bidDetails?.collectionItemTitle}
                            </p>
                            <p className="yourBidAmount">
                              <span className="yourBidTitle">Your Bid: </span>
                              {orderDetails?.currency}{" "}
                              {displayAmount(bidDetails?.amount)}
                            </p>
                          </Col>
                        </Row>
                        <hr className="orderSummaryHR" />
                      </>
                    )} */}

                    <div className="total-itmes-list">
                      <div className="total-itmes-list-row">
                        <div className="namesColumn">
                          {isMultipleLots ? (
                            <span>{`${totalText}Hammer Price (${orderDetails?.orderedBids?.length} Lots)`}</span>
                          ) : (
                            <span>{totalText}Hammer Price</span>
                          )}
                        </div>
                        <div className="valuesColumn">
                          <span>
                            {/* {lotAmountS?.toFixed(2)?.toLocaleString()} */}
                            {displayAmount(orderDetails?.bidAmount)}{" "}
                            {orderDetails?.currency}
                          </span>
                        </div>
                      </div>
                      <div className="total-itmes-list-row order-checkout-green-text">
                        <div className="sale-amount-text green-color-amount-text mt-1">
                          {convertSelectedCountryCurrency(
                            orderDetails?.bidAmount,
                            this.props.selectedSecondaryCurrency
                          )}{" "}
                          {this.props.selectedSecondaryCurrency?.base_code !==
                            "USD" &&
                            this.props.selectedSecondaryCurrency?.base_code}
                        </div>
                      </div>
                      <div className="total-itmes-list-row">
                        <div className="namesColumn">
                          <span>{totalText}Buyer’s Premium</span>
                        </div>
                        <div className="valuesColumn">
                          <span>
                            {displayAmount(orderDetails?.buyerPremium)}{" "}
                            {orderDetails?.currency}
                          </span>
                        </div>
                      </div>
                      <div className="total-itmes-list-row order-checkout-green-text">
                        <div className="sale-amount-text green-color-amount-text mt-1">
                          {convertSelectedCountryCurrency(
                            orderDetails?.buyerPremium,
                            this.props.selectedSecondaryCurrency
                          )}{" "}
                          {this.props.selectedSecondaryCurrency?.base_code !==
                            "USD" &&
                            this.props.selectedSecondaryCurrency?.base_code}
                        </div>
                      </div>
                      {isShipToMe && (
                        <>
                          <div className="total-itmes-list-row">
                            <div className="namesColumn">
                              <span>
                                {totalText}
                                {estimatedText} Shipping Fee
                              </span>
                            </div>
                            <div className="valuesColumn">
                              <span>
                                {displayAmount(orderDetails?.shippingFee)}{" "}
                                {orderDetails?.currency}
                              </span>
                            </div>
                          </div>
                          <div className="total-itmes-list-row order-checkout-green-text">
                            <div className="sale-amount-text green-color-amount-text mt-1">
                              {convertSelectedCountryCurrency(
                                orderDetails?.shippingFee,
                                this.props.selectedSecondaryCurrency
                              )}{" "}
                              {this.props.selectedSecondaryCurrency
                                ?.base_code !== "USD" &&
                                this.props.selectedSecondaryCurrency?.base_code}
                            </div>
                          </div>
                        </>
                      )}

                      <div className="total-itmes-list-row">
                        <div className="namesColumn">
                          <span>
                            {totalText}
                            {estimatedText} Sales Tax
                          </span>
                        </div>
                        <div className="valuesColumn">
                          <span>
                            {displayAmount(orderDetails?.salesTax)}{" "}
                            {orderDetails?.currency}
                          </span>
                        </div>
                      </div>
                      <div className="total-itmes-list-row order-checkout-green-text">
                        <div className="sale-amount-text green-color-amount-text mt-1">
                          {this.props.selectedSecondaryCurrency?.base_code !==
                            "USD" &&
                            this.props.selectedSecondaryCurrency?.base_code && (
                              <>
                                {convertSelectedCountryCurrency(
                                  orderDetails?.salesTax,
                                  this.props.selectedSecondaryCurrency
                                )
                                  ? convertSelectedCountryCurrency(
                                      orderDetails?.salesTax,
                                      this.props.selectedSecondaryCurrency
                                    )
                                  : 0}
                              </>
                            )}{" "}
                          {this.props.selectedSecondaryCurrency?.base_code !==
                            "USD" &&
                            this.props.selectedSecondaryCurrency?.base_code}
                        </div>
                      </div>
                      {/* <div className="total-itmes-list-row">
                        <div className="namesColumn">
                          <span>{totalText}Administration Fee</span>
                        </div>
                        <div className="valuesColumn">
                          <span>
                            {displayAmount(orderDetails?.transactionTax)}
                          </span>
                        </div>
                      </div> */}
                      <div className="total-itmes-list-row text-bold-item-purchase total-itmes-list-row-total">
                        <div className="namesColumn">
                          <span>Total Lot Purchase Price</span>
                        </div>
                        <div className="valuesColumn">
                          <span className="totalPayableAmt">
                            {displayAmount(orderDetails?.totalAmount)}{" "}
                            {orderDetails?.currency}
                          </span>{" "}
                        </div>
                      </div>
                      <div className="total-itmes-list-row order-checkout-green-text">
                        <div className="sale-amount-text green-color-amount-text mt-1">
                          {convertSelectedCountryCurrency(
                            orderDetails?.totalAmount,
                            this.props.selectedSecondaryCurrency
                          )}{" "}
                          {this.props.selectedSecondaryCurrency?.base_code !==
                            "USD" &&
                            this.props.selectedSecondaryCurrency?.base_code}
                        </div>
                      </div>
                      <div className="total-itmes-list-row text-bold-item-purchase">
                        <div className="namesColumn">
                          <span>Balance Due</span>
                        </div>
                        <div className="valuesColumn">
                          <span className="totalPayableAmt">
                            {displayAmount(orderDetails?.totalAmount)}{" "}
                            {orderDetails?.currency}
                          </span>
                        </div>
                      </div>
                      <div className="total-itmes-list-row order-checkout-green-text">
                        <div className="sale-amount-text green-color-amount-text mt-1">
                          {convertSelectedCountryCurrency(
                            orderDetails?.totalAmount,
                            this.props.selectedSecondaryCurrency
                          )}{" "}
                          {this.props.selectedSecondaryCurrency?.base_code !==
                            "USD" &&
                            this.props.selectedSecondaryCurrency?.base_code}
                        </div>
                      </div>
                    </div>

                    {/* {isLoading && (
                      <Row className="spinner_Loading_iconRow">
                        <div className="spinner_Loading_icon">
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      </Row>
                    )} */}
                    {paymentError && !isLoading && (
                      <Row className="detailsRow">
                        <div className="Text_paymentError">{paymentError}</div>
                      </Row>
                    )}

                    <Row className="payNowBtnText ">
                      {/* <Button
                        className="noBoxShadow"
                        disabled={isLoading}
                        onClick={this.payNowClick}
                      >
                        {`Pay Now Test - `}
                        {orderDetails?.currency}
                        {` `} {orderDetails?.totalAmount} {`>`}
                      </Button> */}
                    </Row>
                    {/* <PaymentButton
                      close={() => {
                        this.setState({ showPaymentPopup: false });
                      }}
                      makePayNowAPICall={this.makePayNowAPICall}
                      show={showPaymentPopup}
                    /> */}
                  </div>
                </Col>
              </Row>
            )}
          </>
        )}
      </>
    );
  }
}

// export default ItemPurchases;

const mapStateToProps = (state: any) => {
  return state;
};

export default connect(
  mapStateToProps,
  actionCreators
)(withRouter(ItemPurchases));
