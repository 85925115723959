import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import { Tabs, Tab, Row, Modal } from "react-bootstrap";
import FiltersSection from "./FiltersSection";
import { Button } from "react-bootstrap";
import moment from "moment";
import BrowseTabGridContent from "../BrowseTabGridContent";
import BrowseTabListContent from "../BrowseTabListContent";
import ContentLoaderSection from "./../../components/ContentLoaderSection/ContentLoaderSection";

import { ReactComponent as SearchIcon } from "./../../assets/SearchIcon.svg";
import { ReactComponent as CloseIcon } from "../../assets/image/closeIcon.svg";
import MyActiveBids from "./../../pages/collectionDetails/MyActiveBids";
import "./css/BrowseListTabMenu.css";
import { isMobile } from "react-device-detect";
import ActiveBidInfo from "../../pages/PlaceBid/ActiveBidInfo";
import InfoIcon from "../../assets/infoicon-black.png";
import { Tabs as AntdTabs } from "antd";

interface Props {
  currenciesList?: any;
  timezonesList?: any;
  collectionData?: any;
  collectionItems?: any;
  searchClick?: any;
  sortColumnChange?: any;
  collectionItemsFull?: any;
  getUpdatedDataCall?: any;
  followBid?: any;
  unFollowBid?: any;
  clickViewMore?: any;
  totalCount?: any;
  isLoading?: any;
  isPastAuction?: any;
  onHovering?: any;
  biddingShowNo?: any;
  biddingShowText?: any;
  locationSearch?: any;
  isClosedCollection?: any;
  getCollectionDataCall?: any;
  sortStatusChange?: any;
  registertoBid?: any;
  handleOpenModal?: any;
  registerButon?: any;
}

export const BrowseListTabMenu: React.FC<Props> = (Props) => {
  const [searchText, setSearchText] = useState("");
  const [selectedTab, setSelectedTab] = useState("browselots");
  const [activeKey, setActiveKey] = React.useState("1");
  const [isSticky, setSticky] = useState(false);
  const [activeTab, setActiveTab] = useState("overview");

  const onChange = (key: any) => {
    setActiveKey(key);
  };
  const createMarkup = (data: any) => {
    return { __html: data };
  };
  const itemCount = Props?.totalCount ?? 0;

  // let bannerTimeZone = "vinod";
  // let bannerTimeZone = Props?.currenciesList.find((i:any) => Props?.collectionData?.currency == i?.id)?.currencyCode;
  let bannerTimeZone = Props?.timezonesList?.find(
    (i: any) => Props?.collectionData?.timeZoneId == i?.id
  )?.abbrivation;
  var userDetails = {} as any;
  const userD = localStorage.getItem("customerInfoDetails");
  if (userD != undefined && userD != null && userD != "") {
    userDetails = JSON.parse(userD);
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  useEffect(() => {
    if (Props?.locationSearch) {
      if (Props?.locationSearch === "?section=myActiveBids") {
        setSelectedTab("MyActiveBids");
        document
          .getElementById("uncontrolled-tab-example-tab-MyActiveBids")
          ?.click();
      } else {
        setSelectedTab("browselots");
      }
    }
  }, [Props?.locationSearch, Props?.biddingShowText]);
  const handleTabChange = (e: any) => {
    setSelectedTab(e);
    localStorage.setItem("handleCollectionSelectedTab", e);
    setSticky(false);
  };
  const handleScroll = () => {
    const totalHee =
      window.innerHeight + document.documentElement.scrollTop + 950;
    const totalH = document.documentElement.offsetHeight;
    const valSelec = localStorage.getItem("handleCollectionSelectedTab");
    if (totalHee > totalH && totalH > 3000 && valSelec !== "MyActiveBids") {
      getMoreRecords();
    }
  };
  const getMoreRecords = () => {
    Props?.clickViewMore();
  };

  const [showSearchCalenders, setshowSearchCalenders] = useState(false);
  const handleClose = () => setshowSearchCalenders(false);
  const openSearchButton = () => {
    setshowSearchCalenders(true);
  };
  let currencyName = Props.currenciesList?.find(
    (i: any) => Props?.collectionData?.currency == i?.id
  )?.currencyCode;

  const handleCheckLocaltime = (time: any) => {
    if (time) {
      const formatedTime = moment.utc(time).local().format("YYYY-MM-DD HH:mm");

      const expiresOnDate = new Date(formatedTime);

      // Get the current date and time
      const currentDate = new Date();

      if (currentDate < expiresOnDate) {
        return true;
      } else {
        return false;
      }
    }
  };

  const overviewRef = useRef<HTMLDivElement>(null);
  const auctionDetailsRef = useRef<HTMLDivElement>(null);
  const conditionsOfSaleRef = useRef<HTMLDivElement>(null);

  const handleTabClick = (key: string) => {
    const sectionRefs: { [key: string]: React.RefObject<HTMLDivElement> } = {
      overview: overviewRef,
      "auction-details": auctionDetailsRef,
      "condition-of-sale": conditionsOfSaleRef,
    };
    const targetRef = sectionRefs[key];
    if (targetRef?.current) {
      const offset = targetRef.current.offsetTop - (isSticky ? 220 : 270);
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const navbar = document.getElementById("sticky-tabs");
    const footer = document.getElementById("footer"); // Replace with your footer element ID

    const handleScroll = () => {
      if (navbar && footer) {
        const stickyOffset = navbar.offsetTop;
        const footerOffset = footer.offsetTop;
        const footerHeight = footer.offsetHeight;

        // Check if the scroll position has reached the bottom of the footer
        if (
          window.pageYOffset + window.innerHeight >=
          footerOffset + footerHeight
        ) {
          setSticky(false); // Remove sticky when the footer is reached
        } else {
          setSticky(window.pageYOffset - 100 >= stickyOffset); // Keep sticky when the navbar is in view
        }
      }

      const scrollPosition = window.scrollY; // Adjust to suit when tab becomes active
      const sectionRefs: { [key: string]: React.RefObject<HTMLDivElement> } = {
        overview: overviewRef,
        "auction-details": auctionDetailsRef,
        "condition-of-sale": conditionsOfSaleRef,
      };

      Object.keys(sectionRefs).forEach((key) => {
        const currentSection = sectionRefs[key];
        if (currentSection?.current) {
          const sectionTop = currentSection.current.offsetTop - 500;
          const sectionBottom =
            sectionTop + currentSection.current.offsetHeight;
          if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
            setActiveTab(key);
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSticky]); // Add other dependencies if necessary

  const items = [
    {
      key: "overview",
      label: "Overview",
    },
    {
      key: "auction-details",
      label: "Auction Details",
    },
    {
      key: "condition-of-sale",
      label: "Conditions of Sale",
    },
  ];

  return (
    <>
      <div className="action_calendar_right_tab browes_tab_right_tab">
        <Tabs
          defaultActiveKey={selectedTab}
          id="uncontrolled-tab-example"
          onChange={handleTabChange}
          onSelect={handleTabChange}
        >
          <Tab
            eventKey="browselots" //browselots MyActiveBids
            title={`Browse Lots (${Props?.totalCount})`}
          >
            <div className="browes_tab_content d-flex flex-wrap position-relative">
              {/* <div className="filter_list">
                <BrowseFilterList
                  collectionItemsFull={Props?.collectionItemsFull}
                  getUpdatedDataCall={Props?.getUpdatedDataCall}
                  collectionData={Props.collectionData}
                  currenciesList={Props.currenciesList}
                  sortColumnChange={Props?.sortColumnChange}
                />
              </div> */}
              <div className="filter_list_data_main position-relative">
                <div className="filter_list_sort_section d-flex justify-content-between align-items-center my-3">
                  <div className="collection-filter-sec">
                    <div>
                      <FiltersSection
                        collectionItemsFull={Props?.collectionItemsFull}
                        getUpdatedDataCall={Props?.getUpdatedDataCall}
                        collectionData={Props.collectionData}
                        currenciesList={Props.currenciesList}
                        sortColumnChange={Props?.sortColumnChange}
                      />
                    </div>
                    <div
                      className={`{ ${isMobile ? " " : "sort-bu-sec-content"}`}
                    >
                      <div className="filter_list_sort_section_sory_by">
                        Sort By:
                      </div>
                      <Form.Group
                        controlId="exampleForm.ControlSelect1"
                        className="custom_select_dropdown d-lg-flex d-none  align-items-center overflow-hidden mb-0"
                      >
                        <Form.Control
                          as="select"
                          className="sortBySelect"
                          onChange={Props?.sortColumnChange}
                        >
                          <option value="Sno_asc">
                            Lot Number (Low to High)
                          </option>
                          <option value="Sno_desc">
                            Lot Number (High to Low)
                          </option>
                          <option value="EstimatedPrice_asc">
                            Estimate (Low to High)
                          </option>
                          <option value="EstimatedPrice_desc">
                            Estimate (High to Low)
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </div>
                  </div>
                  {/* <div className="sort-bu-sec-content ">
                    <div className="filter_list_sort_section_sory_by d-none">
                      Status:
                    </div>
                    <Form.Group
                      controlId="exampleForm.ControlSelect1"
                      className="custom_select_dropdown d-lg-flex d-none  align-items-center overflow-hidden mb-0"
                    >
                      <Form.Label className="mb-0 lightColor">
                        Status:
                      </Form.Label>
                      <Form.Control
                        as="select"
                        className="sortBySelect"
                        onChange={Props?.sortStatusChange}
                      >
                        <option value="all_Lots">All Lots</option>
                        <option value="open_lots">Open Lots</option>
                        <option value="my_lots">My Bids</option>
                        <option value="saved_lots">Saved Lots</option>
                      </Form.Control>
                    </Form.Group>
                  </div> */}
                  {isMobile ? (
                    <div
                      className="searchBox mobileAutionSearchBtn"
                      onClick={openSearchButton}
                    >
                      <span className="iconBox searchIconBoxMobile">
                        <SearchIcon className="searchBoxIcons" />
                      </span>
                    </div>
                  ) : (
                    <div className="searchBox">
                      <Form.Control
                        type="text"
                        onKeyDown={(e: any) =>
                          e.keyCode === 13 && Props.searchClick(searchText)
                        }
                        onChange={(e: any) => {
                          setSearchText(e.target.value);
                          if (!e.target.value) {
                            Props.searchClick("");
                          }
                        }}
                        placeholder="Search Lots"
                        className="searchBoxTextField"
                      />
                      <span className="iconBox">
                        <SearchIcon
                          onClick={() => {
                            Props.searchClick(searchText);
                          }}
                          className="searchBoxIcon"
                        />
                      </span>
                    </div>
                  )}
                </div>
                <Tabs
                  defaultActiveKey="grid"
                  id="uncontrolled-tab-example"
                  className="grid_list_tab_menu border-0"
                >
                  <Tab eventKey="grid" title="">
                    <BrowseTabGridContent
                      onHovering={Props?.onHovering}
                      currenciesList={Props.currenciesList}
                      collectionData={Props.collectionData}
                      collectionItems={Props.collectionItems}
                      followBid={Props?.followBid}
                      unFollowBid={Props?.unFollowBid}
                      isPastAuction={Props?.isPastAuction}
                      isLoading={Props?.isLoading}
                      bannerTimeZone={bannerTimeZone}
                      registertoBid={Props?.registertoBid}
                      handleOpenModal={Props?.handleOpenModal}
                      registerButon={Props?.registerButon}
                    />
                    {Props?.isLoading && (
                      <div className="spinner_Loading_iconCollectionDetails">
                        <ContentLoaderSection section="grid" />
                      </div>
                    )}
                    {!Props?.isLoading &&
                      Props?.totalCount > Props.collectionItems.length && (
                        <div className="load_more_list_btn text-center">
                          <Button
                            onClick={Props.clickViewMore}
                            className="classViewMore text-white d-inline-block text-decoration-none"
                          >
                            LOAD MORE
                          </Button>
                        </div>
                      )}
                  </Tab>
                  <Tab eventKey="list" title="">
                    <BrowseTabListContent
                      currenciesList={Props.currenciesList}
                      collectionData={Props.collectionData}
                      collectionItems={Props.collectionItems}
                      bannerTimeZone={bannerTimeZone}
                      followBid={Props?.followBid}
                      unFollowBid={Props?.unFollowBid}
                      isPastAuction={Props?.isPastAuction}
                      isLoading={Props?.isLoading}
                      registertoBid={Props?.registertoBid}
                      handleOpenModal={Props?.handleOpenModal}
                      registerButon={Props?.registerButon}
                    />
                    {Props?.isLoading && (
                      <div className="spinner_Loading_icon">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}
                    {!Props?.isLoading &&
                      Props?.totalCount > Props.collectionItems.length && (
                        <div className="load_more_list_btn text-center">
                          <Button
                            onClick={Props.clickViewMore}
                            className="classViewMore text-white d-inline-block text-decoration-none"
                          >
                            LOAD MORE
                          </Button>
                        </div>
                      )}
                  </Tab>
                </Tabs>
              </div>
            </div>
          </Tab>

          <Tab eventKey="auctiondetails" title="Auction Details">
            <div className="sticky-collection-tabs">
              {!isMobile && (
                <>
                  <AntdTabs
                    activeKey={activeTab}
                    onChange={handleTabClick}
                    items={items}
                    // className="profile-tabs"
                    tabPosition="right"
                    id="sticky-tabs"
                    className={`tabs-container profile-tabs ${
                      isSticky ? "sticky" : ""
                    }`}
                    style={{
                      position: isSticky ? "fixed" : "relative",
                      top: isSticky ? 200 : "auto",
                      zIndex: 1000,
                      backgroundColor: "#fff",
                    }}
                  />
                  {isSticky && <div></div>}
                </>
              )}

              <div className="action_tab_content">
                <div
                  className=" filter_list_data_main filter_list_data_main_overview position-relative"
                  id="overview"
                  ref={overviewRef}
                >
                  {/* <div className="coverImage-collection-image">
                  <img src={Props.collectionData?.coverImage} />
                </div> */}
                  <div className="coverImage-collection-details-text">
                    <h2>Overview</h2>
                    <div className="collection-title-sec-overview">
                      <p>{Props.collectionData?.title}</p>
                    </div>
                    <div
                      dangerouslySetInnerHTML={createMarkup(
                        Props.collectionData?.collectionOverview
                      )}
                    />
                  </div>
                </div>
                <div
                  className=" filter_list_data_main position-relative"
                  id="auction-details"
                  ref={auctionDetailsRef}
                >
                  {/* <h3>Auction Details</h3>
                      <p>Auction Details text</p> */}
                  <Tab.Pane
                    eventKey="auctionDetails"
                    className="auction-details"
                  >
                    <h2>Auction Details</h2>
                    <Row className="auctionDetailSpacing">
                      <Row className="auctionNameValues">
                        <p>
                          <span className="auctionDetailsLeft">
                            Auction Name<span>:</span>
                          </span>{" "}
                          <span className="adTitle">
                            {Props.collectionData?.title}{" "}
                          </span>
                        </p>
                        <p>
                          <span className="auctionDetailsLeft">
                            Auction Date<span>:</span>
                          </span>
                          <span className="adTitle month-uppercase ml-0">
                            {Props.collectionData?.auctionType == "1" ? (
                              <>
                                {!Props.collectionData?.onlineStartDate
                                  ? moment(
                                      Props.collectionData?.onlineStartDate
                                    ).format("MMMM D, YYYY")
                                  : moment(
                                      Props.collectionData?.onlineStartDate
                                    ).format("MMMM D ")}
                                {Props.collectionData?.onlineEndDate && (
                                  <>
                                    {` `}-{" "}
                                    {moment(
                                      Props.collectionData?.onlineStartDate
                                    ).format("MM") ===
                                    moment(
                                      Props.collectionData?.onlineEndDate
                                    ).format("MM")
                                      ? moment(
                                          Props.collectionData?.onlineEndDate
                                        ).format("D, YYYY")
                                      : moment(
                                          Props.collectionData?.onlineEndDate
                                        ).format("MMMM D, YYYY")}
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {moment(
                                  Props.collectionData?.liveStartDate
                                ).format("MMMM D, YYYY")}{" "}
                              </>
                            )}
                          </span>
                        </p>
                        <p>
                          <span className="auctionDetailsLeft">
                            {handleCheckLocaltime(
                              Props.collectionData?.startOnUtc
                            )
                              ? "Bidding Starts"
                              : "Lots Begin Closing"}
                            <span>:</span>
                          </span>{" "}
                          <span className="adTitle month-uppercase">
                            {handleCheckLocaltime(
                              Props.collectionData?.startOnUtc
                            ) ? (
                              <span className="">
                                {moment
                                  .utc(Props.collectionData?.startOnUtc)
                                  .tz("America/New_York")
                                  .format("MMMM D, YYYY [(]h:mm A")}{" "}
                                {"ET)"}
                              </span>
                            ) : (
                              <span className="">
                                {moment
                                  .utc(Props.collectionData?.endOnUtc)
                                  .tz("America/New_York")
                                  .format("MMMM D, YYYY [(]h:mm A")}{" "}
                                <>{"ET)"}</>
                              </span>
                            )}
                          </span>
                        </p>
                        <p>
                          <span className="auctionDetailsLeft">
                            Format<span>:</span>
                          </span>{" "}
                          <span className="adTitle">
                            <span className="adOnlineAuction">
                              {" "}
                              {Props.collectionData?.auctionType === 1
                                ? "Online Only"
                                : "Live"}{" "}
                              Auction
                            </span>
                          </span>
                        </p>
                        <p>
                          <span className="auctionDetailsLeft">
                            Number of Lots<span>:</span>
                          </span>{" "}
                          <span className="adTitle"> {itemCount}</span>{" "}
                        </p>
                      </Row>

                      <p className="forAssistText">
                        For assistance with registration and bidding, please
                        contact{" "}
                        <a href="mailto:support@fineart.hiphop">
                          support@fineart.hiphop
                        </a>{" "}
                      </p>
                      <div className="how-bidding-works-sec">
                        <a href="/howBiddingWorks">
                          <button>HOW BIDDING WORKS</button>
                        </a>
                      </div>
                    </Row>
                  </Tab.Pane>
                </div>
                <div
                  className=" filter_list_data_main condition-sale position-relative"
                  id="condition-of-sale"
                  ref={conditionsOfSaleRef}
                >
                  <h2>Conditions of Sale</h2>
                  <div
                    dangerouslySetInnerHTML={createMarkup(
                      Props.collectionData?.conditionOfSale
                    )}
                  />
                </div>
              </div>
            </div>
          </Tab>
          {userDetails?.id && (
            <Tab
              eventKey="MyActiveBids"
              title={
                <div className="d-flex tab-my-active-bid">
                  My Active Bids
                  <ActiveBidInfo
                    popoverText="My Active Bids is 
where you go to view and manage all 
of the Lots in an Auction you've placed 
Bids on."
                    position={"left"}
                    image={InfoIcon}
                    class="place-bid-popover"
                  />
                </div>
              }
            >
              <>
                <div className="action_tab_content">
                  <MyActiveBids
                    biddingShowText={Props?.biddingShowText}
                    biddingShowNo={Props?.biddingShowNo}
                    collectionData={Props?.collectionData}
                    userDetails={userDetails}
                    bannerTimeZone={bannerTimeZone}
                    currencyName={currencyName}
                    getCollectionDataCall={Props?.getCollectionDataCall}
                    isLoadingParent={Props?.isLoading}
                  />
                </div>
              </>
            </Tab>
          )}
        </Tabs>
        <Modal
          className="modalStyleSearchPopup"
          show={showSearchCalenders}
          aria-labelledby="example-modal-sizes-title-lg"
          size="lg"
          onHide={handleClose}
        >
          <Modal.Header>
            <CloseIcon
              className="modalCloseIconSearchPopUp"
              onClick={handleClose}
            />
          </Modal.Header>
          <Modal.Body>
            <div className="searchBoxs d-flex">
              <Form.Control
                type="text"
                onKeyDown={(e: any) =>
                  e.keyCode === 13 && Props.searchClick(searchText)
                }
                onChange={(e: any) => {
                  setSearchText(e.target.value);
                }}
                placeholder="Search..."
                className="col-12 "
              />
              <span className="iconBoxs searchIconPopUp">
                <SearchIcon
                  onClick={() => {
                    Props.searchClick(searchText);
                    setshowSearchCalenders(false);
                  }}
                />
              </span>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
export default BrowseListTabMenu;
