import React, { useEffect, useState } from "react";
import BidWhite from "../../assets/image/bid-white.png";
import * as signalR from "@microsoft/signalr";
import { SERVER_IP } from "./../../library/constants.js";
import PrevBidDetails from "../../components/PrevBidDetails/PrevBidDetails";
import { convertUTCToLMilleSec, convertLMilleSec } from "./../../Scenes/common";

import Form from "react-bootstrap/Form";

import { LOGINID } from "./../../Scenes/common";
import LotDetailsTime from "./../lotDetails/LotDetailsTime";
import {
  getcollectionitembyidCall,
  getbidincrementCall,
  gethighestbid,
} from "./../../library/Requests";

import { MyActiveBidsStyled } from "./MyActiveBids.styled";
import { convertSelectedCountryCurrency } from "../../utils";
import { useSelector } from "react-redux";
const MyBidCard = (props: any) => {
  const {
    myLot,
    bannerTimeZone,
    currencyName,
    userDetails,
    placeQuickBidCall,
    autobidCallBroadCastCall,
    bidIncrementData,
    youHaveHighestBid,
    collectionData,
  } = props;
  const { selectedSecondaryCurrency } = useSelector((state: any) => state);
  const [showTimer, setShowTimer] = useState("loading" as any);
  const [showActionsBtn, setShowActionsBtn] = useState("placebid");
  const [nextBidAmount, setNextBidAmount] = useState("");
  const [bidsMetRP, setBidsMetRP] = useState("0");
  const [selectedMaxBid, setSelectedMaxBid] = useState("");
  const [highestBidResp, setHighestBidResp] = useState({} as any);
  // const [bidIncrementData, setBidIncrementData] = useState([] as any);
  const [dropdownvalues, setDropdownvalues] = useState([] as any);
  const [showProgressBarSec, setShowProgressBarSec] = useState(false);
  const [showPrevBids, setShowPrevBids] = useState(false);
  const [widthProgress, setWidthProgress] = useState("0%");
  const [lotDetails, setLotDetails] = useState({} as any);
  const [myLot1, setMyLot1] = useState({} as any);
  const [isClosedLot, setIsClosedLot] = useState("");
  const [isBiddingClosed, setIsBiddingClosed] = useState(false);

  useEffect(() => {
    if (myLot1?.id !== myLot?.id && myLot?.id) {
      setMyLot1(myLot);
      // getTimeDefault();
      getNextBidAmount();
      if (!youHaveHighestBid) {
        setShowActionsBtn("quick&maxbid");
      }
      getLotDetailsCall();

      const connection = new signalR.HubConnectionBuilder()
        .withUrl(`${SERVER_IP}/hubs/BidHub`)
        .withAutomaticReconnect()
        .build();
      connection.on("BidInsertSignal", (response) => {});
      connection
        .start()
        .then((result) => {
          connection.on("BidInsertSignal", (response) => {
            getLatestDataFromSignal(response);
            console.log("signal responseresponse", response);
          });
        })
        .catch((err) => console.error(err.toString()));
    }
  }, [myLot]);

  const getLatestDataFromSignal = (resp: any) => {
    if (resp?.itemId == myLot?.id) {
      getLotDetailsCall();
      getNextBidAmount();
    }
  };
  const getLotDetailsCall = async () => {
    const collectionItem = await getcollectionitembyidCall(myLot?.id, LOGINID);
    setLotDetails(collectionItem?.data);
    if (collectionItem?.data?.outFromBid) {
      setShowActionsBtn("quick&maxbid");
    }
  };

  const getNextBidAmount = async () => {
    const bidResp = await gethighestbid(myLot?.id);
    setHighestBidResp(bidResp);
    setNextBidAmount(bidResp?.nextBidAmount);

    setBidsMetRP(bidResp?.bidsMetReservedPriceCount);
    if (bidIncrementData?.length < 5) {
      const jsonVal = await getbidincrementCall();
      // setBidIncrementData(jsonVal?.increment);
      generateDropDownvalues(bidResp?.bidDetails, jsonVal?.increment);
    } else {
      generateDropDownvalues(bidResp?.bidDetails, bidIncrementData);
    }
  };
  const generateDropDownvalues = async (
    bidDetails: any,
    bidIncrementData: any
  ) => {
    const iniPrice = bidDetails?.amount
      ? bidDetails?.amount
      : myLot?.initialBiddingPrice;
    if (iniPrice && bidIncrementData) {
      const amou1 = iniPrice;
      let bidAmout = parseInt(amou1);
      let tempFullStr = "";
      (bidIncrementData || []).map((item: any, index: any) => {
        if (item.priceFrom <= bidAmout && item.priceTo >= bidAmout) {
          let increMA = item.increment;
          bidAmout = bidAmout + increMA;
          tempFullStr = tempFullStr + "___" + bidAmout;
          const priceDiff = item.priceTo - item.priceFrom;
          const repeat = (priceDiff / increMA).toFixed();
          const repeatT = parseInt(repeat) + 2;
          for (var i = 0; i < repeatT; i++) {
            if (item.priceFrom <= bidAmout && item.priceTo >= bidAmout) {
              bidAmout = bidAmout + increMA;
              tempFullStr = tempFullStr + "___" + bidAmout;
            }
          }
        }
      });
      const newListTemp = tempFullStr?.split("___");
      if (newListTemp?.length < 10) {
        for (let index = 0; index < 10; index++) {
          bidAmout = bidAmout + 5000;
          tempFullStr = tempFullStr + "___" + bidAmout;
        }
      }
      setDropdownvalues(tempFullStr.split("___"));
      setSelectedMaxBid(tempFullStr.split("___")[1]);
    }
  };
  const handlePlaceBid = () => {
    setShowActionsBtn("quick&maxbid");
  };
  const handleCancel = () => {
    setShowActionsBtn("quick&maxbid");
  };
  const handleQuickBod = () => {
    setShowActionsBtn("placequickbiid");
  };
  const handleSetMaxBid = () => {
    setShowActionsBtn("placemaxbid");
  };
  const placeQuickBid = async () => {
    if (nextBidAmount != "") {
      const body = {
        quickBid: {
          currency: currencyName,
          itemId: myLot?.id,
          customerId: userDetails?.id,
          maxAmount: nextBidAmount,
          type: "Online",
        },
        groupName: `online_collection_${collectionData?.id}`,
      };
      await placeQuickBidCall(body);
      setShowActionsBtn("placebid");
    }
  };
  const PlaceAutoBid = async () => {
    if (selectedMaxBid != "") {
      const body = {
        autoBid: {
          itemId: myLot?.id,
          customerId: userDetails?.id,
          currency: currencyName,
          maxAmount: selectedMaxBid,
          type: "Online",
        },
        groupName: `online_collection_${collectionData?.id}`,
      };
      // this.setState({ pageLoading: true });
      const resp = await autobidCallBroadCastCall(body);
      setShowActionsBtn("placebid");
    }
  };
  const handleShowPrevBids = (count: any) => {
    if (count > 0) {
      setShowPrevBids(true);
    }
  };
  const hideModal = () => {
    setShowPrevBids(false);
  };
  const currentTime = convertLMilleSec();
  const extentedExpiresOnUtc = convertUTCToLMilleSec(
    lotDetails?.extentedExpiresOnUtc
  );

  return (
    <>
      <MyActiveBidsStyled widthProgress={widthProgress}>
        <PrevBidDetails
          ShowPrevBids={showPrevBids}
          hideModal={hideModal}
          lotId={myLot?.id}
          currencyName={currencyName}
          collectionData={collectionData}
        />

        <div className="card-box">
          <div className="card-box-top-row">
            <div className="card-box-top-left">Lot {myLot?.sno} </div>
            {lotDetails?.status !== 6 && lotDetails?.status !== 7 && (
              <>
                {!lotDetails?.outFromBid ? (
                  <div className="card-box-top-right green-colo">
                    You Have the Highest Bid
                  </div>
                ) : (
                  <div className="card-box-top-right">You Have Been Outbid</div>
                )}
              </>
            )}

            {lotDetails?.status === 6 || lotDetails?.status === 7 ? (
              <div className="lot-bidding-not-started active-bids-closed">
                Bidding is Closed
              </div>
            ) : null}
          </div>
          <div className="card-box-bottom">
            <div className="card-box-b-img-row">
              <a href={`/placeBid/${myLot?.id}`} target="_blank">
                <img src={myLot?.itemImages[0]?.vurtualPath} />{" "}
              </a>
            </div>
            <div className="card-box-con-middle-row">
              <a href={`/placeBid/${myLot?.id}`} target="_blank">
                <h3 className="makers-name">{myLot?.makerName}</h3>
                <div className="card-box-con-title">{myLot?.title}</div>
                <div className="card-box-con-estimate">
                  Estimate:{" "}
                  <span className="d-block mt-1">
                    {myLot?.estimatedPrice?.toLocaleString()} {currencyName} -{" "}
                    {myLot?.estimatedPriceTo?.toLocaleString()} {currencyName}
                  </span>
                  <div className="sale-amount-text green-color-amount-text mb-3 mt-1">
                    {convertSelectedCountryCurrency(
                      myLot?.estimatedPrice,
                      selectedSecondaryCurrency
                    )}{" "}
                    {selectedSecondaryCurrency?.base_code !== "USD" &&
                      selectedSecondaryCurrency?.base_code}
                    {myLot?.estimatedPriceTo && (
                      <>
                        {" "}
                        {selectedSecondaryCurrency?.base_code !== "USD" &&
                          selectedSecondaryCurrency?.base_code &&
                          " - "}
                        {convertSelectedCountryCurrency(
                          myLot?.estimatedPriceTo,
                          selectedSecondaryCurrency
                        )}{" "}
                        {selectedSecondaryCurrency?.base_code !== "USD" &&
                          selectedSecondaryCurrency?.base_code}
                      </>
                    )}
                  </div>
                </div>
              </a>
              <div className="lots_cost lots_cost_lat lots_cost_lat_coll_details">
                {lotDetails?.status === 6 ? (
                  <>
                    <span className="d-block lotDetailsTextRightSide">
                      Lot Sold:
                    </span>
                    <span className="strong d-block">
                      {currencyName} {lotDetails?.soldFor?.toLocaleString()}
                    </span>
                  </>
                ) : lotDetails?.status === 7 || isClosedLot === "closed" ? (
                  <span className="d-block lotDetailsTextRightSide"></span>
                ) : (
                  <>
                    <span className="d-block lotDetailsTextRightSide">
                      Current Bid:
                      <span className="d-flex align-items-end">
                        <span className="strong d-block mr-1 mt-1">
                          {lotDetails?.currentBid?.toLocaleString()}{" "}
                          {currencyName}
                        </span>
                        (
                        <span
                          className={`bids-count-no  ${
                            lotDetails?.bidsCount > 0 && "bids-count"
                          }`}
                          onClick={() => {
                            handleShowPrevBids(lotDetails?.bidsCount);
                          }}
                        >
                          {lotDetails?.bidsCount}{" "}
                          {lotDetails?.bidsCount == 1 ? "Bid" : "Bids"}
                        </span>
                        , Reserve
                        {highestBidResp?.bidDetails?.metResevedPrice
                          ? ""
                          : " Not"}{" "}
                        Met)
                      </span>
                    </span>
                    <div className="sale-amount-text green-color-amount-text mt-1">
                      {convertSelectedCountryCurrency(
                        lotDetails?.currentBid,
                        selectedSecondaryCurrency
                      )}{" "}
                      {selectedSecondaryCurrency?.base_code !== "USD" &&
                        selectedSecondaryCurrency?.base_code}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="card-box-b-con-row">
              {showTimer !== "loading" ? (
                <></>
              ) : showTimer !== "" ? (
                <>
                  {lotDetails?.status !== 6 && lotDetails?.status !== 7 && (
                    <div className="card-box-bid-amount-row">
                      <div className="card-box-bid"> </div>
                      <div
                        className={
                          !lotDetails?.outFromBid
                            ? "card-box-amount green-colo"
                            : "card-box-amount"
                        }
                      >
                        {lotDetails?.currentBid && (
                          <>
                            {lotDetails?.currentBid?.toLocaleString()}{" "}
                            {currencyName}
                            <div className="sale-amount-text active-bids-green-colr green-color-amount-text mt-1">
                              {convertSelectedCountryCurrency(
                                lotDetails?.currentBid,
                                selectedSecondaryCurrency
                              )}{" "}
                              {selectedSecondaryCurrency?.base_code !== "USD" &&
                                selectedSecondaryCurrency?.base_code}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="card-box-reserve-met-time">
                    {!isBiddingClosed && (
                      <span className="show-timer-sec show-timer-sec-sec-cont">
                        {lotDetails?.status !== 6 &&
                          lotDetails?.status !== 7 && (
                            <LotDetailsTime
                              lotData={lotDetails}
                              collectionData={collectionData}
                              pageFrom="myactivebid"
                              updateIsExpiredButton={() => {
                                setIsBiddingClosed(true);
                              }}
                              bannerTimeZone={bannerTimeZone}
                            />
                          )}
                      </span>
                    )}
                  </div>

                  {showProgressBarSec && (
                    <div className="main-sec-top-progress-bar">
                      <div
                        id="my-progress-myProgress"
                        className="my-progress-myProgress"
                      >
                        <div
                          id="myProgress-myBar"
                          className="myProgress-myBar"
                        ></div>
                      </div>
                    </div>
                  )}
                  {nextBidAmount &&
                    lotDetails?.status !== 6 &&
                    lotDetails?.status !== 7 &&
                    currentTime < extentedExpiresOnUtc && (
                      <>
                        {lotDetails?.maxAmount > 0 &&
                          showActionsBtn === "placequickbiid" && (
                            <div className="your-max-bid-amount-text-bid your-max-bid-amount-text-bidquickbid">
                              Your current Maximum Bid is{" "}
                              <span>
                                {lotDetails?.customerLatestBid >
                                lotDetails.maxAmount
                                  ? lotDetails?.customerLatestBid
                                  : lotDetails.maxAmount}{" "}
                                {currencyName}
                              </span>
                              <div className="sale-amount-text green-color-amount-text mt-1">
                                {convertSelectedCountryCurrency(
                                  lotDetails?.customerLatestBid >
                                    lotDetails.maxAmount
                                    ? lotDetails?.customerLatestBid?.toLocaleString()
                                    : lotDetails.maxAmount?.toLocaleString(),
                                  selectedSecondaryCurrency
                                )}{" "}
                                {selectedSecondaryCurrency?.base_code !==
                                  "USD" && selectedSecondaryCurrency?.base_code}
                              </div>
                            </div>
                          )}

                        {!isBiddingClosed && (
                          <div className="card-box-actions-row">
                            {showActionsBtn === "placebid" ? (
                              <div
                                className="card-box-increase-bid"
                                onClick={handlePlaceBid}
                              >
                                {" "}
                                <img src={BidWhite} /> PLACE BID
                              </div>
                            ) : showActionsBtn === "quick&maxbid" ? (
                              <>
                                {lotDetails?.outFromBid && (
                                  <button
                                    className="card-box-cancel-bid"
                                    onClick={handleQuickBod}
                                  >
                                    QUICK BID
                                  </button>
                                )}

                                <button
                                  className="card-box-place-bid"
                                  onClick={handleSetMaxBid}
                                >
                                  SET YOUR MAX BID
                                </button>
                              </>
                            ) : showActionsBtn === "placequickbiid" ? (
                              <>
                                <button
                                  className="card-box-cancel-bid"
                                  onClick={handleCancel}
                                >
                                  CANCEL
                                </button>
                                <button
                                  className="card-box-place-bid"
                                  onClick={placeQuickBid}
                                >
                                  PLACE BID {nextBidAmount?.toLocaleString()}{" "}
                                  {currencyName}
                                </button>
                              </>
                            ) : showActionsBtn === "placemaxbid" ? (
                              <>
                                {" "}
                                <div className="select-bid-amount-sec">
                                  {lotDetails?.maxAmount > 0 && (
                                    <div className="your-max-bid-amount-text-bid">
                                      Your current Maximum Bid is{" "}
                                      <span>
                                        {lotDetails?.customerLatestBid >
                                        lotDetails.maxAmount
                                          ? lotDetails?.customerLatestBid?.toLocaleString()
                                          : lotDetails.maxAmount?.toLocaleString()}{" "}
                                        {currencyName}
                                      </span>
                                      <div className="sale-amount-text green-color-amount-text mt-1">
                                        {convertSelectedCountryCurrency(
                                          lotDetails?.customerLatestBid >
                                            lotDetails.maxAmount
                                            ? lotDetails?.customerLatestBid
                                            : lotDetails.maxAmount,
                                          selectedSecondaryCurrency
                                        )}{" "}
                                        {selectedSecondaryCurrency?.base_code !==
                                          "USD" &&
                                          selectedSecondaryCurrency?.base_code}
                                      </div>
                                    </div>
                                  )}

                                  <div>
                                    <Form>
                                      <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                                        <Form.Label className="bids_enter_current_bid_text bids_enter_select_label">
                                          Set Your Maximum Bid:
                                        </Form.Label>
                                        <Form.Control
                                          as="select"
                                          size="sm"
                                          custom
                                          className="custom_bid_select_box"
                                          onChange={(e) => {
                                            setSelectedMaxBid(e.target.value);
                                          }}
                                        >
                                          {(dropdownvalues || []).map(
                                            (val: any, index: any) => (
                                              <>
                                                {val != undefined &&
                                                  val != "" && (
                                                    <option value={val}>
                                                      {parseInt(
                                                        val
                                                      )?.toLocaleString()}{" "}
                                                      {currencyName}
                                                      {selectedSecondaryCurrency?.base_code !==
                                                        "USD" &&
                                                        selectedSecondaryCurrency?.base_code &&
                                                        "("}
                                                      {`${convertSelectedCountryCurrency(
                                                        val,

                                                        selectedSecondaryCurrency
                                                      )}`}{" "}
                                                      {`${
                                                        selectedSecondaryCurrency?.base_code !==
                                                          "USD" &&
                                                        selectedSecondaryCurrency?.base_code
                                                          ? selectedSecondaryCurrency?.base_code
                                                          : ""
                                                      }`}
                                                      {selectedSecondaryCurrency?.base_code !==
                                                        "USD" &&
                                                        selectedSecondaryCurrency?.base_code &&
                                                        ")"}
                                                    </option>
                                                  )}
                                              </>
                                            )
                                          )}
                                        </Form.Control>
                                      </Form.Group>
                                    </Form>
                                  </div>
                                  <div className="card-box-actions-row">
                                    <button
                                      className="card-box-cancel-bid"
                                      onClick={handleCancel}
                                    >
                                      CANCEL
                                    </button>
                                    <button
                                      className="card-box-place-bid card-box-place-bid1"
                                      onClick={PlaceAutoBid}
                                    >
                                      CONFIRM BID -{" "}
                                      {parseInt(
                                        selectedMaxBid
                                      )?.toLocaleString()}{" "}
                                      {currencyName}
                                    </button>
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </div>
                        )}
                      </>
                    )}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </MyActiveBidsStyled>
    </>
  );
};
export default MyBidCard;
