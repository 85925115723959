import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { isMobile } from "react-device-detect";
import CardItem from "./CardItem";
import MobileView from "./MobileView";
import BidRegistrationModal from "../BidRegistrationPopUp/BidRegisrationModal";

const ActiveActionsSlider = (props: any) => {
  const { AllCollections, handleSucessafterRegister } = props;
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [registerButon, setRegisterButon] = useState(false);
  const [activeActions, setActiveActions] = useState(
    AllCollections?.onGoingAuctions
  );

  const [collectionId, setCollectionId]: any = useState({});

  const responsive = {
    tablet: {
      breakpoint: { max: 1399, min: 1100 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 1920, min: 1400 },
      items: 3,
      slidesToSlide: 1,
    },
  };

  const handleCloseModal = () => {
    setShowRegistrationModal(false);
  };

  useEffect(() => {
    if (registerButon) {
      // Update the activeActions array
      setActiveActions((prevActions: any) =>
        prevActions.map((item: any) =>
          item.id === collectionId?.id
            ? { ...item, isRegistredToAuction: true }
            : item
        )
      );

      // Reset the registerButton to avoid repeated updates
      setRegisterButon(false);
    }
  }, [registerButon, collectionId]);

  return (
    <>
      {showRegistrationModal && (
        <BidRegistrationModal
          showRegistrationModal={showRegistrationModal}
          setShowRegistrationModal={handleCloseModal}
          collectionId={collectionId}
          toPage={"active-auction"}
          setRegisterButon={setRegisterButon}
        />
      )}
      {isMobile ? (
        <MobileView
          onGoingAuctions={activeActions}
          handleSucessafterRegister={handleSucessafterRegister}
          setShowRegistrationModal={setShowRegistrationModal}
          setCollectionId={setCollectionId}
        />
      ) : (
        <div className="custom_action_slider">
          <Carousel responsive={responsive}>
            {(activeActions || []).map((item: any, index: any) => {
              return (
                <CardItem
                  item={item}
                  handleSucessafterRegister={handleSucessafterRegister}
                  setShowRegistrationModal={setShowRegistrationModal}
                  setCollectionId={setCollectionId}
                />
              );
            })}
          </Carousel>
        </div>
      )}
    </>
  );
};

export default ActiveActionsSlider;
