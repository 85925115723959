import React, { Component } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import Slide2 from "./../../assets/image/slide2.jpg";

import "./css/PaymentConfirmation.css";
import { getPaymentDetailsCall } from "./../../library/Requests/MyAccount";
import { displayAmount } from "./../../Scenes/common";
import { convertSelectedCountryCurrency } from "../../utils";
export class index extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      currenciesList: [],
      orderDetails: {},
      isLoading: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const orderID = this.props?.match?.params?.id;
    this.getDataonLoad(orderID);
  }
  getDataonLoad = async (orderID: any) => {
    this.setState({ isLoading: true });
    const resp = await getPaymentDetailsCall(orderID);
    this.setState({ orderDetails: resp?.data });
    this.setState({ isLoading: false });
  };
  goBacklickPage = () => {
    this.props.history.push(`/myPurchases`);
  };
  render() {
    const { orderDetails, isLoading } = this.state;
    let bidDetails = null;
    if (orderDetails?.orderedBids?.length > 0) {
      bidDetails = orderDetails?.orderedBids[0];
    }
    const isMultipleLots = orderDetails?.orderedBids?.length > 1 ? true : false;
    const totalText = isMultipleLots ? "Total " : "";

    return (
      <div>
        <Row className="paymentConfirmContainer">
          {isLoading ? (
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <>
              <Col md="8" className="noPadding">
                {orderDetails?.paymentStatus === "Paid" ? (
                  <>
                    <span className="paymentConfirmTitle noPadding">
                      {" "}
                      Order Confirmation
                    </span>
                    <p className="paymentStatusMsg">
                      {" "}
                      Your payment has been successfully processed. Your item
                      will be shipped soon. You will receive a tracking number
                      for shipping. You can access order information by clicking
                      on the View Order button below.
                    </p>
                    <Row>
                      <a href="/myPurchases">
                        <Button variant="custom" className="paymentViewOrder">
                          VIEW ORDER
                        </Button>
                      </a>
                      {/* <Button
                        variant="custom"
                        className="paymentDownloadInvoice"
                      >
                        Download Invoice
                      </Button> */}
                    </Row>
                  </>
                ) : (
                  <>
                    {" "}
                    <span className="paymentConfirmTitle noPadding">
                      {" "}
                      Transaction Failed
                    </span>
                    <p className="paymentStatusMsg">
                      {" "}
                      Your payment for the order has failed
                    </p>
                    <Row>
                      <a href="/myPurchases">
                        <Button variant="custom" className="paymentViewOrder">
                          VIEW ORDER
                        </Button>
                      </a>
                      <a href="/myPurchases">
                        <Button
                          variant="custom"
                          className="paymentDownloadInvoice"
                        >
                          BACK TO PURCHASES
                        </Button>
                      </a>
                    </Row>
                  </>
                )}
              </Col>
              <Col md="4" className="noPadding">
                <span className="paymentOrderSummary">Order summary</span>
                <div className="orderSummaryStyles">
                  {!isMultipleLots && (
                    <>
                      <Row>
                        <Col md="3">
                          <img
                            src={bidDetails?.imageUrl}
                            className="paymentItemImage"
                          />
                        </Col>
                        <Col className="itemTitleAmount">
                          <p className="paymentItemTitle">
                            {" "}
                            {bidDetails?.collectionItemTitle}
                          </p>
                          <p className="yourBidAmount">
                            <span className="yourBidTitle">Your Bid: </span>
                            {orderDetails?.currency}{" "}
                            {displayAmount(bidDetails?.amount)}
                          </p>
                        </Col>
                      </Row>
                      <hr className="orderSummaryHR" />
                    </>
                  )}
                  <div className="detailsRow">
                    <div className="total-itmes-list">
                      <div className="total-itmes-list-row">
                        <div className="namesColumn">
                          {isMultipleLots ? (
                            <span>{`${totalText}Hammer Price (${orderDetails?.orderedBids?.length} Lots)`}</span>
                          ) : (
                            <span>{totalText}Hammer Price</span>
                          )}
                        </div>
                        <div className="valuesColumn">
                          <span>
                            {displayAmount(orderDetails?.bidAmount)}{" "}
                            {orderDetails?.currency}
                          </span>
                        </div>
                      </div>
                      <div className="total-itmes-list-row order-checkout-green-text">
                        <div className="sale-amount-text green-color-amount-text mt-1">
                          {convertSelectedCountryCurrency(
                            orderDetails?.bidAmount,
                            this.props.selectedSecondaryCurrency
                          )}{" "}
                          {this.props.selectedSecondaryCurrency?.base_code !==
                            "USD" &&
                            this.props.selectedSecondaryCurrency?.base_code}
                        </div>
                      </div>
                      <div className="total-itmes-list-row">
                        <div className="namesColumn">
                          <span>{totalText}Buyer’s Premium</span>
                        </div>
                        <div className="valuesColumn">
                          <span>
                            {displayAmount(orderDetails?.buyerPremium)}
                          </span>
                        </div>
                      </div>
                      {/* {isShipToMe && ( */}
                      <div className="total-itmes-list-row order-checkout-green-text">
                        <div className="sale-amount-text green-color-amount-text mt-1">
                          {convertSelectedCountryCurrency(
                            orderDetails?.buyerPremium,
                            this.props.selectedSecondaryCurrency
                          )}{" "}
                          {this.props.selectedSecondaryCurrency?.base_code !==
                            "USD" &&
                            this.props.selectedSecondaryCurrency?.base_code}
                        </div>
                      </div>
                      <div className="total-itmes-list-row">
                        <div className="namesColumn">
                          <span>{totalText} Shipping Fee</span>
                        </div>
                        <div className="valuesColumn">
                          <span>
                            {/* {`0.00`} */}
                            {displayAmount(orderDetails?.shippingFee)}
                          </span>
                        </div>
                      </div>
                      {/* )} */}
                      <div className="total-itmes-list-row order-checkout-green-text">
                        <div className="sale-amount-text green-color-amount-text mt-1">
                          {convertSelectedCountryCurrency(
                            orderDetails?.shippingFee,
                            this.props.selectedSecondaryCurrency
                          )}{" "}
                          {this.props.selectedSecondaryCurrency?.base_code !==
                            "USD" &&
                            this.props.selectedSecondaryCurrency?.base_code}
                        </div>
                      </div>
                      <div className="total-itmes-list-row">
                        <div className="namesColumn">
                          <span>{totalText} Sales Tax</span>
                        </div>
                        <div className="valuesColumn">
                          <span>{displayAmount(orderDetails?.salesTax)}</span>
                        </div>
                      </div>
                      <div className="total-itmes-list-row order-checkout-green-text">
                        <div className="sale-amount-text green-color-amount-text mt-1">
                          {convertSelectedCountryCurrency(
                            orderDetails?.salesTax,
                            this.props.selectedSecondaryCurrency
                          )}{" "}
                          {this.props.selectedSecondaryCurrency?.base_code !==
                            "USD" &&
                            this.props.selectedSecondaryCurrency?.base_code}
                        </div>
                      </div>
                      {/* <div className="total-itmes-list-row">
                        <div className="namesColumn">
                          <span>{totalText}Administration Fee</span>
                        </div>
                        <div className="valuesColumn">
                          <span>
                            {displayAmount(orderDetails?.transactionTax)}
                          </span>
                        </div>
                      </div> */}
                      <div className="total-itmes-list-row text-bold-item-purchase total-itmes-list-row-total">
                        <div className="namesColumn">
                          <span>Total Lot Purchase Price</span>
                        </div>
                        <div className="valuesColumn">
                          <span className="totalPayableAmt">
                            {displayAmount(orderDetails?.totalAmount)}{" "}
                            {orderDetails?.currency}
                          </span>
                        </div>
                      </div>
                      <div className="total-itmes-list-row order-checkout-green-text">
                        <div className="sale-amount-text green-color-amount-text mt-1">
                          {convertSelectedCountryCurrency(
                            orderDetails?.totalAmount,
                            this.props.selectedSecondaryCurrency
                          )}{" "}
                          {this.props.selectedSecondaryCurrency?.base_code !==
                            "USD" &&
                            this.props.selectedSecondaryCurrency?.base_code}
                        </div>
                      </div>
                      <div className="total-itmes-list-row text-bold-item-purchase">
                        <div className="namesColumn">
                          <span>Balance Due</span>
                        </div>
                        <div className="valuesColumn">
                          <span className="totalPayableAmt">
                            {orderDetails?.paymentStatus === "Paid" ? (
                              <>{`0`}</>
                            ) : (
                              <> {displayAmount(orderDetails?.totalAmount)}</>
                            )}{" "}
                            {orderDetails?.currency}
                          </span>
                        </div>
                      </div>
                    </div>

                    {/* <Col sm="5" className="namesColumn">
                      <span>Bid Amount:</span>
                    </Col>
                    <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                      <span>
                        {orderDetails?.currency}{" "}
                        {orderDetails?.bidAmount?.toLocaleString()}
                      </span>
                    </Col>
                    <Col sm="5" className="namesColumn">
                      <span>Tax Amount:</span>
                    </Col>
                    <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                      <span>
                        {orderDetails?.currency}{" "}
                        {orderDetails?.premiumTax?.toLocaleString()}
                      </span>
                    </Col>
                    <Col sm="5" className="namesColumn">
                      <span>Total Payable:</span>
                    </Col>
                    <Col sm={{ span: 6, offset: 0.5 }} className="valuesColumn">
                      <span className="totalPayableAmt">
                        {orderDetails?.currency}{" "}
                        {orderDetails?.totalAmount?.toLocaleString()}
                      </span>
                    </Col> */}
                  </div>
                </div>
              </Col>
            </>
          )}
        </Row>
      </div>
    );
  }
}

export default index;
