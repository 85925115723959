import React, { Component } from "react";
import { Link } from "react-router-dom";

import Breadcrumb from "react-bootstrap/Breadcrumb";

import Art1 from "../../assets/image/ar1.png";
import Art2 from "../../assets/image/ar2.png";
import Currency from "../../assets/image/currency.png";
import BidWhite from "../../assets/image/bid-white.png";
import infoIconIcon from "../../assets/image/infoIcon.svg";
import { Button, Modal } from "react-bootstrap";
import ImageGallerySlider from "../../components/ImageGallerySlider";
import { bidregistrationCall } from "./../../library/Requests/PlaceBid";

import DescriptionTabs from "../../components/DescriptionTabs1";

import MoreCollectionColum from "../../components/MoreCollectionColum";
import BidIncrementTable from "../../components/BidIncrementTable";
// import moment from 'moment';
import moment from "moment-timezone";
import WordBanner from "./../../assets/image/word_banner.jpg";
import slide1 from "./../../assets/image/slide1.jpg";
import PrevBidDetails from "../../components/PrevBidDetails/PrevBidDetails";
import LotDetailsTime from "./LotDetailsTime";
import {
  convertLMilleSec,
  convertUTCToLMilleSec,
  LOGINID,
} from "./../../Scenes/common";
import "./css/bid1.css";
import LoginPopup from "../../components/LoginPopup";
import "bootstrap/dist/css/bootstrap.min.css";

import { connect } from "react-redux";
import * as actionCreators from "./../../actions/index.js";

import { AFTER_LOGIN_TRIGGER_PAGE } from "./../../library/Requests/eventConstants";
import ContentLoaderSection from "./../../components/ContentLoaderSection/ContentLoaderSection";
import {
  getcollectionitembyidCall,
  getItemidsbyCollectionIdCall,
  getbidincrementCall,
  getCollectionItemsCall,
  getTimeZones,
  gethighestbid,
  getCollectionByIdCall,
  getcurrenciesCall,
  followbidCall,
  unfollowbidCall,
} from "./../../library/Requests";
import CustomModal from "./../../components/CustomModel";

import ContentLoader from "react-content-loader";
import { isMobile } from "react-device-detect";
import { signalHubUrl } from "./../../config";

import * as signalR from "@microsoft/signalr";
import CurrencyConverterBox from "./CurrencyConverterBox";
import { SERVER_IP } from "./../../library/constants.js";
import Loader from "./../../components/Common/Loader";
import { triggerMixpanelEvent } from "./../../Scenes/common";
import { HubConnectionBuilder } from "@microsoft/signalr";

import { updateFollowedData } from "../../redux/slices/collectionDetails/collectionDetailsSlice";
import BiddingStartingComp from "../collectionDetails/BiddingStartsComp";
import { LotDeatilsStyled } from "./LotDetailsStyled.styled";
import BidRegistrationModal from "../../components/BidRegistrationPopUp/BidRegisrationModal";
import { convertSelectedCountryCurrency } from "../../utils";
class lotDetails extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      currenciesList: [],
      timeZones: [],
      collectionData: {},
      lotData: [],
      imagesList: [],
      collectionItems: [],
      warningModelPopup: false,
      userRejectedAddressPopup: false,
      showFullscreenButton: true,
      showGalleryFullscreenButton: true,
      showPlayButton: true,
      showGalleryPlayButton: true,
      showVideo: {},
      prevLot: {},
      nextLot: {},
      highestBidDetails: {},
      highestBidResp: {},
      showPlaceBidBtn: false,
      // loginPopupShow: false,
      isLoading: false,
      isPageLoading: false,
      lotStatusText: "",
      ShowPopCurrency: false,
      ShowBidIncrementTable: false,
      bidIncrementTableData: [],
      allItemsData: [],
      errorMsg: false,
      registerBidSuccess: false,
      errorModelPopup: false,
      errorTextData: "",
      selectedCurrency: {},
      showPrevBids: false,
      signalConnection: {} as any,
      isBiddingClosed: false,
    };
  }

  componentDidMount() {
    const id = this.props?.match?.params?.id;
    this.getcurrenciesDataCall();
    this.getLotData(id, "onLoad");
    this.getHighestBidDetails(id);
    const self = this;
    triggerMixpanelEvent("lot_details_page", { lotId: id });
    window.document.addEventListener(
      AFTER_LOGIN_TRIGGER_PAGE,
      function (event) {
        self.triggerFunctionCall();
      },
      false
    );
  }
  // triggerMicrosoftSignal = (collNo: any) => {
  //   const self = this;
  //   const connection = new HubConnectionBuilder()
  //     .withUrl(signalHubUrl)
  //     .withAutomaticReconnect([3000, 5000, 10000, 15000, 30000])
  //     .build();
  //   this.setState({ connection: connection });

  //   connection
  //     .start()
  //     .then((result) => {
  //       console.log("Connection Started new");
  //       connection
  //         .invoke("JoinGroup", `online_collection_${collNo}`)
  //         .then(() => {
  //           console.log("In JOIN", `online_collection_${collNo}`);
  //         })
  //         .catch(function (err) {
  //           return console.error(err.toString());
  //         });
  //       connection.on("ReceiveHandler", (message, obj) => {
  //         console.log("In ReceiveHandler " + collNo + message);
  //         self.callgetHighestBidNew(obj);
  //       });
  //     })
  //     .catch((e) => console.log("Connection failed: ", e));
  //   document.addEventListener("visibilitychange", () => {
  //     if (
  //       document.visibilityState === "visible" &&
  //       connection.state !== "Connected"
  //     ) {
  //       connection.start();
  //     }
  //   });
  // };

  triggerMicrosoftSignal = (lotIdNo: any) => {
    //   const self = this;
    //   const connection = new HubConnectionBuilder()
    //     .withUrl(signalHubUrl)
    //     .withAutomaticReconnect([3000, 5000, 10000, 15000, 30000])
    //     .build();
    //   // setConnection(newConnection);

    //   connection
    //     .start()
    //     .then((result) => {
    //       console.log("Connection Started new");
    //       connection
    //         .invoke("JoinGroup", `online_Lot_${lotIdNo}`)
    //         .then(() => {
    //           console.log("In JOIN", `online_Lot_${lotIdNo}`);
    //         })
    //         .catch(function (err) {
    //           return console.error(err.toString());
    //         });
    //       connection.on("ReceiveHandler", (message) => {
    //         console.log("In ReceiveHandler " + lotIdNo + message);
    //         self.callgetHighestBidNew(message);
    //       });
    //     })
    //     .catch((e) => console.log("Connection failed: ", e));
    //   document.addEventListener("visibilitychange", () => {
    //     if (
    //       document.visibilityState === "visible" &&
    //       connection.state !== "Connected"
    //     ) {
    //       connection.start();
    //     }
    //   });

    const self = this;
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${SERVER_IP}/hubs/BidHub`)
      .withAutomaticReconnect()
      .build();
    connection.on("BidInsertSignal", (response) => {
      console.log(
        "We got signal! and the message is: " + JSON.stringify(response)
      );
    });

    connection
      .start()
      .then((result) => {
        connection.on("BidInsertSignal", (response) => {
          console.log(
            "We got signal! and the message is: " + JSON.stringify(response)
          );
          console.log(response);
          // Bind GetHightestBids here
          self.callgetHighestBidNew(response);
        });
      })
      .catch((err) => console.error(err.toString()));
  };
  callgetHighestBidNew = async (response: any) => {
    const id = this.props?.match?.params?.id;
    if (response?.itemId == id) {
      this.getLotData(id, "autoUpdate");
      this.getHighestBidDetails(id);
    }
  };
  getHighestBidDetails = async (id: any) => {
    const bidResp = await gethighestbid(id);
    this.setState({
      highestBidDetails: bidResp?.bidDetails,
      highestBidResp: bidResp,
    });
    if (this.state.bidIncrementTableData.length < 10) {
      const bidDataResp = await getbidincrementCall();
      this.setState({ bidIncrementTableData: bidDataResp?.increment });
    }
    // console.log("bidDataResp__",bidDataResp)
  };
  getcurrenciesDataCall = async () => {
    const resp = await getcurrenciesCall();
    this.setState({ currenciesList: resp?.data });
    const timeZoneResp = await getTimeZones();
    this.setState({ timeZones: timeZoneResp?.data });
  };
  getCollectionItems = async (id: any) => {
    const body = {
      page: 1,
      pageSize: 10,
      collectionId: id,
    };
    let resp = await getCollectionItemsCall(body);
    let listItems = resp?.data;
    const index = listItems?.findIndex(
      (item: any) => item?.id === this.state.lotData?.id
    );
    if (index > -1) {
      listItems.splice(index, 1);
    }
    this.setState({ collectionItems: listItems });
    // this.getPrevNextItems(resp?.data);
  };
  getallitemsData = async (collId: any) => {
    if (collId != undefined && collId != null && collId) {
      let resp1 = await getItemidsbyCollectionIdCall(collId);
      this.setState({ allItemsData: resp1?.data });
      this.getPrevNextItems(resp1?.data);
    }
  };
  getLotData = async (id: any, updateType: any) => {
    if (updateType != "autoUpdate") {
      window.scrollTo(0, 0);
      this.setState({ isLoading: true });
    }
    const collectionItem = await getcollectionitembyidCall(id, LOGINID);
    // this.setState({ isPageLoading: true });
    this.getCollectionData(collectionItem?.data?.collectionId, updateType);
    this.setState({ isLoading: false });
    this.setState({ lotData: collectionItem?.data });
    if (updateType === "onLoad") {
      this.triggerMicrosoftSignal(collectionItem?.data?.collectionId);
    }

    this.getCollectionItems(collectionItem?.data?.collectionId);
    this.getallitemsData(collectionItem?.data?.collectionId);
    let imageL = collectionItem?.data?.itemImages;
    if (collectionItem?.data?.id == 0 || !collectionItem?.data?.id) {
      this.setState({ errorMsg: true });
      document.title = "FAHH";
    } else {
      document.title = this.state.lotData?.title;
    }

    let imageList: any = [];
    imageL?.map((image: any, index: any) => {
      const imageP = image?.vurtualPath;
      let imageT = image?.vurtualPath;
      if (image.displayOrder == 8) {
        imageT = imageL[0].vurtualPath;
        var temp1 = {
          original: imageT,
          thumbnail: imageT,
          embedUrl: image?.vurtualPath,
          renderItem: this._renderVideo.bind(this),
        };
        imageList.push(temp1);
      } else {
        var temp = { original: imageP, thumbnail: imageT };
        imageList.push(temp);
      }
    });
    this.setState({ imagesList: imageList });
  };

  getPrevNextItems = (list: any) => {
    const { lotData } = this.state;
    (list || []).map((item: any, index: any) => {
      if (lotData.id == item.itemId) {
        let currentNoo = item.rno;
        const prevIn = currentNoo - 1;
        const nectIn = currentNoo + 1;
        let prevLotN = list.filter((i2: any) => i2.rno == prevIn);
        let nectLotN = list.filter((i2: any) => i2.rno == nectIn);
        if (prevLotN.length > 0) {
          this.setState({ prevLot: prevLotN[0] });
        } else {
          this.setState({ prevLot: {} });
        }
        if (nectLotN.length > 0) {
          this.setState({ nextLot: nectLotN[0] });
        } else {
          this.setState({ nextLot: {} });
        }
      }
    });
  };

  setFollowedDataInLocalStorage = (value: any) => {
    if (value) {
      window.localStorage.setItem("isFollwed", "1");
    } else {
      window.localStorage.removeItem("isFollwed");
    }
  };

  gotoNextLot = (url: any, id: any) => {
    this.props.history.push(url);
    this.getcurrenciesDataCall();
    this.getLotData(id, "");
    this.getHighestBidDetails(id);
  };
  followBid = async (id: any) => {
    this.props.afterLoginTriggerPageCall({});
    const { lotData } = this.state;

    let userInfo = localStorage.getItem("customerInfoDetails");
    if (userInfo != undefined && userInfo != null && userInfo != "") {
      const userIn = JSON.parse(userInfo);
      if (userIn?.id) {
        lotData.ifollowed = true;
        const body = {
          follow: {
            itemId: id,
            customerId: userIn?.id,
            isFollow: true,
          },
        };
        this.setState({ lotData: lotData });
        const resp = await followbidCall(body);
        this.props.followeddata(true);
      }
    } else {
      // localStorage.setItem("afterLoginTriggerPage", "lotDetailsPage__"+id)
      const jsonD = {
        pageName: "followClick",
        lotId: id,
      };
      this.props.afterLoginTriggerPageCall(jsonD);
      this.props.LopinPopUpPage(true);
    }
  };
  triggerFunctionCall = () => {
    // console.log(this.props?.afterLoginTriggerPage);
    if (
      this.props?.afterLoginTriggerPage &&
      this.props?.afterLoginTriggerPage?.lotId
    ) {
      this.followBid(this.props?.afterLoginTriggerPage?.lotId);
    }
  };
  unFollowBid = async (id: any) => {
    const { lotData } = this.state;
    let userInfo = localStorage.getItem("customerInfoDetails");
    if (userInfo != undefined && userInfo != null && userInfo != "") {
      const userIn = JSON.parse(userInfo);
      if (userIn?.id) {
        const body = {
          follow: {
            itemId: id,
            customerId: userIn?.id,
            isFollow: false,
          },
        };
        lotData.ifollowed = false;
        this.setState({ lotData: lotData });
        const resp = await followbidCall(body);
        this.props.followeddata(false);
      }
    }
  };

  _toggleShowVideo(url: any) {
    this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);
    this.setState({
      showVideo: this.state.showVideo,
    });

    if (this.state.showVideo[url]) {
      if (this.state.showPlayButton) {
        this.setState({ showGalleryPlayButton: false });
      }

      if (this.state.showFullscreenButton) {
        this.setState({ showGalleryFullscreenButton: false });
      }
    }
  }

  ShowCurrencyModal() {
    this.setState({ ShowPopCurrency: true });
  }
  ShowBidIncrementModal() {
    this.setState({ ShowBidIncrementTable: true });
  }
  howBidWorkCall() {
    this.props.history.push(`/howBiddingWorks/${this.state.lotData?.id}`);
    window.scrollTo(0, 0);
  }

  _renderVideo(item: any) {
    return (
      <div>
        {this.state.showVideo[item.embedUrl] ? (
          <div className="video-wrapper">
            <a
              className="close-video"
              onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
            ></a>
            <iframe
              width="560"
              height="315"
              src={item.embedUrl}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        ) : (
          <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
            <div className="play-button"></div>
            <img className="image-gallery-image" src={item.original} />
            {item.description && (
              <span
                className="image-gallery-description"
                style={{ right: "0", left: "initial" }}
              >
                {item.description}
              </span>
            )}
          </a>
        )}
      </div>
    );
  }
  gotoBigPage = (url: any, id: any) => {
    this.props.history.push(url);
    this.getcurrenciesDataCall();
    this.getLotData(id, "");
  };
  getCollectionData = async (id: any, uType: any) => {
    if (uType === "onLoad") {
      this.setState({ isPageLoading: true });
    }
    const collectionItem = await getCollectionByIdCall(id);
    this.setState({
      collectionData: collectionItem?.data,
      isPageLoading: false,
    });
    // this.showPlaceBidBtnCheck();
  };
  hideModal = () => {
    this.setState({ ShowBidIncrementTable: false });
    this.setState({ ShowPopCurrency: false, showPrevBids: false });
  };
  gotoUploadPhotoPage = () => {
    let userInfo = localStorage.getItem("customerInfoDetails");
    if (userInfo != undefined && userInfo != null && userInfo != "") {
      const userIn = JSON.parse(userInfo);
      if (!userIn?.billingAddressId) {
        window.location.href = `/sign-up/step2?returnUrl=/lotDetails/${this.state.lotData?.id}`;
      } else {
        window.location.href = `/sign-up/step3?returnUrl=/lotDetails/${this.state.lotData?.id}`;
      }
    }
  };
  gotoPlaceBidPage = () => {
    this.props.history.push(`/placeBid/${this.state.lotData?.id}`);
  };
  handlePlaceBid = () => {
    let userInfo = localStorage.getItem("customerInfoDetails");
    if (userInfo != undefined && userInfo != null && userInfo != "") {
      const userIn = JSON.parse(userInfo);
      if (!userIn?.billingAddressId) {
        //  !userIn?.identityProof ||  !userIn?.addressProof ||
        this.setState({ warningModelPopup: true });
      } else if (!userIn?.active && userIn?.isVerified) {
        this.setState({ userRejectedAddressPopup: true });
      } else if (this.state.collectionData?.registredToCollection) {
        this.gotoPlaceBidPage();
      } else {
        this.registerTOBid("gotoAction");
      }
    } else {
      this.props.LopinPopUpPage(true);
    }
  };
  handlePlaceBidRegister = async (e: any) => {
    if (this.props.user?.id) {
      let userInfo = localStorage.getItem("customerInfoDetails");
      if (userInfo != undefined && userInfo != null && userInfo != "") {
        const userIn = JSON.parse(userInfo);
        if (!userIn?.billingAddressId) {
          //!userIn?.identityProof || !userIn?.addressProof ||
          this.setState({ warningModelPopup: true });
        } else if (!userIn?.active && userIn?.isVerified) {
          this.setState({ userRejectedAddressPopup: true });
        } else {
          this.registerTOBid();
        }
      }
    } else {
      this.props.LopinPopUpPage(true);
      // window.localStorage.setItem("trigger_after_login_id", e.target.id);
    }
  };
  registerTOBid = async (paramV?: any) => {
    const body = {
      bidRegistration: {
        collectionId: this.state?.collectionData?.id,
        customerId: this.props.user?.id,
        type: "Online",
        registred: true,
      },
    };
    this.setState({ isPageLoading: true });
    const resp = await bidregistrationCall(body);
    this.setState({ isPageLoading: false });
    if (resp?.success) {
      triggerMixpanelEvent("bid_registration_success", {
        lotId: this.state.lotData?.id,
      });
      if (paramV === "gotoAction") {
        this.gotoPlaceBidPage();
      } else {
        this.setState({ registerBidSuccess: true });
      }
      this.getCollectionData(this.state?.collectionData?.id, "");
    } else if (resp?.errors?.length > 0) {
      triggerMixpanelEvent("bid_registration_error", {
        lotId: this.state.lotData?.id,
        error_message: resp?.errors[0],
      });
      this.setState({
        errorModelPopup: true,
        errorTextData: resp?.errors[0],
      });
    } else if (resp?.message) {
      triggerMixpanelEvent("bid_registration_error", {
        lotId: this.state.lotData?.id,
        error_message: resp?.message,
      });
      this.setState({
        errorModelPopup: true,
        errorTextData: resp?.message,
      });
    }
  };

  // showDateStatus = ()=>{
  //   const {collectionData} = this.state;
  //   if(collectionData?.biddingStartDateOnUTC != undefined && collectionData.auctionType == 2){
  //     var liveCurrUTC = moment(collectionData?.biddingStartDateOnUTC).format("MM/DD/YYYY hh:mm:ss A");
  //       const startTime = new Date(liveCurrUTC).getTime();
  //       const currTime = new Date().getTime();
  //       if(currTime > startTime){
  //         return "Lot Starts At";
  //       }
  //   }else if()

  // }
  // showPlaceBidBtnCheck = () => {
  //   const { collectionData } = this.state;
  //   console.log("collectionData", collectionData);
  //   if (
  //     collectionData?.biddingStartDateOnUTC != undefined &&
  //     collectionData.auctionType == 2
  //   ) {
  //     // var currUTC = moment(collectionData?.biddingStartDateOnUTC).format("MM/DD/YYYY hh:mm:ss A UTC");
  //     // var currUTC1 = moment(collectionData?.biddingStartDateOnUTC).format("MM/DD/YYYY hh:mm:ss A UTC").getTime();
  //     // const collTime = new Date(currUTC).getTime();
  //     const gmtDateTime = moment.utc(
  //       collectionData?.biddingStartDateOnUTC,
  //       "YYYY-MM-DD h:mm:ss a"
  //     );
  //     const local_date = gmtDateTime.local().format("MM/DD/YYYY hh:mm:ss A");
  //     const collTime333 = new Date(local_date).getTime();
  //     // console.log("local_date",collTime333);

  //     const currTime = new Date().getTime();
  //     if (currTime > collTime333) {
  //       this.setState({ showPlaceBidBtn: true });
  //       // this.setState({});
  //       // document.getElementById("dateStatus").innerHTML=
  //       // document.getElementById("demo").innerHTML = "Lot Starts At";
  //     }
  //     if (currTime < collTime333) {
  //       this.setState({ lotStatusText: "Lot Starts At" });
  //     }
  //     const gmtDateTime1 = moment.utc(
  //       collectionData?.startOnUtc,
  //       "YYYY-MM-DD h:mm:ss a"
  //     );
  //     const local_date1 = gmtDateTime1.local().format("MM/DD/YYYY hh:mm:ss A");
  //     const collTime3331 = new Date(local_date1).getTime();

  //     // var currUTCStart = moment(collectionData?.startOnUtc).format("MM/DD/YYYY hh:mm:ss A UTC");
  //     // const collStartTime = new Date(currUTCStart).getTime();
  //     if (currTime > collTime3331) {
  //       this.setState({ showPlaceBidBtn: false });
  //     }
  //   } else if (collectionData?.auctionType == 1) {
  //     if (collectionData?.startOnUtc != undefined) {
  //       // var fullDT1 = dd.substr(0, 11)+tt;
  //       // var currUTC = moment(collectionData?.startOnUtc).format("MM/DD/YYYY hh:mm:ss A UTC");
  //       // const collTime1 = new Date(currUTC).getTime();
  //       const gmtDateTime = moment.utc(
  //         collectionData?.startOnUtc,
  //         "YYYY-MM-DD h:mm:ss a"
  //       );
  //       const local_date = gmtDateTime.local().format("MM/DD/YYYY hh:mm:ss A");
  //       const collTime1 = new Date(local_date).getTime();

  //       const currTime1 = new Date().getTime();
  //       if (currTime1 < collTime1) {
  //         this.setState({ lotStatusText: "Lot Starts At" });
  //       } else if (collectionData?.endOnUtc != undefined) {
  //         // var fullDT2 = ddd.substr(0, 11)+ttt;
  //         // var startCurrUTC = moment(collectionData?.startOnUtc).format("MM/DD/YYYY hh:mm:ss A UTC");
  //         //   var currUTC = moment(collectionData?.endOnUtc).format("MM/DD/YYYY hh:mm:ss A UTC");
  //         //   const startCollTime2 = new Date(startCurrUTC).getTime();
  //         //   const collTime2 = new Date(currUTC).getTime();

  //         const gmtDateTime1 = moment.utc(
  //           collectionData?.startOnUtc,
  //           "YYYY-MM-DD h:mm:ss a"
  //         );
  //         const local_date1 = gmtDateTime1
  //           .local()
  //           .format("MM/DD/YYYY hh:mm:ss A");
  //         const startCollTime2 = new Date(local_date1).getTime();

  //         const gmtDateTime2 = moment.utc(
  //           collectionData?.endOnUtc,
  //           "YYYY-MM-DD h:mm:ss a"
  //         );
  //         const local_date2 = gmtDateTime2
  //           .local()
  //           .format("MM/DD/YYYY hh:mm:ss A");
  //         const collTime2 = new Date(local_date2).getTime();

  //         const currTime2 = new Date().getTime();
  //         if (startCollTime2 < currTime2 && currTime2 < collTime2) {
  //           // console.log("endOnUtcendOnUtc4444");
  //           this.setState({
  //             lotStatusText: "Lot Closes:",
  //             showPlaceBidBtn: true,
  //           });
  //         } else if (currTime2 < collTime2) {
  //           // console.log("endOnUtcendOnUtc66666");
  //           this.setState({
  //             showPlaceBidBtn: true,
  //             lotStatusText: "Lot Closed At:",
  //           });
  //         } else if (currTime2 > collTime2) {
  //           this.setState({ lotStatusText: "Lot Closed:" });
  //         }
  //       }
  //     }
  //   }
  // };
  handleShowCurrency = (itttM: any) => {
    this.setState({ selectedCurrency: itttM });
  };
  handleShowPrevBids = (count: any) => {
    if (count > 0) {
      this.setState({ showPrevBids: true });
    }
  };

  handleCloseModal = () => {
    this.setState({ showRegistrationModal: false });
  };
  handleOpenModal = () => {
    this.setState({ showRegistrationModal: true });
  };
  getRegisterButton = () => {
    this.setState({ registerButon: true });
  };
  render() {
    const {
      collectionData,
      currenciesList,
      lotData,
      collectionItems,
      imagesList,
      timeZones,
      prevLot,
      nextLot,
      highestBidDetails,
      showPlaceBidBtn,
      isLoading,
      lotStatusText,
      errorMsg,
      isPageLoading,
      highestBidResp,
      selectedCurrency,
      isBiddingClosed,
    } = this.state;
    let currencyName = currenciesList?.find(
      (i: any) => collectionData?.currency == i?.id
    )?.currencyCode;
    let bannerTimeZone = timeZones?.find(
      (i: any) => collectionData?.timeZoneId === i?.id
    )?.abbrivation;
    if (!bannerTimeZone) {
      bannerTimeZone = timeZones?.find(
        (i: any) => collectionData?.timeZoneId === i?.id
      )?.name;
    }
    const rateAmount = selectedCurrency?.conversion_rates
      ? selectedCurrency?.conversion_rates[selectedCurrency?.base_code]
      : 1;
    console.log("rateAmount", selectedCurrency);

    const handleChecktime = (time: any) => {
      if (time) {
        const expiresOnDate = new Date(time);

        // Get the current date and time
        const currentDate = new Date();

        console.log(
          currentDate,
          expiresOnDate,
          "handleChecktime(collectionData?.startOnUtc)"
        );
        // Check if the expiresOnDate is less than the current date
        if (currentDate < expiresOnDate) {
          return true;
        } else {
          return false;
        }
      }
    };

    const handleCheckLocaltime = (time: any) => {
      if (time) {
        const formatedTime = moment
          .utc(time)
          .local()
          .format("YYYY-MM-DD HH:mm");

        const expiresOnDate = new Date(formatedTime);

        // Get the current date and time
        const currentDate = new Date();

        if (currentDate < expiresOnDate) {
          return true;
        } else {
          return false;
        }
      }
    };
    const collSLocalTim = convertUTCToLMilleSec(collectionData?.startOnUtc);

    const currentTime = convertLMilleSec();

    const sDate =
      collectionData?.auctionType === 2
        ? collectionData?.liveStartDate
        : collectionData?.onlineStartDate;
    const sTime =
      collectionData.auctionType === 2
        ? collectionData?.liveStartTime
        : collectionData?.onlineStartTime;

    return (
      <LotDeatilsStyled>
        {this.state.showRegistrationModal && (
          <BidRegistrationModal
            showRegistrationModal={this.state.showRegistrationModal}
            setShowRegistrationModal={this.handleCloseModal}
            collectionId={collectionData}
            toPage={"top-banner"}
            setRegisterButon={this.getRegisterButton}
          />
        )}
        {errorMsg ? (
          <p className="noResults" id="errorPage">
            No Results are Available in Lot Details !{" "}
          </p>
        ) : (
          <>
            {isLoading ? (
              <>
                <div className="spinner_Loading_icon loaderClassforLotDetails">
                  <ContentLoaderSection section="lotdetails" />
                </div>
              </>
            ) : (
              <>
                {isPageLoading && <Loader />}
                <div className="main-content-lot-details lot-details-1">
                  {!isMobile && (
                    <div className="lot-detils-sec-top ">
                      <div className="breadcrumb_left  align-items-center">
                        <Link
                          to={`/collectionDetails/${lotData?.collectionId}`}
                          className="breadcumb_btn text-decoration-none"
                        >
                          <svg
                            width="8"
                            height="14"
                            viewBox="0 0 8 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="mr-2"
                          >
                            <path
                              d="M7 13L1 7L7 1"
                              stroke="#111111"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                          {collectionData?.title}
                        </Link>
                      </div>
                      <div className="breadcrumb_right  align-items-center">
                        {/* <CurrencyConverterBox
                          handleShowCurrency={this.handleShowCurrency}
                        /> */}
                        {prevLot?.itemId && (
                          <Button
                            onClick={() => {
                              this.gotoNextLot(
                                `/lotDetails/${prevLot?.itemId}`,
                                prevLot?.itemId
                              );
                            }}
                            className="  breadcumb_btn breadcumb_btn_lot_d align-items-center text-decoration-none"
                          >
                            Lot {prevLot?.sno}{" "}
                            <img src={Art2} className="mx-2" />
                          </Button>
                        )}
                        {nextLot?.itemId && (
                          <>
                            <Button
                              onClick={() => {
                                this.gotoNextLot(
                                  `/lotDetails/${nextLot?.itemId}`,
                                  nextLot?.itemId
                                );
                              }}
                              className=" breadcumb_btn breadcumb_btn_lot_d align-items-center text-decoration-none"
                            >
                              <img src={Art1} className="mx-2" /> Lot{" "}
                              {nextLot?.sno}
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="lot-detils-sec-main-content">
                    <div className="lot-detils-main-content-left">
                      <div className="lot_no d-inline-block bg-white lot-no-sec-top-details-page">
                        Lot {lotData?.sno}
                      </div>
                      <div className="inner_banner_slider position-relative">
                        <div className="banner_left">
                          <div className="banner_breadcrumb breadcrumb_hide_mobile d-none d-lg-flex align-items-center justify-content-between"></div>
                          <Breadcrumb className="d-lg-none d-block custom_breadcrumb">
                            <Breadcrumb.Item
                              href="#"
                              className="text-decoration-none"
                            >
                              {collectionData?.title}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                              Lot {lotData?.sno}
                            </Breadcrumb.Item>
                          </Breadcrumb>
                          <div className="banner_for_main">
                            <ImageGallerySlider images={imagesList} />
                          </div>
                        </div>
                      </div>
                      {/* {!isMobile && ( */}
                      <div className="product_description product_description_desktop">
                        <DescriptionTabs
                          lotData={lotData}
                          collectionItems={collectionItems}
                          currenciesList={currenciesList}
                          collectionData={collectionData}
                          gotoBigPage={this.gotoBigPage}
                        />
                      </div>
                      {/* )} */}
                    </div>
                    <div className="lot-detils-main-content-right inner_banner_slider">
                      {" "}
                      <div className="banner_right_cont">
                        <div className="right_cont_details_box bg-white">
                          <h3 className="makers-name lot-page mt-0">
                            {lotData?.makerName}
                          </h3>
                          <h4>{lotData?.title} </h4>
                          {lotData?.bidsCount > 0 &&
                            lotData?.myBidsCount > 0 &&
                            lotData?.status !== 6 &&
                            lotData?.status !== 7 && (
                              <>
                                {!lotData?.outFromBid ? (
                                  <div className="card-box-top-right-new green-colo-new lot-page">
                                    You Have the Highest Bid
                                  </div>
                                ) : (
                                  <div className="card-box-top-right-new lot-page">
                                    You Have Been Outbid
                                  </div>
                                )}
                              </>
                            )}
                          {/* // show lot clossess */}
                          {/* <p>XXXXXXXXXXXXX</p> */}

                          {!lotData?.myBidsCount && lotData?.showPlaceBid && (
                            <div className="lot-bidding-open-text">
                              Bidding Open
                            </div>
                          )}
                          {!lotData?.showPlaceBid &&
                          handleChecktime(lotData?.extentedExpiresOnUtc) ? (
                            <div className="lot-bidding-not-started">
                              Bidding Not Started
                            </div>
                          ) : null}

                          {lotData?.status === 6 ? (
                            <div className="lot-bidding-not-started">
                              Bidding is Closed
                            </div>
                          ) : null}

                          <div className="lots_cost lots_cost_lat ">
                            {lotData?.status === 6 ? (
                              <>
                                {lotData?.soldFor !== 0 && LOGINID && (
                                  <>
                                    <span className="d-block lotDetailsTextRightSide">
                                      Lot Sold:
                                    </span>
                                    <span className="strong d-block">
                                      {lotData?.soldFor?.toLocaleString()}{" "}
                                      {currencyName}
                                    </span>{" "}
                                    <div className="sale-amount-text green-color-amount-text mt-1">
                                      {convertSelectedCountryCurrency(
                                        lotData?.soldFor,
                                        this.props.selectedSecondaryCurrency
                                      )}{" "}
                                      {this.props.selectedSecondaryCurrency
                                        ?.base_code !== "USD" &&
                                        this.props.selectedSecondaryCurrency
                                          ?.base_code}
                                    </div>
                                  </>
                                )}
                              </>
                            ) : lotData?.status === 7 ? (
                              <span className="lot-bidding-not-started">
                                Bidding is Closed
                              </span>
                            ) : (
                              <>
                                <span className="lotDetailsTextRightSide lot-current-bid-sec">
                                  {lotData?.showPlaceBid && !isBiddingClosed
                                    ? "Current Bid:"
                                    : "Starting Bid: "}{" "}
                                  <div>
                                    <span className="bid-cost-details">
                                      {highestBidDetails?.amount?.toLocaleString()}{" "}
                                      {currencyName}
                                    </span>{" "}
                                    {lotData?.showPlaceBid &&
                                    !isBiddingClosed ? (
                                      <>
                                        (
                                        <span
                                          onClick={() => {
                                            this.handleShowPrevBids(
                                              lotData?.bidsCount
                                            );
                                          }}
                                          className={`bids-count-no bid-count-cursor bids-count-number${lotData?.bidsCount}`}
                                        >
                                          {lotData?.bidsCount}{" "}
                                          {lotData?.bidsCount == 1
                                            ? "Bid"
                                            : "Bids"}
                                        </span>
                                        , Reserve
                                        {highestBidDetails?.metResevedPrice
                                          ? ""
                                          : " Not"}{" "}
                                        Met)
                                      </>
                                    ) : (
                                      <span>(Reserve, Yes)</span>
                                    )}
                                  </div>
                                </span>
                                <div className="sale-amount-text green-color-amount-text mt-1">
                                  {convertSelectedCountryCurrency(
                                    highestBidDetails?.amount,
                                    this.props.selectedSecondaryCurrency
                                  )}{" "}
                                  {this.props.selectedSecondaryCurrency
                                    ?.base_code !== "USD" &&
                                    this.props.selectedSecondaryCurrency
                                      ?.base_code}
                                </div>
                              </>
                            )}
                          </div>
                          {/* {lotData?.maxAmount > 0 &&
                            lotData?.status !== 6 &&
                            lotData?.status !== 7 && (
                              <div className="your-max-bid-amount-text your-max-bid-amount-text-lot-details-page">
                                Your current Maximum Bid Amount is{" "}
                                {currencyName}{" "}
                                {lotData?.maxAmount?.toLocaleString()}
                              </div>
                            )} */}

                          <div className="lots_cost mb-1">
                            <span className="d-block lotDetailsTextRightSide lot-current-bid-sec">
                              Estimate:
                            </span>
                            <span className="d-block bid-cost-details">
                              {lotData?.estimatedPrice?.toLocaleString()}{" "}
                              {currencyName}
                              {lotData?.estimatedPriceTo && (
                                <>
                                  {` `} -{" "}
                                  {lotData?.estimatedPriceTo?.toLocaleString()}{" "}
                                  {currencyName}
                                </>
                              )}
                            </span>
                          </div>
                          <div className="sale-amount-text green-color-amount-text mb-3">
                            {convertSelectedCountryCurrency(
                              lotData?.estimatedPrice,
                              this.props.selectedSecondaryCurrency
                            )}{" "}
                            {this.props.selectedSecondaryCurrency?.base_code !==
                              "USD" &&
                              this.props.selectedSecondaryCurrency?.base_code}
                            {lotData?.estimatedPriceTo && (
                              <>
                                {" "}
                                {this.props.selectedSecondaryCurrency
                                  ?.base_code !== "USD" &&
                                  this.props.selectedSecondaryCurrency
                                    ?.base_code &&
                                  " - "}
                                {convertSelectedCountryCurrency(
                                  lotData?.estimatedPriceTo,
                                  this.props.selectedSecondaryCurrency
                                )}{" "}
                                {this.props.selectedSecondaryCurrency
                                  ?.base_code !== "USD" &&
                                  this.props.selectedSecondaryCurrency
                                    ?.base_code}
                              </>
                            )}
                          </div>

                          {!isBiddingClosed && (
                            <div className="lot-details-timer-sec">
                              {collectionData?.startOnUtc &&
                                !(
                                  lotData?.status === 7 || lotData?.status === 6
                                ) && (
                                  <>
                                    {!handleCheckLocaltime(
                                      collectionData?.startOnUtc
                                    ) ? (
                                      <LotDetailsTime
                                        dateText={
                                          !handleChecktime(
                                            collectionData?.startOnUtc
                                          )
                                            ? "Lot Begins Closing:"
                                            : "Bidding Starts:"
                                        }
                                        lotData={lotData}
                                        frompage="lotpage"
                                        collectionData={collectionData}
                                        bannerTimeZone={bannerTimeZone}
                                        updateIsExpiredButton={() => {
                                          this.setState({
                                            isBiddingClosed: true,
                                          });
                                        }}
                                      />
                                    ) : (
                                      <BiddingStartingComp
                                        sTime={sTime}
                                        sDate={sDate}
                                        bannerTimeZone={bannerTimeZone}
                                        formPage="lotpage"
                                        startDate={collectionData?.startOnUtc}
                                      />
                                    )}
                                  </>
                                )}
                            </div>
                          )}

                          {(lotData?.status === 6 || lotData?.status === 7) && (
                            <div className="bidding-date-closed-text">
                              {moment
                                .utc(lotData?.extentedExpiresOnUtc)
                                .tz("America/New_York")
                                .format("MMMM D, YYYY [|] h:mm A")}{" "}
                              (ET)
                            </div>
                          )}

                          <div className="btn_lots d-flex align-items-center">
                            <div className="pl-2 w-50">
                              {lotData?.ifollowed ? (
                                <Button
                                  onClick={() => {
                                    this.unFollowBid(lotData?.id);
                                  }}
                                  className=" followingThisBid lots_btn_same_placeBid lots_btn_same text-decoration-none text-center d-inline-block w-100"
                                >
                                  FOLLOWING
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => {
                                    this?.followBid(lotData?.id);
                                  }}
                                  className="lots_btn_same lot-follow-btn-back lots_btn_same_placeBid text-decoration-none text-center d-inline-block w-100"
                                >
                                  FOLLOW
                                </Button>
                              )}
                            </div>
                            {lotData?.status !== 6 &&
                              lotData?.status !== 7 &&
                              !isBiddingClosed &&
                              !lotData?.showPlaceBid && (
                                <>
                                  {collectionData?.registredToCollection ||
                                  this.state.registerButon ? (
                                    <>
                                      <div className="pr-2 w-50">
                                        <button
                                          disabled={true}
                                          className="lots_btn_same lots_btn_same_placeBid bid-white text-white text-decoration-none text-center d-inline-block w-100"
                                        >
                                          REGISTERED
                                        </button>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {lotStatusText !== "Lot Closed:" && (
                                        <div className="pr-2 w-50">
                                          <button
                                            onClick={() => {
                                              this.handleOpenModal();
                                            }}
                                            id={`registered-button-lot-${collectionData?.id}`}
                                            className="lots_btn_same lots_btn_same_placeBid bid-white text-white text-decoration-none text-center d-inline-block w-100"
                                          >
                                            REGISTER TO BID
                                          </button>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </>
                              )}

                            {lotData?.status !== 6 &&
                              lotData?.status !== 7 &&
                              !isBiddingClosed &&
                              lotData?.showPlaceBid && (
                                <>
                                  {collectionData?.registredToCollection ||
                                  this.state.registerButon ? (
                                    <div className="pr-2 w-50">
                                      <button
                                        onClick={this.handlePlaceBid}
                                        className="lots_btn_same lots_btn_same_placeBid bid-white text-white text-decoration-none text-center d-inline-block w-100"
                                      >
                                        <img
                                          src={BidWhite}
                                          className="bid_icon"
                                        />{" "}
                                        PLACE BID
                                      </button>
                                    </div>
                                  ) : (
                                    <>
                                      {lotStatusText !== "Lot Closed:" && (
                                        <div className="pr-2 w-50">
                                          <button
                                            onClick={() => {
                                              this.handleOpenModal();
                                            }}
                                            id={`registered-button-lot-${collectionData?.id}`}
                                            className="lots_btn_same lots_btn_same_placeBid bid-white text-white text-decoration-none text-center d-inline-block w-100"
                                          >
                                            REGISTER TO BID
                                          </button>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                          </div>
                        </div>
                        <div className="lot-bidding-works-items-sec">
                          <div className=" lot-bidding-works-items-sec-how-b cur_convt d-flex align-items-center justify-content-end">
                            <p
                              className="BidIncrementTableText"
                              onClick={() => this.howBidWorkCall()}
                            >
                              {" "}
                              <span className="InfoIconSpan">
                                <img
                                  src={infoIconIcon}
                                  width="20"
                                  height="20"
                                />
                              </span>
                              How Bidding Works
                            </p>
                          </div>
                          <div className="dash-line-bidding-sec"></div>
                          <div className="cur_convt d-flex align-items-center justify-content-end">
                            <p
                              className="BidIncrementTableText"
                              onClick={() => this.ShowBidIncrementModal()}
                            >
                              <span className="InfoIconSpan">
                                <img
                                  src={infoIconIcon}
                                  width="20"
                                  height="20"
                                />
                              </span>
                              Bid Increments Table
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* {isMobile && ( */}
                    <div className="product_description product_description_mobile">
                      <DescriptionTabs
                        lotData={lotData}
                        collectionItems={collectionItems}
                        currenciesList={currenciesList}
                        collectionData={collectionData}
                        gotoBigPage={this.gotoBigPage}
                      />
                    </div>
                    {/* )} */}
                  </div>
                </div>
                <BidIncrementTable
                  ShowBidIncrementTable={this.state.ShowBidIncrementTable}
                  hideModal={this.hideModal}
                  bidIncrementTableData={this.state.bidIncrementTableData}
                  currencyName={currencyName}
                />
                <CustomModal
                  show={this.state.warningModelPopup}
                  titleText="Submit your identification "
                  subTitleText="In order to place a bid on a lot in this auction, please complete FAHH’s verification process."
                  deleteText="CONTINUE"
                  cancelText=""
                  onCancel={() => {}}
                  onDelete={() => {
                    this.setState({ warningModelPopup: false });
                    this.gotoUploadPhotoPage();
                  }}
                />{" "}
                <CustomModal
                  show={this.state.userRejectedAddressPopup}
                  // titleText="Submit your identification "
                  subTitleText="Your ID proof document has been rejected, It appears that there were issues with the provided ID. To proceed, please resubmit a clear and valid ID image."
                  deleteText="REUPLOAD"
                  cancelText="OK"
                  showCancelBtn={true}
                  onCancel={() => {
                    this.setState({ userRejectedAddressPopup: false });
                  }}
                  onDelete={() => {
                    this.setState({ userRejectedAddressPopup: false });
                    this.gotoUploadPhotoPage();
                  }}
                />{" "}
                <CustomModal
                  show={this.state.registerBidSuccess}
                  titleText={`You have been successfully registered for the ${
                    collectionData?.auctionType == 1 ? "Online Only" : "Live"
                  } Auction`}
                  deleteText="OK"
                  cancelText=""
                  onCancel={() => {}}
                  onDelete={() => {
                    this.setState({ registerBidSuccess: false });
                  }}
                />
                <CustomModal
                  show={this.state.errorModelPopup}
                  titleText={this.state.errorTextData}
                  deleteText="OK"
                  cancelText=""
                  longText={true}
                  onCancel={() => {}}
                  onDelete={() => {
                    this.setState({ errorModelPopup: false });
                  }}
                />
                <PrevBidDetails
                  ShowPrevBids={this.state.showPrevBids}
                  hideModal={this.hideModal}
                  lotId={this.state.lotData?.id}
                  currencyName={currencyName}
                  collectionData={this.state.collectionData}
                />
              </>
            )}
          </>
        )}
      </LotDeatilsStyled>
    );
  }
}
// export default lotDetails;
const mapStateToProps = (state: any) => {
  return state;
};
// export default withRouter(LoginPopup);
export default connect(mapStateToProps, actionCreators)(lotDetails);
