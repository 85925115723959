import { useLocation } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import { Col } from "react-bootstrap";
import user_img1 from "./../../assets/profile/user-6-fill.png";
import user_img2 from "./../../assets/profile/shopping-bag-fill.png";
import user_img3 from "./../../assets/profile/archive-stack-fill.png";
import user_img4 from "./../../assets/profile/mail-unread-fill.png";
import user_img5 from "./../../assets/profile/customer-service-2-fill.png";
import user_img6 from "./../../assets/profile/logout-box-fill.png";

import s_user_img1 from "./../../assets/profile/image_p_s.png";
import s_user_img2 from "./../../assets/profile/shopping-bag-fill (1).png";
import s_user_img3 from "./../../assets/profile/archive-stack-fill (1).png";
import s_user_img4 from "./../../assets/profile/mail-unread-fill (1).png";
import s_user_img5 from "./../../assets/profile/customer-service-2-fill (1).png";

import "./css/AccountSlideBar.css";
import { AccountSliderStyled } from "./AccountSliderStyled.styled";

const AccountSlideBar = () => {
  const location = useLocation();

  const logoutHandle = () => {
    const code1 = localStorage.getItem("gateCode2") as any;
    const code2 = localStorage.getItem("welcomeModelText") as any;
    localStorage.removeItem("customerInfoDetails");
    localStorage.clear();
    localStorage.setItem("gateCode2", code1);
    localStorage.setItem("welcomeModelText", code2);
    window.location.href = "/";
  };

  const itemsOne = [
    {
      id: 1,
      sidebarLinkName: "Profile",
      linksTitle: "profile",
      links: "/profile",
      icon: user_img1,
      sicon: s_user_img1,
    },
    {
      id: 3,
      sidebarLinkName: "Purchases",
      linksTitle: "purchases",
      links: "/myPurchases",
      icon: user_img2,
      sicon: s_user_img2,
    },
    {
      id: 4,
      sidebarLinkName: "My Followed Lots",
      linksTitle: "followedlots",
      links: "/myFollowedLots",
      icon: user_img3,
      sicon: s_user_img3,
    },
    {
      id: 5,
      sidebarLinkName: "Email Preferences",
      linksTitle: "emailpreferences",
      links: "/emailpreferences",
      icon: user_img4,
      sicon: s_user_img4,
    },
    {
      id: 5,
      sidebarLinkName: "Customer Support",
      linksTitle: "support",
      links: "/support",
      icon: user_img5,
      sicon: s_user_img5,
    },
  ];
  const uuURL = location?.pathname;

  return (
    <>
      <div className="bids_page_main_heading w-100">
        <h2 className="mb-0">My Account</h2>
      </div>
      <Col lg={3} className="my-account-data-new-sec-left">
        <div className="more_collection_colum d-flex d-lg-block">
          <ListGroup className="custom_account_list_sidebar mr-lg-0 mr-auto">
            {itemsOne.map((i1) => {
              return (
                <ListGroup.Item action href={i1.links} title={i1.linksTitle}>
                  <img
                    src={uuURL === i1.links ? i1?.sicon : i1?.icon}
                    alt="profile"
                  />{" "}
                  {i1.sidebarLinkName}
                </ListGroup.Item>
              );
            })}
            <ListGroup.Item
              onClick={logoutHandle}
              className="logout_btn"
              title="Logout"
            >
              <img src={user_img6} alt="logout" /> Logout
            </ListGroup.Item>
          </ListGroup>
        </div>
      </Col>
    </>
  );
};

export default AccountSlideBar;
