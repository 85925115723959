import React, { Component } from "react";
import "./css/style.css";
import { Row, Col, Accordion, Card, Form, Button } from "react-bootstrap";
import uplaodImageNew from "../../services";
import CustomModal from "./../../components/CustomModel";
import Loader from "./../../components/Common/Loader";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import checkboxCircleLine from "./../../assets/main/checkbox-circle-line.png";
import uploadImage from "./../../assets/main/upload-image.png";
import searchIcon from "./../../assets/main/search-icon.png";
import { ReactComponent as AddImageIcon } from "./../../assets/main/AddImageIcon.svg";
import { ReactComponent as DeleteBin } from "./../../assets/main/deleteBin.svg";
import { saveEnquiryFormDataCall } from "./../../library/Requests/MyAccount";
import { validateImageFormat } from "./../../Scenes/common";
class SellingGuides extends React.Component<any, any> {
  static propTypes = {};

  state = {
    thirdImage: "",
    thirdImageFileName: "",
    secondImage: "",
    secondImageFileName: "",
    feedBackImages: [],
    loading: false,
    startUploadCount: 0,
    endUploadCount: 0,
    jsonData: {} as any,
    showSuccessMsg: false,
    errorFields: {
      itemDescription: false,
      metaDescription: false,
      phoneNumber: false,
      dateCreated: false,
      email: false,
      firstName: false,
      itemCondition: false,
      lastName: false,
    },
  };
  componentDidMount() {
    document.title = "FAHH-sellingGuides";
  }
  handleDeleteImage = (image: any, type: any) => {
    if (image?.id != undefined && image?.id) {
      // deleteImageByIdCall(image.id);
    }

    this.setState({ [type]: { preview: "" } });
  };
  handleChnageValue = async (e: any) => {
    const { name, value } = e.target;
    var prevValues = this.state.jsonData as any;
    let errorsList = this.state.errorFields as any;

    prevValues[name] = value;
    errorsList[name] = false;

    this.setState({ jsonData: prevValues, errorFields: errorsList });
  };
  checkAddressValidForm = () => {
    let returnFlag = true;
    let errorsList = this.state.errorFields as any;
    const state = this.state.jsonData as any;
    for (var key in errorsList) {
      const Pvalue = state[key];
      if (
        Pvalue == undefined ||
        Pvalue == "" ||
        Pvalue == null ||
        Pvalue?.trim() == ""
      ) {
        errorsList[key] = true;
        returnFlag = false;
      }
    }
    this.setState({ errorFields: errorsList });
    return returnFlag;
  };

  handleSubmitClick = async () => {
    if (this.checkAddressValidForm()) {
      const JsonD = this.state.jsonData as any;
      //     dateCreated
      // "2023-07-14"
      // email
      // "dddd4rr4rd@gmail.com"
      // firstName
      // "dd"
      // itemCondition
      // "dd"
      // itemDescription
      // "d"
      // lastName
      // "dd"
      // metaDescription
      // "dd"
      // phoneNumber
      // "dddd"
      // var returnFalg =
      JsonD.Images = this.state.feedBackImages;
      if (
        JsonD?.metaDescription &&
        JsonD?.phoneNumber &&
        JsonD?.dateCreated &&
        JsonD?.email &&
        JsonD?.firstName &&
        JsonD?.itemCondition &&
        JsonD?.itemDescription &&
        JsonD?.lastName
      ) {
        this.setState({ loading: true });
        await saveEnquiryFormDataCall(JsonD);
        this.setState({ loading: false, showSuccessMsg: true });
      }
    }
  };
  handleChangeSecond = async (e: any) => {
    if (e.target.files.length) {
      if (validateImageFormat(e.target.files[0].type)) {
        const filesName = e.target.files[0];
        this.setState({
          secondImageFileName: filesName,
          loading: true,
        });
        const respApp = await uplaodImageNew(filesName, "lot");

        const { feedBackImages } = this.state;
        this.setState({
          feedBackImages: [
            ...feedBackImages,
            { imageUrl: respApp?.imageUrl, name: filesName },
          ],
          loading: false,
        });
      }
    }
  };
  handleChangeThird = async (e: any) => {
    if (e.target.files.length) {
      if (validateImageFormat(e.target.files[0].type)) {
        const filesName = e.target.files[0];
        this.setState({
          thirdImageFileName: filesName,
          loading: true,
        });
        const respApp = await uplaodImageNew(filesName, "lot");
        this.setState({
          thirdImage: respApp?.imageUrl,
          loading: false,
        });
      }
    }
  };
  handleChangeDate = (date: any) => {
    console.log("date", date);
    // const state = this.state.userAddressData as any;
    // let errorsList = this.state.errorsAddressFields;
    const dateN = moment(date).format("MM-DD-YYYY");
    var prevValues = this.state.jsonData as any;
    let errorsList = this.state.errorFields as any;

    prevValues.dateCreated = dateN;
    errorsList.dateCreated = false;

    this.setState({ jsonData: prevValues, errorFields: errorsList });
  };

  handleDelete = (ind: number) => {
    this.setState({ loading: true });
    const { feedBackImages } = this.state;
    let array = feedBackImages.filter(
      (item: any, index) => item.name.lastModified != ind
    );
    this.setState({
      feedBackImages: array,
      loading: false,
    });
  };
  render() {
    console.log("this.state.jsonData", this.state.jsonData);
    const {
      thirdImage,
      secondImage,
      loading,
      jsonData,
      secondImageFileName,
      thirdImageFileName,
      showSuccessMsg,
      errorFields,
      feedBackImages,
    } = this.state;
    console.log("secondImage", secondImage);
    console.log("thirdImage", thirdImage);
    const newminData = moment().subtract(0, "years") as any;
    const newmaxData = moment().subtract(100, "years") as any;

    console.log(feedBackImages, "feedBackImages");

    return (
      <div className="selling-guides-container">
        {loading && <Loader />}
        <CustomModal
          show={showSuccessMsg}
          titleText={`Thank you for submitting the details, we will get back you soon.`}
          deleteText="OK"
          cancelText=""
          className={"edit-address-modal"}
          longText={true}
          onCancel={() => {}}
          onDelete={() => {
            this.setState({ showSuccessMsg: false });
            this.props.history.push(`/`);
          }}
        />

        <div className="selling-guides-content">
          <h3 className="selling-header-con">
            Are you interested in selling your Hip Hop inspired fine art or
            collectibles on FineArt.HipHop?
          </h3>
          <p className="selling-sub-text">
            In FAHH’s calendarized auctions, items are bid on and sold to the
            highest bidder in a public sale. If you are interested in selling
            your item on the FAHH auction platform simply follow the steps below
            and a specialist will review your submission.
          </p>
          <div className="selling-how-it-works">
            <div className="s-how-it-work-text">How it Works</div>
          </div>
          <div className="s-how-it-work-con">
            <div className="s-how-it-work-con-box">
              <div>
                <img src={searchIcon} />
              </div>
              <div className="s-how-box-center">Tell us about your Item</div>
              <div className="s-how-box-bottom">
                Provide information that will help us to better evaluate your
                item
              </div>
            </div>
            <div className="s-how-it-work-con-box">
              <div>
                <img src={uploadImage} />
              </div>
              <div className="s-how-box-center">Upload Photos</div>
              <div className="s-how-box-bottom">
                Take front and back images of your item
              </div>
            </div>
            <div className="s-how-it-work-con-box">
              <div>
                <img src={checkboxCircleLine} />
              </div>
              <div className="s-how-box-center">Submit for Review</div>
              <div className="s-how-box-bottom">
                A specialist will review your submission and contact you
                regarding next steps
              </div>
            </div>
          </div>
          <div className="selling-sub-form">
            <h4>Item Submission Form</h4>
            <div className="selling-sub-form-con">
              <p className="selling-basic-information-hea">Basic Information</p>
              <div className="selling-text-box">
                <label>Item Description</label>
                <textarea
                  name="itemDescription"
                  placeholder="Please provide details about your item"
                  rows={7}
                  onChange={this.handleChnageValue}
                  cols={50}
                  className={errorFields?.itemDescription ? "hasError" : ""}
                ></textarea>
              </div>
              <div className="selling-text-box">
                <label>Date Created</label>
                {/* <input
                  name="dateCreated"
                  onChange={this.handleChnageValue}
                  placeholder="MM/dd/YYYY"
                  type="date"
                  // value="MM/dd/YYYY"
                  // format="MM/dd/YYYY"
                  className={errorFields?.dateCreated ? "hasError" : ""}
                /> */}
                <DatePicker
                  value={jsonData?.dateCreated}
                  name="dateOfBirth"
                  placeholderText={"Enter Date"}
                  autoComplete="off"
                  onChange={this.handleChangeDate}
                  maxDate={newminData._d}
                  // minDate={newmaxData._d}

                  // showMonthDropdown={true}
                  // showYearDropdown={true}
                  // scrollableYearDropdown={true}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  // showYearPicker={true}
                  // maxDate={newminData._d}
                  // minDate={newmaxData._d}
                  className={
                    errorFields?.dateCreated
                      ? "error-date-sec-dateof-birth-selling"
                      : ""
                  }
                  dateFormat="MM/dd/yyyy"
                  onKeyDown={(e: any) => {
                    e.preventDefault();
                  }}
                />
              </div>
              <div className="selling-text-box">
                <label>Medium/Material Description</label>
                <textarea
                  placeholder="Please share information about media type, dimensions, etc."
                  rows={7}
                  name="metaDescription"
                  onChange={this.handleChnageValue}
                  cols={50}
                  className={errorFields?.metaDescription ? "hasError" : ""}
                ></textarea>
              </div>
              <div className="selling-text-box">
                <label>Upload Images</label>
                <div className="upload-images-box">
                  <div className="dualImages">
                    <div
                      // sm="5.5"
                      className="imageBlock imageBlockSmall second"
                    >
                      <>
                        {" "}
                        <label
                          htmlFor="uploadButtonSecond"
                          className="labelContainer"
                        >
                          <AddImageIcon />
                          <p className="addImageText">Add Image here</p>
                        </label>
                      </>

                      <>
                        <input
                          id="uploadButtonSecond"
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={this.handleChangeSecond}
                        />
                      </>
                    </div>
                    {/* <div
                      // sm="5.5"
                      className="imageBlock imageBlockSmall third"
                    >
                      {thirdImage ? (
                        <>
                          <DeleteBin
                            className="deleteImageIcon"
                            onClick={() =>
                              this.setState({
                                thirdImage: "",
                              })
                            }
                          />
                          <img
                            src={thirdImage}
                            alt="Preview"
                            className="previewImage previewImage-selling"
                          />
                        </>
                      ) : (
                        <>
                          <label
                            htmlFor="uploadButtonThird"
                            className="labelContainer"
                          >
                            <AddImageIcon />
                            <p className="addImageText">Add Image here</p>
                            <p className="blackText">Back </p>
                          </label>
                        </>
                      )}

                      {!thirdImage && (
                        <input
                          id="uploadButtonThird"
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={this.handleChangeThird}
                        />
                      )}
                    </div> */}
                  </div>
                </div>
                <div className="images-feedback-sec">
                  {feedBackImages?.map((item: any, index: number) => (
                    <div className="images-sec" key={index}>
                      <img src={item?.imageUrl} alt="image-feedback" />
                      <DeleteBin
                        className="deleteImageIcon"
                        onClick={() =>
                          this.handleDelete(item?.name.lastModified)
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="selling-text-box">
                <label>Item Condition</label>
                <input
                  type="text"
                  placeholder="Describe Item Condition "
                  id="itemCondition"
                  name="itemCondition"
                  onChange={this.handleChnageValue}
                  className={errorFields?.itemCondition ? "hasError" : ""}
                />
              </div>
              <div className="selling-contact-info">Contact Information</div>
              <div className="selling-text-box">
                <label>First Name</label>
                <input
                  type="text"
                  placeholder="Enter First Name"
                  id="firstName"
                  name="firstName"
                  onChange={this.handleChnageValue}
                  className={errorFields?.firstName ? "hasError" : ""}
                />
              </div>
              <div className="selling-text-box">
                <label>Last Name</label>
                <input
                  type="text"
                  placeholder="Enter Last Name"
                  id="lastName"
                  name="lastName"
                  onChange={this.handleChnageValue}
                  className={errorFields?.lastName ? "hasError" : ""}
                />
              </div>
              <div className="selling-text-box">
                <label>Email Address</label>
                <input
                  type="text"
                  placeholder="Enter Email Address"
                  id="email"
                  name="email"
                  onChange={this.handleChnageValue}
                  className={errorFields?.email ? "hasError" : ""}
                />
              </div>
              <div className="selling-text-box">
                <label>Phone Number</label>
                <input
                  type="text"
                  placeholder="Enter Phone Number"
                  id="phoneNumber"
                  name="phoneNumber"
                  onChange={this.handleChnageValue}
                  className={errorFields?.phoneNumber ? "hasError" : ""}
                />
              </div>
              <div className="selling-bubmit-box">
                <button onClick={this.handleSubmitClick}>SUBMIT</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default SellingGuides;
