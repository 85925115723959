import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import AccountSlideBar from "../../components/AccountSlideBar";
import PurchasesListcontent from "../../components/PurchasesListcontent";

import "./css/lots.css";

import { getcurrenciesCall } from "./../../library/Requests";
import { getcustomerfollowedbidsCall } from "./../../library/Requests/MyAccount";
import { LOGINID } from "./../../Scenes/common";
import Loader from "../../components/Common/Loader";

class MyFollowedLots extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      currenciesList: [],
      Mylots: [],
      errorMsg: false,
      loading: false,
    };
  }
  componentDidMount() {
    this.getcurrenciesDataCall();
    this.getDataonLoad();
    document.title = "FAHH-myFollowedLots";
  }

  getcurrenciesDataCall = async () => {
    const resp = await getcurrenciesCall();
    this.setState({ currenciesList: resp?.data });
  };
  getDataonLoad = async () => {
    this.setState({ loading: true });
    const resp = await getcustomerfollowedbidsCall(LOGINID);
    if (resp?.totalCount == 0) {
      this.setState({ errorMsg: true });
    }
    this.setState({ Mylots: resp?.followedBids });
    this.setState({ loading: false });
  };

  render() {
    const { currenciesList, Mylots, errorMsg, loading } = this.state;
    return (
      <>
        {/* */}
        <div className="purchases_page_content followed_lots_page_content account_all_page">
          {loading && <Loader />}
          <Container>
            <Row className="purchases_page_left_right_content">
              <AccountSlideBar />
              <Col lg={9}>
                <div className="purchases_page_right_content account_all_right_content">
                  <div className="purchases_page_active_bid account_all_right_section account_all_right_section1 pb-0">
                    <div className="account_all_right_content_heading pb-3">
                      <h4 className="mb-0 my-followed-lots-sec-txt11 email-preferences-content-page-h3">
                        My Followed Lots
                      </h4>
                    </div>
                    <div className="purchases_no_activity account_all_right_section2">
                      {errorMsg ? (
                        <div className="noRecordsData">
                          You are not currently following any lots.
                        </div>
                      ) : (
                        <PurchasesListcontent
                          Mylots={Mylots}
                          myfollowedLots={true}
                          currenciesList={currenciesList}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default MyFollowedLots;
