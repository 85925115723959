import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Button, Card } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import BottomArrow from "../../assets/image/Bottom_arrow.svg";
import FilterBtn from "../../assets/image/filter.png";
import CloseIcon from "../../assets/image/close.svg";
import { isMobile } from "react-device-detect";

import "./css/ActionCalendarLeftFilter.css";
import { DatePicker } from "antd";
import moment from "moment";
const { RangePicker } = DatePicker;

const ActionCalendarLeftFilter = (props: any) => {
  const {
    selectedMonths,
    setSelectedMonths,
    setSelectedYears,
    selectedYears,
    setSDate,
    setEDate,
    sDate,
    eDate,
    setSortColumnChange,
    sortColumnChange,
    AllCollectionsFull,
    isLoading,
    setPageLoadedState,
    setDates,
    dates,
    setIsLoading,
    setCurrentPage,
    setCalenderPageLoadingState,
    setSelectedEvents,
    selectedEvents,
  } = props;
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);

  const [selectedCategories, setSelectedCategories] = useState([]);

  const FilterValueSelect = async (e: any, artist: any, type: any) => {
    if (type == "Events") {
      if (e.target.checked) {
        setSelectedEvents(selectedEvents.concat(artist));
      } else {
        const existData = selectedEvents;
        const index = existData?.findIndex((el: any, ind: any) => el == artist);
        existData?.splice(index, 1);
        setSelectedEvents(existData);
      }
      setCalenderPageLoadingState(true);
    } else if (type == "Category") {
      if (e.target.checked) {
        setSelectedCategories(selectedCategories.concat(artist));
      } else {
        const existData = selectedCategories;
        const index = existData?.findIndex((el: any, ind: any) => el == artist);
        existData?.splice(index, 1);

        setSelectedCategories(existData);
      }
    } else if (type === "Month") {
      setSelectedMonths(artist);
      setCurrentPage(1);

      await handleSettingState();
    } else if (type === "Year") {
      setSelectedYears(artist);
      setCurrentPage(1);
      await handleSettingState();
    }
  };
  const clearAllFilters = (e: any, type: any) => {
    if (type == "ClearAll") {
      if (e.target.click) {
        document
          .querySelectorAll("input[type=checkbox]")
          .forEach((el: any) => (el.checked = false));

        setSelectedEvents([]);
        setSelectedYears(moment().format("YYYY"));
        setSelectedCategories([]);
        setSelectedMonths(moment().format("MMMM"));
      }
    }
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const thisMonth: any = months[new Date().getMonth()];

  const { handleTab, tabMonths } = props;
  const auctionTypeList = props?.AllCollectionsFull?.auctionType;
  const yearsList = props?.AllCollectionsFull?.years;

  const handleDateChange = (seDate: any) => {
    setDates(seDate);
    if (seDate) {
      const sDate = moment(seDate[0]?.$d).format("YYYY-MM-DD");
      const eDate = moment(seDate[1]?.$d).format("YYYY-MM-DD");
      setSDate(sDate);
      setEDate(eDate);
    }
    setCalenderPageLoadingState(true);
  };

  const handleReset = async () => {
    await handleClearAll();
    await handleSettingState();
  };

  const handleClearAll = () => {
    setSelectedMonths(moment().format("MMMM"));
    setSelectedYears(moment().format("YYYY"));
  };

  const handleSettingState = () => {
    setPageLoadedState(true);
    setIsLoading(true);
  };

  const handleChecked = (type: any) => {
    let [value] = selectedEvents.filter((i: any) => i == type);

    if (value) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="action_calendar_left_filter">
        {!isMobile && (
          <div className="custom_slider_heading_filter pt-0 d-flex align-items-center">
            <Form.Group
              controlId="exampleForm.ControlSelect1"
              className="ml-auto d-md-none"
            >
              <Form.Control
                as="select"
                onChange={(event) => handleTab(event.target.value)}
              >
                <option value="all">All</option>
                {tabMonths.map((m: any) => {
                  return (
                    <option key={m} value={m}>
                      {thisMonth === m ? "This Month" : m}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </div>
        )}
        <div className="action_calenda_filter_menu">
          <div
            className={`results-text ${
              props?.isResultsPage ? "auction-results-page" : ""
            }`}
          >
            Showing {AllCollectionsFull?.totalCount} results
          </div>

          {isMobile && (
            <h3
              className="filter_heading"
              onClick={() => {
                setFilterMenuOpen(true);
              }}
            >
              <img
                src={FilterBtn}
                alt="filter-btn"
                className="mr-2 d-inline-block d-lg-none"
              />
              Filter By &nbsp;
              {!isMobile && (
                <span
                  className="clearAllStyles"
                  onClick={(e) => {
                    clearAllFilters(e, "ClearAll");
                  }}
                >
                  Clear all
                </span>
              )}
            </h3>
          )}

          <Accordion className={`${filterMenuOpen && "active"}`}>
            <div className="responsive_menu_close mb-3 d-flex align-items-center justify-content-end d-lg-none">
              {/* <Link
                to="/auction-calendar"
                className="mr-auto text-decoration-none"
                onClick={(e) => {
                  clearAllFilters(e, "ClearAll");
                }}
              >
                Clear All
              </Link> */}
              <div
                className="close_icon"
                onClick={() => {
                  setFilterMenuOpen(false);
                }}
              >
                <img src={CloseIcon} alt="close_icon" />
              </div>
            </div>
            <div className="mobile_right_sidebar_content">
              {props?.isResultsPage && (
                <section className="sort-by-text">
                  <h2>Filter by</h2>
                  {(selectedMonths !== moment().format("MMMM") ||
                    selectedYears != moment().format("YYYY")) && (
                    <span
                      onClick={() => {
                        handleReset();
                      }}
                    >
                      CLEAR ALL
                    </span>
                  )}
                </section>
              )}

              {!props?.isResultsPage && (
                <section className="sort-by-text">
                  <h2>Sort By</h2>
                  {(sortColumnChange ||
                    (sDate && eDate) ||
                    selectedEvents?.length > 0) && (
                    <span
                      onClick={() => {
                        setSortColumnChange();
                        setEDate("");
                        setSDate("");
                        setDates([]);
                        setSelectedEvents([]);
                        setCalenderPageLoadingState(true);
                      }}
                    >
                      CLEAR ALL
                    </span>
                  )}
                </section>
              )}

              {props?.isResultsPage && (
                <>
                  <Card className="rounded-0 border-0 mb-2">
                    <Card.Header className="p-0 border-0 bg-transparent mb-2">
                      <Accordion.Toggle
                        className="w-100 mt-2  text-left text-decoration-none rounded-0"
                        as={Button}
                        variant="link"
                        eventKey="0"
                      >
                        <span className="mr-auto d-block mb-2">Year</span>
                        <div className="d-flex justify-content-between">
                          <span className="selected-year-span">
                            {selectedYears}
                          </span>
                          <img src={BottomArrow} alt="dropdown arrow" />
                        </div>
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body className="p-0">
                        <div className="filter_list calender-filter-list">
                          {(yearsList || []).map((i: any, index: any) => {
                            return (
                              <div
                                onClick={(e: any) => {
                                  !isLoading && FilterValueSelect(e, i, "Year");
                                }}
                                className={selectedYears == i ? "selected" : ""}
                              >
                                {i}
                              </div>
                            );
                          })}
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card className="rounded-0 border-0 mb-2">
                    <Card.Header className="p-0 border-0 bg-transparent mb-2">
                      <Accordion.Toggle
                        className="w-100  text-left text-decoration-none rounded-0"
                        as={Button}
                        variant="link"
                        eventKey="1"
                      >
                        <span className="mr-auto d-block mb-2">Month</span>
                        <div className="d-flex justify-content-between">
                          <span className="selected-month-span">
                            {selectedMonths}
                          </span>
                          <img src={BottomArrow} alt="dropdown arrow" />
                        </div>
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body className="p-0">
                        <div className="filter_list calender-filter-list">
                          {(months || []).map((i: any, index: any) => {
                            return (
                              <div
                                onClick={(e: any) => {
                                  !isLoading &&
                                    FilterValueSelect(e, i, "Month");
                                }}
                                className={
                                  selectedMonths === i ? "selected" : ""
                                }
                              >
                                {i}
                              </div>
                            );
                          })}
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </>
              )}
              {!props?.isResultsPage && (
                <Card className="rounded-0 border-0 calender-">
                  <section className="ascending-filter">
                    <Form.Group controlId={"asec"}>
                      <Form.Check
                        type="radio"
                        id={"12"}
                        name="enddate-ascending1"
                        value="Sno_asc"
                        label={"End Date - Ascending"}
                        checked={sortColumnChange === "asc" ? true : false}
                        onChange={() => {
                          setSortColumnChange("asc");
                          setCalenderPageLoadingState(true);
                        }}
                      />
                      <Form.Check
                        type="radio"
                        id={"22"}
                        name="enddate-ascending2"
                        value="Sno_desc"
                        label={"End Date - Descending"}
                        checked={sortColumnChange === "desc" ? true : false}
                        onChange={() => {
                          setSortColumnChange("desc");
                          setCalenderPageLoadingState(true);
                        }}
                      />
                    </Form.Group>
                  </section>

                  <div className="sort-by-text mb-3">
                    <h2>Filter by</h2>
                  </div>

                  <div className="ml-2 pl-1">
                    <div className="date-filter-sec">
                      <h3>Date Range</h3>
                    </div>

                    <RangePicker
                      onChange={handleDateChange}
                      placeholder={["From", "To"]}
                      className="date-range-picker"
                      value={dates}
                    />

                    <Card.Header className="p-0 sales-card-section border-0 bg-transparent">
                      <Accordion.Toggle
                        className="w-100 d-flex align-items-center mt-4 text-left text-decoration-none rounded-0"
                        as={Button}
                        variant="link"
                        eventKey="2"
                      >
                        <span className="mr-auto d-block sales-heading">
                          Sales
                        </span>
                      </Accordion.Toggle>
                    </Card.Header>
                    <Card.Body className="p-0">
                      <div className="filter_list">
                        {(auctionTypeList || []).map((i: any) => {
                          return (
                            <Form.Group controlId={i.type} key={i.type}>
                              <Form.Check
                                onClick={(e: any) => {
                                  FilterValueSelect(e, i?.type, "Events");
                                }}
                                type="checkbox"
                                label={`${
                                  i.auction === "Online"
                                    ? "Online Only"
                                    : "Live"
                                }`}
                                checked={handleChecked(i?.type)}
                              />
                            </Form.Group>
                          );
                        })}
                      </div>
                    </Card.Body>
                  </div>
                </Card>
              )}
              {/* <Card className="rounded-0 border-0">
                  <Card.Header className="p-0 border-0 bg-transparent">
                    <Accordion.Toggle
                      className="w-100 d-flex align-items-center text-left text-decoration-none rounded-0"
                      as={Button}
                      variant="link"
                      eventKey="3"
                    >
                      <span className="mr-auto d-block">Category</span>
                      <img src={BottomArrow} alt="dropdown arrow" />
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body className="p-0">
                      <div className="filter_list">
                        {(categoriesList || []).map((i2: any, index: any) => {
                          return (
                            <>
                              {showLimit > index && (
                                <Form.Group controlId={i2.id} key={i2.id}>
                                  <Form.Check
                                    onClick={(e: any) => {
                                      this.FilterValueSelect(
                                        e,
                                        i2?.id,
                                        "Category"
                                      );
                                    }}
                                    type="checkbox"
                                    label={i2.category}
                                  />
                                </Form.Group>
                              )}
                            </>
                          );
                        })}
                        {showLimit < categoriesList?.length && (
                          <Button
                            onClick={this.clickShowMore}
                            className="show_more_filter_list text-decoration-none"
                          >
                            Show More
                          </Button>
                        )}
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card> */}
            </div>
          </Accordion>
        </div>
      </div>
    </>
  );
};
export default ActionCalendarLeftFilter;
