import * as React from "react";

import { Container } from "react-bootstrap";
import BrowseListTopBanner from "../../components/BrowseListTopBanner";
import BrowseListTabMenu from "../../components/BrowseListTabMenu";

import "./css/BrowseListing.css";

import {
  getCollectionItemsCall,
  getCollectionByIdCall,
  getcurrenciesCall,
  followbidCall,
  getTimeZones,
} from "./../../library/Requests";

import { UPDATE_FILTERS } from "../../library/Requests/eventConstants";
import { triggerEventForProfileUpdate } from "../../Scenes/common";
import { LOGINID } from "./../../Scenes/common";
import { bidregistrationCall } from "./../../library/Requests/PlaceBid";
import RegisterToBid from "./../../components/RegisterToBid";
import CustomModal from "./../../components/CustomModel";
import moment from "moment";

import { connect } from "react-redux";
import * as actionCreators from "./../../actions/index.js";
import { triggerMixpanelEvent } from "./../../Scenes/common";
import {
  AFTER_REGISTERBID_TRIGGER_PAGE,
  AFTER_LOGIN_TRIGGER_PAGE,
} from "./../../library/Requests/eventConstants";
import * as signalR from "@microsoft/signalr";
import { SERVER_IP } from "./../../library/constants.js";
import BidRegistrationModal from "../../components/BidRegistrationPopUp/BidRegisrationModal";

class BrowseListing extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSize: "9",
      totalCount: 0,
      isLoading: false,
      currenciesList: [],
      timezonesList: [],
      collectionData: {},
      collectionItems: [],
      sortColumn: "Sno", //EstimatedPrice
      sortDirection: "asc",
      searchText: "",
      jsonData: {},
      collectionItemsFull: [],
      collectionId: 0,
      registerToBidPopUp: false,
      registerBidSuccess: false,
      showLiveActionBtn: false,
      userRejectedAddressPopup: false,
      biddingShowText: "",
      biddingShowNo: "",
      isPastAuction: false,
      BidStartEndType: "",
      showBiddingTie: "",
      errorModelPopup: false,
      warningModelPopup: false,
      errorTextData: "",
      selectedStatus: "",
      errorMsg: false,
      locationSearch: "",
      isClosedCollection: false,
      queryState: "",
      registerButon: false,
      showRegistrationModal: false,
    };
  }
  componentDidMount() {
    localStorage.removeItem("newInterValues");
    localStorage.removeItem("collection_details_newInterValues");
    const id = this.props?.match?.params?.id;
    const queryParams = new URLSearchParams(this.props.location.search);
    this.setState({ queryState: queryParams.get("tab") });
    this.getCollectionData(id, "onLoad");
    window.scrollTo(0, 0);
    this.getcurrenciesDataCall();
    this.gettimezonesDataCall();
    this.getDataonLoad();
    triggerMixpanelEvent("collection_details_page", {});
    const self = this;
    window.document.addEventListener(
      AFTER_REGISTERBID_TRIGGER_PAGE,
      function (event) {
        self.registertoBid(event);
      },
      false
    );
    window.document.addEventListener(
      AFTER_LOGIN_TRIGGER_PAGE,
      function (event) {
        self.followBid(
          self.props?.afterLoginTriggerPage?.lotId ||
            self.props.lotIdNumberValue?.lotId
        );
      },
      false
    );
    const _this = this;
    this.triggerMicrosoftSignal(id);
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${SERVER_IP}/hubs/BidHub`)
      .withAutomaticReconnect()
      .build();
    connection.on("BidInsertSignal", (response) => {});
    connection
      .start()
      .then((result) => {
        connection.on("BidInsertSignal", (response) => {
          _this.getLatestDataBySignal();
          console.log("signal responseresponse", response);
        });
      })
      .catch((err) => console.error(err.toString()));
  }
  triggerMicrosoftSignal = (collNo: any) => {
    const self = this;
  };

  getRegisterButton = () => {
    this.setState({ registerButon: true });
  };

  getCollectionDataCall = async () => {
    const id = this.props?.match?.params?.id;

    this.getCollectionData(id, "");
  };
  handleLoginBtn = () => {
    this.props.LopinPopUpPage(true);
  };
  getcurrenciesDataCall = async () => {
    const resp = await getcurrenciesCall();
    this.setState({ currenciesList: resp?.data });
  };
  gettimezonesDataCall = async () => {
    const timezoneResp = await getTimeZones();
    this.setState({ timezonesList: timezoneResp?.data });
  };
  getDataonLoad = async () => {
    const { sortColumn, sortDirection } = this.state;
    const id = this.props?.match?.params?.id;
    if (id) {
      const body = {
        page: this.state.currentPage,
        pageSize: this.state.pageSize,
        getOnlyTotalCount: false,
        collectionId: id,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
        customerId: LOGINID,
      };
      this.setState({
        jsonData: body,
        collectionId: id,
        locationSearch: this.props?.location?.search,
      });

      this.getCollectionItems(body, "onLoad");
    }
  };
  getCollectionItems = async (body: any, type: any) => {
    if (type !== "fromSignal") {
      this.setState({ isLoading: true });
    }

    if (type != "viewmore" && type !== "fromSignal") {
      this.setState({ collectionItems: [] });
    }
    // body.status=1
    let resp = await getCollectionItemsCall(body);
    if (resp?.totalCount == 0 && type === "onLoad") {
      this.setState({ errorMsg: true });
      document.title = "FAHH";
    }
    if (type == "viewmore") {
      this.setState({
        isLoading: false,
        collectionItems: [...this.state.collectionItems, ...resp?.data],
        collectionItemsFull: resp,
      });
    } else {
      this.setState({
        isLoading: false,
        collectionItems: resp?.data,
        collectionItemsFull: resp,
      });
    }
    // if(type=="onLoad"){
    this.setState({ totalCount: resp?.totalCount });
    // }
    if (type == "onLoad") {
      triggerEventForProfileUpdate(UPDATE_FILTERS);
    }
  };
  getCollectionData = async (id: any, type: any) => {
    const userI = this.props?.user?.id;
    if (type == "onLoad") {
      // this.setState({ isLoading: true });
    }
    let resp = await getCollectionByIdCall(id, userI, "collectiondetails");
    // this.getAllOutBids(id);
    this.setState({ collectionData: resp?.data });
    // this.updateShowLiveActionBtn();
    this.checkDates(resp?.data);
    document.title = this.state?.collectionData?.title;
  };

  checkDates = (collectionData: any) => {
    const gmtDateTime = moment.utc(
      collectionData?.endOnUtc,
      "YYYY-MM-DD HH:mm:ss a"
    );
    const startLocalFullDate = gmtDateTime
      .local()
      .format("YYYY-MM-DD HH:mm:ss a");
    const todayDate = moment().local().format("YYYY-MM-DD HH:mm:ss a");
    todayDate > startLocalFullDate
      ? this.setState({ isPastAuction: true })
      : this.setState({ isPastAuction: false });
  };
  getCollectionItemsNew = (body: any) => {
    const { sortColumn, sortDirection, searchText } = this.state;
    body.sortColumn = sortColumn;
    body.sortDirection = sortDirection;
    body.searchText = searchText;
    this.setState({ jsonData: body });
    this.getCollectionItems(body, "");
  };
  searchClick = (search: any) => {
    const body = this.state.jsonData;
    body.searchText = search;
    this.setState({ jsonData: body, searchText: search });
    this.getCollectionItems(body, "");
  };
  sortColumnChange = (e: any) => {
    const value = e.target.value;
    const sortCol = value.split("_")[0];
    const sortDir = value.split("_")[1];
    const body = this.state.jsonData;
    body.sortColumn = sortCol;
    body.sortDirection = sortDir;
    this.setState({
      sortColumn: sortCol,
      sortDirection: sortDir,
      jsonData: body,
    });
    this.getCollectionItems(body, "");
  };
  sortStatusChange = (e: any) => {
    const value = e.target.value;
    const sortCol = value;
    const body = this.state.jsonData;
    body.selectedStatus = sortCol;
    this.setState({
      sortColumn: sortCol,
      selectedStatus: sortCol,
      jsonData: body,
    });
    this.getCollectionItems(body, "");
  };

  getUpdatedDataCall = (body: any) => {
    const { sortColumn, collectionId, sortDirection, searchText, pageSize } =
      this.state;
    body.collectionId = collectionId;
    body.sortColumn = sortColumn;
    body.sortDirection = sortDirection;
    body.searchText = searchText;
    body.pageSize = pageSize;
    body.page = 1;

    this.setState({ jsonData: body });
    this.getCollectionItems(body, "");
  };
  followBid = async (id: any) => {
    this.props.afterLoginTriggerPageCall({});
    const { collectionItems } = this.state;
    let userInfo = localStorage.getItem("customerInfoDetails");
    if (userInfo != undefined && userInfo != null && userInfo != "") {
      const userIn = JSON.parse(userInfo);
      if (userIn?.id) {
        const index = collectionItems.findIndex((item: any) => item?.id === id);
        if (index > -1) {
          collectionItems[index].ifollowed = true;
        }
        const body = {
          follow: {
            itemId: id,
            customerId: userIn?.id,
            isFollow: true,
          },
        };
        this.setState({ collectionItems: collectionItems });
        const resp = await followbidCall(body);
        this.props.followeddata(true);
      }
    } else {
      const jsonD = {
        pageName: "followClick",
        lotId: id,
      };
      this.props.afterLoginTriggerPageCall(jsonD);
      this.props.LopinPopUpPage(true);
    }
  };
  unFollowBid = async (id: any) => {
    const { collectionItems } = this.state;
    let userInfo = localStorage.getItem("customerInfoDetails");
    if (userInfo != undefined && userInfo != null && userInfo != "") {
      const userIn = JSON.parse(userInfo);
      if (userIn?.id) {
        const index = collectionItems.findIndex((item: any) => item?.id === id);
        if (index > -1) {
          collectionItems[index].ifollowed = false;
        }
        const body = {
          follow: {
            itemId: id,
            customerId: userIn?.id,
            isFollow: false,
          },
        };
        this.setState({ allData: collectionItems });
        const resp = await followbidCall(body);
        this.props.followeddata(false);
      }
    }
  };
  registertoBid = async (e: any) => {
    if (this.props?.user?.id) {
      this.props.afterLoginTriggerPageCall({});
      const { collectionData } = this.state;
      if (collectionData?.auctionType == 1) {
        this.registoBidValue("Online");
      } else {
        this.registoBidValue("Online");
        // this.setState({registerToBidPopUp: true});
      }
    } else {
      const jsonD = {
        pageName: "registerBid",
        lotId: 0,
      };
      // this.props.afterLoginTriggerPageCall(jsonD);
      this.props.LopinPopUpPage(true);
    }
  };
  registoBidValue = async (value: any) => {
    if (value != "" && this.props.user?.id) {
      let userInfo = localStorage.getItem("customerInfoDetails");
      if (userInfo != undefined && userInfo != null && userInfo != "") {
        const userIn = JSON.parse(userInfo);
        if (!userIn?.billingAddressId) {
          //  !userIn?.identityProof ||  !userIn?.addressProof ||
          this.setState({ warningModelPopup: true });
        } else if (!userIn?.active && userIn?.isVerified) {
          this.setState({ userRejectedAddressPopup: true });
        } else {
          this.setState({ registerToBidPopUp: false });
          const body = {
            bidRegistration: {
              collectionId: this.state?.collectionData?.id,
              customerId: this.props.user?.id,
              type: value,
              registred: true,
            },
          };
          const resp = await bidregistrationCall(body);
          if (resp?.success) {
            triggerMixpanelEvent("bid_registration_success", {
              lotId: this.state.lotData?.id,
            });
            this.setState({ registerBidSuccess: true });
            this.getCollectionData(this.state?.collectionData?.id, "");
          } else if (resp?.errors?.length > 0) {
            this.setState({
              errorModelPopup: true,
              errorTextData: resp?.errors[0],
            });
            triggerMixpanelEvent("bid_registration_error", {
              lotId: this.state.lotData?.id,
              error_message: resp?.errors[0],
            });
          } else if (resp?.message) {
            triggerMixpanelEvent("bid_registration_error", {
              lotId: this.state.lotData?.id,
              error_message: resp?.message,
            });
            this.setState({
              errorModelPopup: true,
              errorTextData: resp?.message,
            });
          }
        }
      }
    }
  };

  onHovering = (id: any, value: boolean) => {
    const { collectionItems } = this.state;
    const index = collectionItems.findIndex((item: any) => item?.id === id);
    if (index > -1) {
      collectionItems[index].hovering = value;
    }
    this.setState({ collectionItems: collectionItems });
  };

  gotoAuctionCalenderPage = () => {
    if (this.state.queryState === "Upcoming") {
      this.props.history.push(
        "/auction-calendar?selectedTab=calender&subTab=upcomming"
      );
    } else if (this.state.queryState === "Active") {
      this.props.history.push(
        "/auction-calendar?selectedTab=calender&subTab=active"
      );
    } else {
      this.props.history.push(
        "/auction-calendar?selectedTab=calender&subTab=all"
      );
    }
  };
  gotoAuctionResultsPage = () => {
    this.props.history.push("/auction-calendar?selectedTab=results");
  };
  liveAuctionPage = () => {
    this.props.history.push(
      `/live-auction-landing/${this.state?.collectionData?.id}`
    );
  };
  clickViewMore = () => {
    const { currentPage, isLoading, totalCount, collectionItems } = this.state;
    if (!isLoading && totalCount > collectionItems.length) {
      this.setState({ currentPage: currentPage + 1 });
      const body = this.state.jsonData;
      body.page = currentPage + 1;
      this.getCollectionItems(body, "viewmore");
    }
  };
  getLatestDataBySignal = () => {
    const body = this.state.jsonData;
    const id = this.props?.match?.params?.id;

    this.getCollectionData(id, "");
    this.getCollectionItems(body, "fromSignal");
  };
  gotoUploadPhotoPage = () => {
    let userInfo = localStorage.getItem("customerInfoDetails");
    if (userInfo != undefined && userInfo != null && userInfo != "") {
      const userIn = JSON.parse(userInfo);
      if (!userIn?.billingAddressId) {
        window.location.href = `/sign-up/step2?returnUrl=/collectionDetails/${this.state?.collectionData?.id}`;
      } else {
        window.location.href = `/sign-up/step3?returnUrl=/collectionDetails/${this.state?.collectionData?.id}`;
      }
    }
  };
  handleCloseModal = () => {
    this.setState({ showRegistrationModal: false });
  };
  handleOpenModal = () => {
    this.setState({ showRegistrationModal: true });
  };

  render() {
    const {
      currenciesList,
      timezonesList,
      collectionData,
      collectionItems,
      collectionItemsFull,
      registerToBidPopUp,
      registerBidSuccess,
      showLiveActionBtn,
      biddingShowText,
      biddingShowNo,
      isPastAuction,
      errorModelPopup,
      errorTextData,
      errorMsg,
      warningModelPopup,
      locationSearch,
      isClosedCollection,
    } = this.state;

    console.log(this.state.queryState, "saiiii");
    return (
      <>
        {errorMsg ? (
          <p className="noResults" id="errorPage">
            No Results are Available in Collection Details !{" "}
          </p>
        ) : (
          <>
            {this.state.showRegistrationModal && (
              <BidRegistrationModal
                showRegistrationModal={this.state.showRegistrationModal}
                setShowRegistrationModal={this.handleCloseModal}
                collectionId={collectionData}
                toPage={"top-banner"}
                setRegisterButon={this.getRegisterButton}
              />
            )}
            <div className="brows_list_content_page">
              <BrowseListTopBanner
                currenciesList={currenciesList}
                handleLoginBtn={this.handleLoginBtn}
                timezonesList={timezonesList}
                collectionData={collectionData}
                registertoBid={this.registertoBid}
                gotoAuctionCalenderPage={this.gotoAuctionCalenderPage}
                gotoAuctionResultsPage={this.gotoAuctionResultsPage}
                liveAuctionPage={this.liveAuctionPage}
                showLiveActionBtn={showLiveActionBtn}
                biddingShowText={biddingShowText}
                biddingShowNo={biddingShowNo}
                isPastAuction={isPastAuction}
                getRegisterButton={this.getRegisterButton}
                registerButon={this.state.registerButon}
              />
              <Container>
                <BrowseListTabMenu
                  onHovering={this.onHovering}
                  currenciesList={currenciesList}
                  timezonesList={timezonesList}
                  collectionData={collectionData}
                  collectionItems={collectionItems}
                  collectionItemsFull={collectionItemsFull}
                  searchClick={this.searchClick}
                  sortColumnChange={this.sortColumnChange}
                  sortStatusChange={this.sortStatusChange}
                  getUpdatedDataCall={this.getUpdatedDataCall}
                  followBid={this.followBid}
                  unFollowBid={this.unFollowBid}
                  clickViewMore={this.clickViewMore}
                  totalCount={this.state.totalCount}
                  isLoading={this.state.isLoading}
                  isPastAuction={isPastAuction}
                  biddingShowNo={biddingShowNo}
                  biddingShowText={biddingShowText}
                  locationSearch={locationSearch}
                  isClosedCollection={isClosedCollection}
                  getCollectionDataCall={this.getCollectionDataCall}
                  registertoBid={this.registertoBid}
                  handleOpenModal={this.handleOpenModal}
                  registerButon={this.state.registerButon}
                />
              </Container>
              <RegisterToBid
                show={registerToBidPopUp}
                titleText=""
                deleteText="OK"
                cancelText=""
                onCancel={() => {}}
                onRegisterBid={this.registoBidValue}
              />
              <CustomModal
                show={registerBidSuccess}
                titleText={`You have been successfully registered for the ${
                  collectionData?.auctionType == 1 ? "Online Only" : "Live"
                } Auction`}
                deleteText="OK"
                cancelText=""
                onCancel={() => {}}
                onDelete={() => {
                  this.setState({ registerBidSuccess: false });
                }}
              />
              <CustomModal
                show={errorModelPopup}
                titleText={errorTextData}
                deleteText="OK"
                cancelText=""
                longText={true}
                onCancel={() => {}}
                onDelete={() => {
                  this.setState({ errorModelPopup: false });
                }}
              />
              <CustomModal
                show={warningModelPopup}
                titleText="Submit your identification "
                subTitleText="In order to place a bid on a lot in this auction, please complete FAHH’s verification process."
                deleteText="CONTINUE"
                cancelText=""
                onCancel={() => {}}
                onDelete={() => {
                  this.setState({ warningModelPopup: false });
                  this.gotoUploadPhotoPage();
                }}
              />{" "}
              <CustomModal
                show={this.state.userRejectedAddressPopup}
                // titleText="Submit your identification "
                subTitleText="Your ID proof document has been rejected, It appears that there were issues with the provided ID. To proceed, please resubmit a clear and valid ID image."
                deleteText="REUPLOAD"
                cancelText="OK"
                showCancelBtn={true}
                onCancel={() => {
                  this.setState({ userRejectedAddressPopup: false });
                }}
                onDelete={() => {
                  this.setState({ userRejectedAddressPopup: false });
                  this.gotoUploadPhotoPage();
                }}
              />
            </div>
          </>
        )}
      </>
    );
  }
}
// export default BrowseListing;
const mapStateToProps = (state: any) => {
  return state;
};
// export default withRouter(LoginPopup);
export default connect(mapStateToProps, actionCreators)(BrowseListing);
