import React from "react";
import "./css/style.css";
class BuyingGuides extends React.Component {
  componentDidMount() {
    document.title = "FAHH-buyingGuides";
  }
  render() {
    return (
      <div className="buyingGuidesContainer">
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </p>
        <p>this is BuyingGuides page</p>
      </div>
    );
  }
}
export default BuyingGuides;
