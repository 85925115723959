import React from "react";
import { Link } from "react-router-dom";

import Step1 from "../../components/Step1";
import Step2 from "../../components/Step2";
import Step3 from "../../components/Step3";
import Modal from "react-bootstrap/Modal";
import moment from "moment";

import { Container, Button, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import * as actionCreators from "./../../actions/index.js";
import { GOOGLE_MAP_URL } from "./../../library/constants";

import {
  createNewUser,
  createUserAddress,
  getbusinessuserbyidCall,
  customerkycCall,
  newGetcollectionguidCall,
  getUpdatedLoginDetails,
} from "./../../library/Requests";
import { editcustomeraddressCall } from "./../../library/Requests/MyAccount";
import FinishSignupFromModal from "../../components/FinishSignupFromModal";
import { useHistory, withRouter } from "react-router-dom";
import uplaodImageNew from "../../services";
import Loader from "./../../components/Common/Loader";
import "./css/SignUp.css";
import { LOGINID } from "../../Scenes/common";
import {
  triggerMixpanelEvent,
  triggerUserFprofileInMixpanel,
} from "./../../Scenes/common";

class SignUp extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      currentStep: 1,
      show: false,
      close: false,
      isEdit: false,
      charLength: false,
      specialChar: false,
      numberLength: false,
      privacyCheckBox: false,
      termsCheckBox: false,
      isLoading: false,
      userAccountData: {
        title: "Mr.",
        email: "",
        firstName: "",
        lastName: "",
        // confirmEmail: "",
        password: "",
        confirmPassword: "",
        isMarketingPreferences: "",
      },
      errorsFields: {
        // title: false,
        email: false,
        firstName: false,
        lastName: false,
        // confirmEmail: false,
        isMarketingPreferences: false,
        password: false,
        confirmPassword: false,
      },
      errorsData: {
        // title: "Please enter the title",
        email: "Please enter valid email",
        firstName: "Please enter first name",
        lastName: "Please enter last name",
        isMarketingPreferences: "Please Check Marketing Preferences",
        confirmEmail: "Please enter valid email",
        password: "Please enter password",
        confirmPassword: "Please enter password",
      },
      userAddressData: {
        dateOfBirth: "",
        phoneCode: "",
        phoneNumber: "",
        address1: "",
        address2: "",
        zipPostalCode: "",
        city: "",
        state: "",
        county: "",
      },
      errorsAddressFields: {
        phoneNumber: false,
        address1: false,
        // address2: false,
        zipPostalCode: false,
        dateOfBirth: false,
        city: false,
        state: false,
        county: false,
      },
      errorsAddressData: {
        phoneNumber: "Please enter phoneNumber",
        address1: "Please enter address1",
        address2: "Please enter address2",
        zipPostalCode: "Please enter zipPostalCode",
        city: "Please enter city",
        state: "Please enter state",
        county: "Please enter country",
        dateOfBirth: "Please select Date of Birth",
      },
      customerRole: "Registred",
      password: "",
      photoIDURL: "",
      addressProofURL: "",
      customerData: {},
      addressId: null,
      emailError: "",
      isMarketingPreferencesSelected: false,
      passwordType: "password",
      addressImageError: "",
      idImageError: "",
    };
  }
  componentDidMount() {
    triggerMixpanelEvent("create_user_page");
    const id = this.props?.match?.params?.id;
    if (id == "step2") {
      this.setState({ currentStep: 2, isEdit: true });
      this.getuserDetails();
      setTimeout(() => {
        this.onLoadGoogleMap();
      }, 500);
    } else if (id == "step3") {
      this.setState({ currentStep: 3, isEdit: true });
      this.getuserDetails();
    } else if (LOGINID) {
      this.props.history.push(`/`);
    }
    this.newGetcollectionguid();
  }

  handleScriptLoad = () => {
    const options = {
      // componentRestrictions: { country: "us" },
    };
    const autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("signup_addres_line1") as HTMLInputElement,
      options
    );
    autocomplete.setFields(["address_components", "formatted_address"]);
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      this.handlePlaceSelect(place);
    });
  };

  handlePlaceSelect = (addressObject: any) => {
    var address1 = "";
    let postcode = "";
    let city = "";
    let state = "";
    let address2 = "";
    let country = "";
    let short_name_country = "";
    let short_name_state = "";

    console.log(
      "addressObject?.address_components",
      addressObject?.address_components
    );
    for (const component of addressObject?.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number": {
          address1 = `${component.long_name}`;
          break;
        }
        case "route": {
          address2 = component.short_name;
          break;
        }
        case "postal_code": {
          postcode = `${component.long_name}${postcode}`;
          break;
        }
        case "locality":
          city = component.long_name;
          break;
        case "country":
          country = component.long_name;
          short_name_country = component.short_name;
          break;
        case "administrative_area_level_1": {
          state = component.long_name;
          short_name_state = component.short_name;
          break;
        }
      }
    }
    const AddressLine1 = address1 + " " + address2;

    const stateD = this.state.userAddressData;
    stateD["address1"] = AddressLine1;
    stateD["address2"] = "";
    stateD["city"] = city;
    stateD["state"] = state;
    stateD["county"] = country;
    stateD["zipPostalCode"] = postcode;
    stateD["StateCode"] = short_name_state;
    stateD["CountryCode"] = short_name_country;

    let errorsList = this.state.errorsAddressFields;
    errorsList["address1"] = false;
    errorsList["city"] = false;
    errorsList["state"] = false;
    errorsList["county"] = false;
    errorsList["zipPostalCode"] = false;

    this.setState({ errorsAddressFields: errorsList });

    this.setState({ userAddressData: stateD });
  };

  getuserDetails = async () => {
    if (LOGINID) {
      const resp = await getbusinessuserbyidCall(LOGINID);
      const respt = resp?.customer;
      this.setState({ customerData: resp?.customer });
      console.log(respt);
      const { userAccountData } = this.state;
      userAccountData.title = respt.title;
      userAccountData.email = respt.email;
      userAccountData.firstName = respt.firstName;
      userAccountData.lastName = respt.lastName;
      userAccountData.isMarketingPreferences = respt.isMarketingPreferences;
      userAccountData.lastName = respt.lastName;
      this.setState({ userAccountData });
    } else {
      this.props.LopinPopUpPage(true);

      // this.setState({ currentStep: 1, isEdit: false });
    }
  };
  newGetcollectionguid = async () => {
    const resp = await newGetcollectionguidCall();
    localStorage.removeItem("collectionguidforKycDocuments");
    localStorage.setItem("collectionguidforKycDocuments", resp?.collectionGuid);
  };
  handleChangeStep2 = (event: any) => {
    const { name, value } = event.target;
    const state = this.state.userAddressData;
    if (name == "phoneNumber") {
      const re = /^[0-9-\b]+$/;
      if (value === "" || re.test(value)) {
        state[name] = value?.replace(/[\D]/g, "");
      }
    } else if (name === "zipPostalCode") {
      const re = /^[0-9\b]+$/;
      if (value === "" || re.test(value)) {
        state[name] = value;
      }
    } else {
      state[name] = value;
    }
    let errorsList = this.state.errorsAddressFields;
    errorsList[name] = false;
    this.setState({ errorsAddressFields: errorsList });

    // errorsData
    this.setState({ userAddressData: state });
  };
  handleChangeDate = (date: any) => {
    console.log("date", date);
    const state = this.state.userAddressData as any;
    let errorsList = this.state.errorsAddressFields;
    const dateN = moment(date).format("MM-DD-YYYY");

    state.dateOfBirth = dateN;
    errorsList.dateOfBirth = false;
    this.setState({ errorsAddressFields: errorsList, userAddressData: state });
  };

  handleChange = (event: any) => {
    const { name, value } = event.target;
    const state = this.state.userAccountData;
    let errorsData1 = this.state.errorsData;
    let errorsList = this.state.errorsFields;
    if (name == "firstName" || name === "lastName") {
      let resFlag = /^[a-zA-Z]+$/.test(value);
      if (resFlag || value === "") {
        state[name] = value;
      }
      // if (value === "" || re.test(value)) {
      //   state[name] = value;
      // }
    } else {
      state[name] = value;
    }
    errorsList[name] = false;
    if (name == "email" || name == "confirmEmail") {
      this.setState({ emailError: "" });
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailRegex.test(String(value).toLowerCase())) {
        errorsList[name] = false;
        // this.setState({isErrorEmail:false});
        // if (state.email != "" && state.confirmEmail != "") {
        //   if (state.email === state.confirmEmail) {
        //     errorsData1.confirmEmail = "Please enter valid email";
        //   } else {
        //     errorsData1.confirmEmail = "email and Confirm Email do not match";
        //     errorsList.confirmEmail = true;
        //   }
        // }
      } else {
        errorsList[name] = true;
      }
    }
    // errorsData
    this.setState({
      userAccountData: state,
      errorsFields: errorsList,
      errorsData: errorsData1,
    });
    if (name == "password" || name == "confirmPassword") {
      const self = this;
      setTimeout(() => {
        self.passwordValidate(event);
      }, 100);
    }
  };

  handlePasswordChange = (event: any) => {
    const { name, value } = event.target;
    this.setState({ ...this.state, password: value });
    // this.passwordValidate(event);
  };
  handleValuesSelect = (value: any) => {
    const state = this.state.userAccountData;
    state.isMarketingPreferences = value;
    let errorsList = this.state.errorsFields;
    errorsList.isMarketingPreferences = false;
    this.setState({
      userAccountData: state,
      errorsFields: errorsList,
      isMarketingPreferencesSelected: true,
    });
  };
  passwordValidate = (event: any) => {
    const { password, confirmPassword } = this.state.userAccountData;
    const state = this.state.userAccountData;
    let errorsData1 = this.state.errorsData;
    let errorsList = this.state.errorsFields;

    if (password != "") {
      let charLength1 = password.length >= 10 ? true : false;
      const regex = /\d/;
      const regex1 = /[!@#$%^&*_+]/g;
      this.setState({
        specialChar: regex1.test(password),
        numberLength: regex.test(password),
        charLength: charLength1,
      });
    }
    if (password != "" && confirmPassword != "") {
      if (state.password === state.confirmPassword) {
        errorsData1.confirmPassword = "Please enter password";
        errorsList.confirmPassword = false;
      } else {
        errorsData1.confirmPassword =
          "Password and Confirm Password do not match";
        errorsList.confirmPassword = true;
      }
    }

    this.setState({ errorsFields: errorsList, errorsData: errorsData1 });
  };
  onLoadGoogleMap = () => {
    const _this = this;
    const script = document.createElement("script");
    script.src = GOOGLE_MAP_URL;
    script.id = "googleMaps";
    document.body.appendChild(script);
    script.onload = () => {
      _this?.handleScriptLoad();
    };
  };
  _next = async () => {
    window.scrollTo(0, 0);
    let currentStep = this.state.currentStep;
    currentStep = currentStep >= 2 ? 3 : currentStep + 1;
    await this.setState({
      currentStep: currentStep,
    });
    setTimeout(() => {
      this.onLoadGoogleMap();
    }, 500);
  };

  _prev = () => {
    window.scrollTo(0, 0);
    let currentStep = this.state.currentStep;
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep,
    });
    if (currentStep == 2) {
      setTimeout(() => {
        this.onLoadGoogleMap();
      }, 500);
    }
  };

  getJsonBody = () => {
    const { userAccountData, password, customerRole } = this.state;

    const dataJson = {
      customer: {
        title: userAccountData.title,
        email: userAccountData.email,
        firstName: userAccountData.firstName,
        lastName: userAccountData.lastName,
        dateOfBirth: userAccountData.dateOfBirth,
        active: false,
        isVerified: false,
      },
      password: userAccountData?.password,
      customerRole: "Registred",
    };

    return dataJson;
  };

  getJsonAddress = () => {
    const { userAddressData, customerData, userAccountData } = this.state;

    const dataJson = {
      costomerId: customerData?.id,
      dateOfBirth: userAddressData.dateOfBirth,
      address: {
        title: userAccountData?.title,
        firstName: userAccountData?.firstName,
        lastName: userAccountData?.lastName,
        email: userAccountData?.email,
        phoneNumber: userAddressData.phoneNumber,
        phoneCode: userAddressData.phoneCode,
        address1: userAddressData.address1,
        address2: userAddressData.address2,
        zipPostalCode: userAddressData.zipPostalCode,
        city: userAddressData.city,
        state: userAddressData.state,
        county: userAddressData.county,
        isPrimary: true,
        StateCode: userAddressData.StateCode,
        CountryCode: userAddressData.CountryCode,
      },
      customer: {
        dateOfBirth: userAddressData.dateOfBirth,
      },
    } as any;

    return dataJson;
  };

  onCreateUser = async () => {
    const { termsCheckBox, privacyCheckBox, userAccountData } = this.state;
    if (
      this.checkValidForm() &&
      termsCheckBox &&
      privacyCheckBox &&
      userAccountData?.firstName &&
      userAccountData?.lastName
    ) {
      const body = this.getJsonBody();
      this.setState({ isLoading: true });
      const resp = await createNewUser(body);
      this.setState({ isLoading: false });
      if (resp.success) {
        console.log("#Create USER SUCCESS");
        localStorage.removeItem("customerInfoDetails");
        this.setState({ customerData: resp?.customer });
        localStorage.removeItem("customerInfoDetails");
        localStorage.removeItem("cat");
        localStorage.removeItem("crt");
        localStorage.setItem(
          "customerInfoDetails",
          JSON.stringify(resp?.customer)
        );
        localStorage.setItem("cat", resp?.accessToken);
        localStorage.setItem("crt", resp?.refreshToken);
        triggerUserFprofileInMixpanel(resp?.customer);
        triggerMixpanelEvent("create_user_success");

        // window.location.href = "/sign-up/step2";
        this._next();
      } else if (resp?.errors?.length > 0) {
        this.setState({ emailError: resp?.errors[0] });
        triggerMixpanelEvent("create_user_error", {
          error_message: resp?.errors[0],
        });
      }
    }
  };

  onAddAddress = async () => {
    const { customerData, addressId } = this.state;
    console.log("addressId", addressId);
    if (LOGINID || customerData?.id) {
      if (this.checkAddressValidForm()) {
        const body = this.getJsonAddress();
        this.setState({ isLoading: true });
        if (!addressId) {
          const resp = await createUserAddress(body);

          let userInfo = localStorage.getItem("customerInfoDetails");
          if (userInfo != undefined && userInfo != null && userInfo != "") {
            const userIn = JSON.parse(userInfo);
            userIn.billingAddressId = resp?.addressId;
            localStorage.removeItem("customerInfoDetails");
            localStorage.setItem("customerInfoDetails", JSON.stringify(userIn));
          }
          this.setState({ isLoading: false });
          if (resp.success) {
            this._next();
            this.setState({ addressId: resp?.addressId });
          }
        } else {
          body.address.id = addressId;
          const resp = await editcustomeraddressCall(body);
          this.setState({ isLoading: false });
          if (resp.success) {
            this._next();
          }
        }
      }
    } else {
      this.props.LopinPopUpPage(true);
    }
  };

  checkAddressValidForm = () => {
    let returnFlag = true;
    let errorsList = this.state.errorsAddressFields;
    const state = this.state.userAddressData;
    for (var key in errorsList) {
      const Pvalue = state[key];
      if (
        Pvalue == undefined ||
        Pvalue == "" ||
        Pvalue == null ||
        Pvalue?.trim() == ""
      ) {
        errorsList[key] = true;
        returnFlag = false;
      }
      if (key === "phoneNumber" && state?.phoneNumber?.length < 10) {
        errorsList[key] = true;
        returnFlag = false;
      }
      if (key === "zipPostalCode" && state?.zipPostalCode?.length < 5) {
        errorsList[key] = true;
        returnFlag = false;
      }
    }
    this.setState({ errorsAddressFields: errorsList });
    return returnFlag;
  };

  checkValidForm = () => {
    let returnFlag = true;
    let errorsList = this.state.errorsFields;
    const { charLength, numberLength, isMarketingPreferencesSelected } =
      this.state;

    const state = this.state.userAccountData;
    for (var key in errorsList) {
      if (!errorsList[key]) {
        const Pvalue = state[key];
        if (key == "isMarketingPreferences") {
          if (!isMarketingPreferencesSelected) {
            errorsList[key] = true;
            returnFlag = false;
            this.setState({ show: true });
          }
        } else if (Pvalue?.trim() == "") {
          errorsList[key] = true;
          returnFlag = false;
          this.setState({ show: true });
        }
      }
    }
    this.setState({ errorsFields: errorsList });
    if (!charLength || !numberLength) {
      returnFlag = false;
    }
    if (this.state?.errorsFields?.confirmEmail) {
      returnFlag = false;
    }
    if (this.state?.errorsFields?.confirmPassword) {
      returnFlag = false;
    }
    return returnFlag;
  };
  onChangeInputBox = (name: any, e: any) => {
    const { checked } = e.target;
    if (name === "privacy") {
      this.setState({ privacyCheckBox: checked });
    } else {
      this.setState({ termsCheckBox: checked });
    }
  };
  /*
   * the functions for our button
   */
  previousButton() {
    let currentStep = this.state.currentStep;
    if (currentStep !== 1 && currentStep !== 2) {
      return (
        // <button
        //   className="btn btn-secondary"
        //   type="button"
        //   onClick={this._prev}
        // >
        //   Previous
        // </button>
        <Button
          variant="outline-primary"
          className="mr-auto"
          type="button"
          onClick={this._prev}
        >
          BACK
        </Button>
      );
    }
    return null;
  }
  completeLater = () => {
    // this.props.history.push(`/`);
    window.location.href = "/";
  };
  handleChangeAddress = async (e: any) => {
    if (e.target.files.length) {
      const filesName = e.target.files[0];
      this.setState({ isLoading: true, addressImageError: "" });
      const respApp = await uplaodImageNew(filesName, "KYCDocuments");
      console.log("respApp", respApp);
      localStorage.setItem("addressProofURL", respApp?.imageUrl);
      if (respApp?.message) {
        this.setState({
          addressImageError: respApp?.message,
          isLoading: false,
        });
      } else {
        this.setState({ addressProofURL: respApp?.imageUrl, isLoading: false });
      }
    }
  };
  handleChangePhoto = async (e: any) => {
    if (e.target.files.length) {
      const filesName = e.target.files[0];
      this.setState({ isLoading: true, idImageError: "" });
      const respApp = await uplaodImageNew(filesName, "KYCDocuments");
      console.log("respApp", respApp);
      localStorage.setItem("photoIDURL", respApp?.imageUrl);
      if (respApp?.message) {
        this.setState({ idImageError: respApp?.message, isLoading: false });
      } else {
        this.setState({ photoIDURL: respApp?.imageUrl, isLoading: false });
      }
    }
  };
  handleSigninClick = async () => {
    this.props.LopinPopUpPage(true);
  };
  togglePassword = () => {
    if (this.state.passwordType === "text") {
      this.setState({ passwordType: "password" });
    } else {
      this.setState({ passwordType: "text" });
    }
  };
  finishClick = async () => {
    const { photoIDURL, addressProofURL, customerData, addressId } = this.state;
    // if (LOGINID || customerData?.id) {
    if (photoIDURL != "" || addressProofURL != "") {
      const body = {
        customerId: customerData?.id,
        addressId: addressId,
        addressIdentity: addressProofURL,
        personalIdentity: photoIDURL,
      };
      await customerkycCall(body);
      await getUpdatedLoginDetails();
    }
    // } else {
    //   this.props.LopinPopUpPage(true);
    // }
  };

  handleCompleteLater = () => {
    const photoIDURL1 = localStorage.getItem("photoIDURL");
    const addressProofURL1 = localStorage.getItem("addressProofURL");
    const returnUrlValue = new URLSearchParams(this.props?.location.search).get(
      "returnUrl"
    );
    if (returnUrlValue) {
      window.location.href = returnUrlValue;
    } else if (!photoIDURL1 || !addressProofURL1) {
      window.location.href = "/";
    }
  };
  nextButton(disb: any) {
    const { photoIDURL, addressProofURL } = this.state;

    let currentStep = this.state.currentStep;
    const { termsCheckBox, privacyCheckBox } = this.state;

    const returnUrlValue = new URLSearchParams(this.props?.location.search).get(
      "returnUrl"
    );
    if (currentStep < 4) {
      return (
        // <button
        //   className="btn btn-primary float-right"
        //   type="button"
        //   onClick={this._next}
        // >
        <>
          <Button
            variant="outline-primary"
            className="ml-auto complete_btn d-md-block d-none"
            type="button"
            disabled={disb}
            custom-title={this.state.currentStep}
            onClick={this.handleCompleteLater}
          >
            COMPLETE LATER
          </Button>
          <Button
            variant="outline-primary"
            className="ml-2 next_btn"
            type="button"
            // onClick={this._next}
            onClick={this.onAddAddress}
            custom-title={this.state.currentStep}
          >
            NEXT
          </Button>
          <Button
            variant="outline-primary"
            className="ml-auto next_popup_btn next_popup_btn_signup_flow d-none"
            type="button"
            // onClick={this._next}
            onClick={this.onCreateUser}
            custom-title={this.state.currentStep}
            disabled={termsCheckBox && privacyCheckBox ? false : true}
          >
            NEXT
          </Button>
          <Modal
            show={this.state.show}
            animation={true}
            onClick={() => this.setState({ show: false })}
            className="custom_modal_box custom_magage_profile_modal"
          >
            <Modal.Body className="not_fill_input_modal">
              <h4 className="d-flex align-items-center justify-content-center">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.29 3.8602L1.82002 18.0002C1.64539 18.3026 1.55299 18.6455 1.55201 18.9947C1.55103 19.3439 1.64151 19.6873 1.81445 19.9907C1.98738 20.2941 2.23675 20.547 2.53773 20.7241C2.83871 20.9012 3.18082 20.9964 3.53002 21.0002H20.47C20.8192 20.9964 21.1613 20.9012 21.4623 20.7241C21.7633 20.547 22.0127 20.2941 22.1856 19.9907C22.3585 19.6873 22.449 19.3439 22.448 18.9947C22.4471 18.6455 22.3547 18.3026 22.18 18.0002L13.71 3.8602C13.5318 3.56631 13.2807 3.32332 12.9812 3.15469C12.6817 2.98605 12.3438 2.89746 12 2.89746C11.6563 2.89746 11.3184 2.98605 11.0188 3.15469C10.7193 3.32332 10.4683 3.56631 10.29 3.8602V3.8602Z"
                    stroke="#9E1616"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M12 9V13"
                    stroke="#9E1616"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M12 17H12.01"
                    stroke="#9E1616"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
                Fields Missing
              </h4>
              <h5 className="text-center w-100 mb-3">
                To ensure successful registration please enter all the details
              </h5>
              <Col className="d-flex justify-content-between modal_btns pt-0">
                <Button
                  variant="outline-secondary save_change_modal_btn"
                  className="ml-auto"
                  // onClick={() => this.setState({ show: false })}
                  // onClick={this._next}
                >
                  OK
                </Button>
              </Col>
            </Modal.Body>
          </Modal>
          <FinishSignupFromModal
            returnUrlValue={returnUrlValue}
            finishClick={this.finishClick}
            isLoading={this.state.isLoading}
          />
        </>
        //   Next
        // </button>
      );
    }
    return null;
  }
  render() {
    const {
      errorsFields,
      errorsData,
      userAccountData,
      emailError,
      userAddressData,
      isEdit,
      termsCheckBox,
      privacyCheckBox,
      passwordType,
      isLoading,
      errorsAddressFields,
      errorsAddressData,
      addressImageError,
      idImageError,
    } = this.state;
    // redirectUrl
    const returnUrlValue = new URLSearchParams(this.props?.location.search).get(
      "returnUrl"
    );
    console.log("emailError", emailError);
    const photoIDURL2 = localStorage.getItem("photoIDURL");
    const addressProofURL2 = localStorage.getItem("addressProofURL");
    const isDisableLaterBtn = photoIDURL2 && addressProofURL2 ? true : false;
    console.log("userAccountData?.dateOfBirth", userAddressData?.dateOfBirth);
    console.log("userAccountData?.dateOfBirth", userAccountData);

    return (
      <Container>
        <div
          className="sign_up_page_content add_address_page_content"
          custom-title={this.state.currentStep}
        >
          {isLoading && <Loader />}
          <React.Fragment>
            <div className="sign_up_tabs_heading text-center">
              <h2 className="d-none d-md-block">
                {" "}
                {isEdit ? "Complete Profile" : "Sign Up"}
              </h2>
              <h4 className="d-block d-md-none">Create your Account</h4>
              <h6 className="d-block d-md-none">
                We need few details to sign you up
              </h6>
              {/* <p>Step {this.state.currentStep} </p> */}
              <div className="d-flex align-items-center justify-content-center sign_up_page_tab_number">
                <p custom-title={this.state.currentStep}>
                  <span className="position-relative d-flex align-items-center justify-content-center">
                    1
                  </span>
                  <h5>Account Details</h5>
                </p>
                <p custom-title={this.state.currentStep}>
                  <span className="position-relative d-flex align-items-center justify-content-center">
                    2
                  </span>
                  <h5>Personal Details</h5>
                </p>
                <p custom-title={this.state.currentStep}>
                  <span className="position-relative d-flex align-items-center justify-content-center">
                    3
                  </span>
                  <h5>Identity Verification</h5>
                </p>
              </div>
            </div>
            <div className="add_address_form_container mx-auto">
              {/* <form> */}
              <Step1
                currentStep={this.state.currentStep}
                handleChange={this.handleChange}
                handlePasswordChange={this.handlePasswordChange}
                // email={this.state.userAccountData.email}
                errorsFields={errorsFields}
                errorsData={errorsData}
                userAccountData={userAccountData}
                charLength={this.state.charLength}
                specialChar={this.state.specialChar}
                numberLength={this.state.numberLength}
                password={this.state.password}
                handleValuesSelect={this.handleValuesSelect}
                emailError={emailError}
                onChangeInputBox={this.onChangeInputBox}
                passwordType={passwordType}
                togglePassword={this.togglePassword}
              />
              <Step2
                currentStep={this.state.currentStep}
                handleChange={this.handleChangeStep2}
                errorsFields={errorsFields}
                errorsAddressFields={errorsAddressFields}
                errorsData={errorsData}
                userAccountData={userAccountData}
                userAddressData={this.state.userAddressData}
                errorsAddressData={errorsAddressData}
                handleScriptLoad={this.handleScriptLoad}
                handleChangeDate={this.handleChangeDate}
                // firtstName={this.state.firtstName}
              />
              <Step3
                currentStep={this.state.currentStep}
                handleChange={this.handleChange}
                password={this.state.password}
                handleChangeAddress={this.handleChangeAddress}
                handleChangePhoto={this.handleChangePhoto}
                addressImageError={addressImageError}
                idImageError={idImageError}
              />

              <Col
                sm={12}
                className="d-flex justify-content-between add_address_form_btn px-0"
              >
                {!returnUrlValue && <>{this.previousButton()}</>}
                {this.nextButton(isDisableLaterBtn)}
              </Col>

              <Col
                sm={12}
                className="d-flex justify-content-between add_address_form_btn pt-4 px-0"
                custom-title={this.state.currentStep}
              >
                <Button
                  variant="outline-primary"
                  className="mx-auto complete_btn d-md-none d-block mobile_complete_btn p-0 border-0"
                  type="button"
                  onClick={this.completeLater}
                >
                  COMPLETE LATER
                </Button>
              </Col>
              {/* </form> */}
            </div>
            {this.state.currentStep === 1 && (
              <div
                className="already_account_text text-center  d-block"
                custom-title={this.state.currentStep}
              >
                <p className="mb-0">
                  Already have an account?{" "}
                  <span
                    className="cursor-pointer"
                    onClick={this.handleSigninClick}
                  >
                    Sign in
                  </span>
                </p>
              </div>
            )}
          </React.Fragment>
        </div>
      </Container>
    );
  }
}
const mapStateToProps = (state: any) => {
  return state;
};
// export default withRouter(LoginPopup);
export default connect(mapStateToProps, actionCreators)(SignUp);

// export default SignUp;
