import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Tabs, Tab, Form } from "react-bootstrap";

import TopBannerSlider from "../../components/TopBannerSlider";
import ActionCalendarLeftFilter from "../../components/ActionCalendarLeftFilter";
import ActionCalendarTabMenu from "../../components/ActionCalendarTabMenu";
import {
  getallupcommingauctionsCall,
  getPastAuctions,
} from "./../../library/Requests";
import { UPDATE_CALENDERDATA } from "./../../library/Requests/eventConstants";
import { triggerEventForProfileUpdate } from "./../../Scenes/common";
import moment from "moment";
import { triggerMixpanelEvent } from "./../../Scenes/common";
import { ReactComponent as SearchIcon } from "./../../assets/SearchIcon.svg";
import { isMobile } from "react-device-detect";

import "./css/AuctionCalendar.css";

const AuctionCalendar = () => {
  const [tabKey, setTabKey] = useState("all");

  const [tabMonths, setTabMonths] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [jsonData, setJsonData] = useState({});
  const [AllCollectionsFull, setAllCollectionsFull] = useState({});
  const [AllCollections, setAllCollections]: any = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLoadMore, setisLoadingLoadMore] = useState(false);
  const [isResultsPage, setisResultsPage] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [maintabKey, setMaintabKey]: any = useState("calender");
  const [subTabKey, setSubTabKey]: any = useState("all");
  const [selectedMonths, setSelectedMonths] = useState(moment().format("MMMM"));
  const [selectedYears, setSelectedYears] = useState(moment().format("YYYY"));
  const [seleletedPageTab, setSelectedPageTab] = useState("calender");
  const [seleletedSubPageTab, setSelectedSubPageTab] = useState("all");
  const [sortColumnChange, setSortColumnChange] = useState("");
  const [pageLoadedState, setPageLoadedState] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedSearchtText, setSelectedSearchtText] = useState(false);
  const [dates, setDates]: any = useState([]);
  const [calenderPageLoadingState, setCalenderPageLoadingState] =
    useState(false);
  const [selectedEvents, setSelectedEvents] = useState([]);

  const [sDate, setSDate]: any = useState();
  const [eDate, setEDate]: any = useState();

  const [status, setStatus]: any = useState("");
  const useQuery = () => {
    return new URLSearchParams(window.location.search);
  };

  let query = useQuery();

  let history = useHistory();

  useEffect(() => {
    if (seleletedPageTab === "calender") {
      history.push(
        `/auction-calendar?selectedTab=${seleletedPageTab}&subTab=${seleletedSubPageTab}`
      );
    } else if (seleletedPageTab === "results") {
      history.push(`/auction-calendar?selectedTab=${seleletedPageTab}`);
    }
  }, [seleletedPageTab, seleletedSubPageTab]);

  useEffect(() => {
    handleUrlLoadingState();
  }, []);

  const handleUrlLoadingState = async () => {
    await handleSettingTab();
    await handleUpdateTab();
  };

  const handleSettingTab = () => {
    let selectedTab = query.get("selectedTab");
    let subTab = query.get("subTab");
    if (selectedTab === "results") {
      setisResultsPage(true);
      setMaintabKey("auction-results");
      setSelectedPageTab("results");
    } else {
      setisResultsPage(false);
      setMaintabKey("calender");
      setSelectedPageTab("calender");
      if (subTab === "active") {
        setSelectedSubPageTab("active");
        setTabKey("Active");
        setSubTabKey("active");
      } else if (subTab === "upcomming") {
        setSelectedSubPageTab("upcomming");
        setTabKey("Upcoming");
        setSubTabKey("upcoming");
      } else {
        setSelectedSubPageTab("all");
        setTabKey("all");
        setSubTabKey("all");
      }
    }
  };

  const handleUpdateTab = () => {
    setPageLoadedState(true);
  };
  // setting the tab

  const handleTab = (key: string) => {
    setStatus(
      key === "Active" ? "active" : key === "Upcoming" ? "upcomming" : ""
    );
    setPageLoadedState(true);
    setSelectedSubPageTab(
      key === "Active" ? "active" : key === "Upcoming" ? "upcomming" : "all"
    );
    setTabKey(key);
  };

  //end
  // get All auctions Api call

  const getAllActions = async (body: any, type: any) => {
    if (type != "viewmore") {
      setIsLoading(true);
    } else {
      setisLoadingLoadMore(true);
    }
    if (isResultsPage) {
    } else {
      const resp = await getallupcommingauctionsCall(body);
      if (resp?.totalCount == 0) {
      }
      setAllCollectionsFull(resp);
      setIsLoading(false);
      setisLoadingLoadMore(false);

      let AllCollectionsP: any = [];

      AllCollectionsP = resp?.auctions;
      if (type == "viewmore") {
        setAllCollections([...AllCollections, ...AllCollectionsP]);
        setIsLoading(false);
      } else {
        setAllCollections(AllCollectionsP);
        setIsLoading(false);
      }
      triggerEventForProfileUpdate(UPDATE_CALENDERDATA);
      updateMonthsDataFilter();
    }
  };

  useEffect(() => {
    if (pageLoadedState) {
      if (seleletedPageTab === "results") {
        const body = {
          page: currentPage,
          pageSize: 18,
          months: [selectedMonths],
          years: [selectedYears],
          ...(searchText && { searchText: searchText }),
        };
        setisResultsPage(true);
        getPastActions(body, "");
        triggerMixpanelEvent("auction_results_page");
      } else {
        setisResultsPage(false);
        const body = {
          startDate: sDate,
          endDate: eDate,
          sortColumn: "EndOnUtc",
          sortDirection: !sortColumnChange ? "asc" : sortColumnChange,
          page: currentPage,
          pageSize: 18,
          ...(searchText && { searchText: searchText }),
          ...((status || seleletedSubPageTab !== "all") && {
            status: status || seleletedSubPageTab,
          }),
          ...(selectedEvents?.length && {
            auctionType: selectedEvents,
          }),
        };
        setJsonData(body);
        getAllActions(body, "");
        triggerMixpanelEvent("auction_calendar_page");
      }
      setPageLoadedState(false);
      document.title = "FAHH";
    }
  }, [
    maintabKey,
    selectedMonths,
    selectedSearchtText,
    selectedYears,
    seleletedPageTab,
    status,
    seleletedSubPageTab,
    pageLoadedState,
  ]);

  useEffect(() => {
    if (calenderPageLoadingState) {
      setIsLoading(true);
      let json = {
        startDate: sDate,
        endDate: eDate,
        sortColumn: "EndOnUtc",
        sortDirection: !sortColumnChange ? "asc" : sortColumnChange,
        page: 1,
        pageSize: 18,
        ...(searchText && { searchText: searchText }),
        ...((status || seleletedSubPageTab !== "all") && {
          status: status || seleletedSubPageTab,
        }),
        ...(selectedEvents?.length && {
          auctionType: selectedEvents,
        }),
      };
      setJsonData(json);
      handleFilterCall(json);
    }
  }, [eDate, sDate, sortColumnChange, calenderPageLoadingState]);

  const handleFilterCall = async (body: any) => {
    const resp = await getallupcommingauctionsCall(body);
    setAllCollections(resp.auctions);
    setAllCollectionsFull(resp);
    setCalenderPageLoadingState(false);
    setIsLoading(false);
  };

  const updateMonthsDataFilter = () => {
    const mth: any = [];
    (AllCollections || []).map((d: any) => {
      mth[moment(d?.auctionStartDate).format("M")] = moment(
        d?.auctionStartDate
      ).format("MMMM");
    });
    setTabMonths(Object.values(mth));
  };

  const clickViewMore = () => {
    setCurrentPage(currentPage + 1);
    const body: any = jsonData;
    body.page = currentPage + 1;
    if (isResultsPage) {
      let jsonBody = {
        page: currentPage + 1,
        pageSize: 18,
        months: [selectedMonths],
        years: [selectedYears],
        searchText: "",
      };
      getPastActions(jsonBody, "viewmore");
    } else {
      getAllActions(body, "viewmore");
    }
  };

  const getPastActions = async (body: any, type: any) => {
    if (type != "viewmore") {
      setIsLoading(true);
    } else {
      setisLoadingLoadMore(true);
    }
    body.sortColumn = "EndOnUtc";
    body.sortDirection = "desc";

    const resp1 = await getPastAuctions(body);
    if (resp1?.totalCount == 0) {
      // this.setState({ errorMsg: true });
    }
    setAllCollectionsFull(resp1);
    setIsLoading(false);
    setisLoadingLoadMore(false);

    if (type == "viewmore") {
      let AllCollectionsP: any = [];
      AllCollectionsP = resp1?.pastAuctions;

      setAllCollections([...AllCollections, ...AllCollectionsP]);

      triggerEventForProfileUpdate(UPDATE_CALENDERDATA);
      updateMonthsDataFilter();
    } else {
      let AllCollectionsP: any = [];
      AllCollectionsP = resp1?.pastAuctions;
      setAllCollections(AllCollectionsP);

      triggerEventForProfileUpdate(UPDATE_CALENDERDATA);
      updateMonthsDataFilter();
    }
  };

  const handleClearAllCalenderFilter = () => {
    setSortColumnChange("");
    setEDate("");
    setSDate("");
    setDates([]);
    setSelectedEvents([]);
  };

  const handleClearAll = () => {
    setSelectedMonths(moment().format("MMMM"));
    setSelectedYears(moment().format("YYYY"));
  };

  return (
    <>
      {errorMsg ? (
        <p className="noResults noResults-auctionpafw" id="errorPage">
          No results are available in{" "}
          {isResultsPage ? "Auction Results" : "Auction Calendar"}
          {". "}
        </p>
      ) : (
        <div className="action_calendar_page_content">
          <TopBannerSlider />

          <div className="action_calendar_page_section">
            <Container className="position-relative">
              <h3 className="mb-0 position-relative pl-3">
                <span className="d-md-inline-block calender-bar d-none">
                  Auction
                </span>{" "}
                {isResultsPage ? "Results" : "Calendar"}
              </h3>

              <Tabs
                id="uncontrolled-tab-example"
                className="mb-3 mt-4 auction-calender-tabs"
                activeKey={maintabKey}
                onSelect={(k) => {
                  setMaintabKey(k);
                  if (k === "auction-results") {
                    setSelectedPageTab("results");
                    setPageLoadedState(true);
                    setisResultsPage(true);
                    setCurrentPage(1);
                    handleClearAllCalenderFilter();
                    setSearchText("");
                  } else {
                    setSelectedPageTab("calender");
                    setisResultsPage(false);
                    setPageLoadedState(true);
                    setCurrentPage(1);
                    handleClearAll();
                    setSearchText("");
                  }
                }}
              >
                <Tab eventKey="calender" title="Calendar"></Tab>
                <Tab eventKey="auction-results" title="Auction Results"></Tab>
              </Tabs>

              <div
                className={`searchBox ${
                  isResultsPage ? "results-search-box" : "calender-search-box"
                }`}
              >
                <Form.Control
                  type="text"
                  onChange={(e: any) => {
                    if (!e.target.value) {
                      setSelectedSearchtText(!selectedSearchtText);
                      setPageLoadedState(true);
                    }
                    setSearchText(e.target.value);
                  }}
                  value={searchText}
                  placeholder="Search"
                  className="searchBoxTextField"
                />
                <span className="iconBox">
                  <SearchIcon
                    onClick={() => {
                      if (searchText && !isLoading) {
                        setSelectedSearchtText(!selectedSearchtText);
                        setPageLoadedState(true);
                      }
                    }}
                    className="searchBoxIcon"
                  />
                </span>
              </div>

              {isMobile && !isResultsPage && (
                <Tabs
                  id="uncontrolled-tab-example"
                  className="mt-4 auction-calender-tabs calender-subtabs"
                  activeKey={subTabKey}
                  onSelect={(k) => {
                    setSubTabKey(k);
                    if (k === "active") {
                      setStatus("active");
                      setPageLoadedState(true);
                    } else if (k === "upcoming") {
                      setStatus("upcomming");
                      setPageLoadedState(true);
                    } else {
                      setStatus("");
                      setPageLoadedState(true);
                    }
                    setIsLoading(true);
                  }}
                >
                  <Tab eventKey="all" title="All"></Tab>
                  <Tab eventKey="active" title="Active"></Tab>
                  <Tab eventKey="upcoming" title="Upcoming"></Tab>
                </Tabs>
              )}

              <Row>
                <Col
                  lg={3}
                  className={`${!isMobile ? "sticky-calender-filter-sec" : ""}`}
                >
                  <ActionCalendarLeftFilter
                    handleTab={handleTab}
                    tabMonths={tabMonths}
                    AllCollections={AllCollections}
                    AllCollectionsFull={AllCollectionsFull}
                    isResultsPage={isResultsPage}
                    setSelectedMonths={setSelectedMonths}
                    selectedMonths={selectedMonths}
                    selectedYears={selectedYears}
                    setSelectedYears={setSelectedYears}
                    setSDate={setSDate}
                    setEDate={setEDate}
                    sDate={sDate}
                    eDate={eDate}
                    isLoading={isLoading}
                    setSortColumnChange={setSortColumnChange}
                    sortColumnChange={sortColumnChange}
                    setPageLoadedState={setPageLoadedState}
                    setDates={setDates}
                    dates={dates}
                    setIsLoading={setIsLoading}
                    setCurrentPage={setCurrentPage}
                    setCalenderPageLoadingState={setCalenderPageLoadingState}
                    setSelectedEvents={setSelectedEvents}
                    selectedEvents={selectedEvents}
                  />
                </Col>
                <Col lg={9}>
                  <ActionCalendarTabMenu
                    isLoading={isLoading}
                    isLoadingLoadMore={isLoadingLoadMore}
                    tabKey={tabKey}
                    handleTab={handleTab}
                    AllCollections={AllCollections}
                    AllCollectionsFull={AllCollectionsFull}
                    clickViewMore={clickViewMore}
                    isResultsPage={isResultsPage}
                    selectedMonths={selectedMonths}
                    selectedYears={selectedYears}
                    setSelectedYears={setSelectedYears}
                    setSelectedMonths={setSelectedMonths}
                    setStatus={setStatus}
                    setCurrentPage={setCurrentPage}
                    setPageLoadedState={setPageLoadedState}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}
    </>
  );
};

export default AuctionCalendar;
