import moment from "moment";
import { useEffect, useState } from "react";

const BiddingStartingComp = ({
  sTime,
  sDate,
  bannerTimeZone,
  formPage,
  startDate,
  collection,
}: any) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [showDate, setShowDate] = useState(true); // Whether to show the event date
  const [isEventOver, setIsEventOver] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Calculate the difference between event time and current time in days and hours

  const calculateTimeLeft = () => {
    setIsLoading(true);
    // Parse sDate as a full date-time and override time with aTime
    if (startDate) {
      const formatedTime: any = moment.utc(startDate).local().format();

      const eventDateTime = moment(formatedTime);

      const currentTime = moment(); // Get the current time

      // Calculate time differences
      const diffInSeconds = eventDateTime?.diff(currentTime, "seconds");
      const diffInDays = Math.floor(diffInSeconds / (60 * 60 * 24));
      const diffInHours = Math.floor(
        (diffInSeconds % (60 * 60 * 24)) / (60 * 60)
      );
      const diffInMinutes = Math.floor((diffInSeconds % (60 * 60)) / 60);
      const remainingSeconds = diffInSeconds % 60;

      // Return the calculated time left
      return {
        days: diffInDays,
        hours: String(diffInHours).padStart(2, "0"),
        minutes: diffInMinutes,
        seconds: remainingSeconds,
      };
    }
  };

  // Set interval to update countdown every second
  useEffect(() => {
    const timeRemaining: any = calculateTimeLeft();
    if (timeRemaining.days === 0) {
      const timer = setInterval(() => {
        const updatedTimeRemaining: any = calculateTimeLeft();
        setTimeLeft(updatedTimeRemaining);

        // Determine if we should show the date or the countdown
        if (updatedTimeRemaining.days < 6) {
          setShowDate(false); // Less than 6 days, show countdown
        }
        if (
          updatedTimeRemaining.days === 0 &&
          updatedTimeRemaining.hours === "00" &&
          updatedTimeRemaining.minutes === 0 &&
          updatedTimeRemaining.seconds === 0
        ) {
          setIsEventOver(true);
          clearInterval(timer); // Stop the timer once the event has started
        } else {
          setIsEventOver(false);
        }
        setIsLoading(false);

        console.log(updatedTimeRemaining, "timeLeft");
      }, 1000);

      // Cleanup the interval when the component is unmounted
      return () => clearInterval(timer);
    } else {
      setTimeLeft(timeRemaining);
      if (timeRemaining.days >= 6) {
        setShowDate(true);
      } else {
        setShowDate(false);
      }
      setIsLoading(false);
    }
  }, [sDate, sTime, startDate, collection]);

  if (isEventOver) {
    window.location.reload();
    return <p></p>;
  }

  return (
    <>
      {!isLoading ? (
        <p className={`${formPage === "topbanner" ? "d-flex" : ""}`}>
          {showDate && formPage === "topbanner" && <p>Starts At: </p>}
          {formPage === "lotpage" && <p>Starts At: </p>}

          {showDate ? (
            <p className="starts-date">
              {/* Show the event start date if more than 6 days away */}
              {moment(sDate).format("MMMM D, YYYY")} (
              {moment(sTime, "HHmmss").format("h:mm A")}{" "}
              {bannerTimeZone === "EST" ? "ET" : bannerTimeZone})
            </p>
          ) : timeLeft.days >= 1 ? (
            <>
              {/* Show the countdown in days if between 1 and 6 days left */}
              <div className="info-text">
                {timeLeft.days} {timeLeft.days > 1 ? "Days" : "Day"} Until
                Bidding Starts
              </div>
            </>
          ) : timeLeft.hours < 1 ? (
            <>
              {/* Show the countdown in hours, minutes, and seconds if less than 24 hours left */}
              <div className="info-text">
                <span className="danger-text-info">
                  {timeLeft.hours < 1 ? "00" : timeLeft.hours}:
                  {timeLeft.minutes < 10
                    ? `0${timeLeft.minutes}`
                    : timeLeft.minutes}
                  :
                  {timeLeft.seconds < 10
                    ? `0${timeLeft.seconds}`
                    : timeLeft.seconds}{" "}
                </span>
                Until Bidding Starts
              </div>
            </>
          ) : (
            <>
              {/* Show the countdown in hours, minutes, and seconds if less than 24 hours left */}
              <div className="info-text">
                {timeLeft.hours < 1 ? "00" : timeLeft.hours}:
                {timeLeft.minutes < 10
                  ? `0${timeLeft.minutes}`
                  : timeLeft.minutes}
                :
                {timeLeft.seconds < 10
                  ? `0${timeLeft.seconds}`
                  : timeLeft.seconds}{" "}
                Until Bidding Starts
              </div>
            </>
          )}
        </p>
      ) : null}
      {formPage === "topbanner" && (
        <>
          {!showDate && (
            <p className="mt-2">
              {moment(sDate).format("MMMM D, YYYY")} {"|"}{" "}
              {moment(sTime, "HHmmss").format("h:mm A")}{" "}
              {bannerTimeZone === "EST" ? "ET" : bannerTimeZone}
            </p>
          )}
          <div className="lot-info-text">
            Lots close independently at one-minute intervals; closure times are
            subject to change
          </div>
        </>
      )}
    </>
  );
};

export default BiddingStartingComp;
