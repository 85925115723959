import React, { useState, useEffect } from "react";
import moment from "moment";
import { LOGINID } from "./../../Scenes/common";

import CountdownTimer from "./../../components/Counter/CountdownTimer";
import {
  convertUTCToLocaltime,
  convertUTCToLMilleSec,
  convertLMilleSec,
} from "./../../Scenes/common";
import AuctionClosed from "./AuctionClosed";
import BiddingStartingComp from "./BiddingStartsComp";

const TopBannerTime = (props: any) => {
  const { collectionData, bannerTimeZone, handleLoginBtn } = props;

  const [collectionData1, setCollectionData1] = useState({} as any);

  const [isClosed, setIsClosed] = useState(false);

  useEffect(() => {
    if (collectionData?.id !== collectionData1?.id && collectionData?.id) {
      setCollectionData1(collectionData);
    }
  }, [collectionData?.id]);
  useEffect(() => {
    updateShowLiveActionBtn();
  }, [collectionData1]);
  useEffect(() => {
    console.log("collectionData?.endOnUtc", collectionData1?.endOnUtc);
    updateShowLiveActionBtn();
  }, [collectionData1?.endOnUtc]);

  const updateShowLiveActionBtn = () => {};

  const targetDate = convertUTCToLocaltime(
    collectionData1?.liveEndDate
  ).toString();
  const collSLocalTim = convertUTCToLMilleSec(collectionData1?.startOnUtc);
  const collELocalTim = convertUTCToLMilleSec(collectionData1?.endOnUtc);
  const originCollEndTime = convertUTCToLMilleSec(collectionData1?.liveEndDate);
  const currentTime = convertLMilleSec();
  const currentTime_plus_20 = currentTime + 432000000;
  const sDate =
    collectionData?.auctionType === 2
      ? collectionData?.liveStartDate
      : collectionData?.onlineStartDate;
  const sTime =
    collectionData.auctionType === 2
      ? collectionData?.liveStartTime
      : collectionData?.onlineStartTime;

  return (
    <>
      {currentTime < collSLocalTim ? (
        <p>
          <BiddingStartingComp
            sTime={sTime}
            sDate={sDate}
            bannerTimeZone={bannerTimeZone}
            formPage="topbanner"
            startDate={collectionData1?.startOnUtc}
            collection={collectionData}
          />
        </p>
      ) : currentTime_plus_20 < originCollEndTime ? (
        <>
          <div className="auction-month-text">
            <p>Lots Begin Closing:</p>
            {moment(collectionData?.onlineEndDate).format("MMMM D, YYYY")} {"("}
            {moment(collectionData?.onlineEndTime, "HHmmss").format(
              "h:mm A"
            )}{" "}
            {bannerTimeZone === "EST" ? "ET" : bannerTimeZone}
            {")"}
            <p></p>
          </div>
          <div className="lot-info-text">
            Lots close independently at one-minute intervals; closure times are
            subject to change
          </div>
        </>
      ) : currentTime < originCollEndTime ? (
        <>
          <p>
            {collectionData1?.liveEndDate && (
              <CountdownTimer
                targetDate={targetDate}
                showExtraText={true}
                formPage="collection"
                updateIsExpiredButton={() => {
                  setIsClosed(true);
                }}
                bannerTimeZone={bannerTimeZone}
                showProgressBarsec={() => {}}
              />
            )}
            {!isClosed && (
              <>
                <p className="mt-2">
                  {moment(collectionData?.onlineEndDate).format("MMMM D, YYYY")}{" "}
                  {"|"}{" "}
                  {moment(collectionData?.onlineEndTime, "HHmmss").format(
                    "h:mm A"
                  )}{" "}
                  {bannerTimeZone === "EST" ? "ET" : bannerTimeZone}
                </p>
                <div className="lot-info-text">
                  Lots close independently at one-minute intervals; closure
                  times are subject to change
                </div>
              </>
            )}
          </p>
        </>
      ) : currentTime < collELocalTim ? (
        <>
          <p>
            {collectionData1?.liveEndDate && (
              <AuctionClosed collELocalTim={collELocalTim} />
            )}
          </p>
        </>
      ) : currentTime > collELocalTim ? (
        <>
          <p>Auction Closed</p>
          {!LOGINID && (
            <div className="login-view-results-top">
              <button onClick={handleLoginBtn}>Log in to view Results</button>
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default TopBannerTime;
