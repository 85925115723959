import { Button, Modal } from "react-bootstrap";
import { ReactComponent as Close } from "../../assets/close-icon.svg";
import { LopinPopUpPage } from "../../actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const LoginNewModal = (props: any) => {
  const { show, onhide, toPage, collectionId } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const handleRedirect = () => {
    if (toPage === "active-auction") {
      history.push("sign-up?returnUrl=/&showPopUp=true");
    } else if (toPage === "upcoming-auction") {
      history.push("sign-up?returnUrl=/&showPopUp=true");
    } else if (toPage === "top-banner") {
      window.location.href = `/sign-up?returnUrl=/collectionDetails/${collectionId?.id}&showPopUp=true`;
    }
  };
  return (
    <>
      <Modal show={show} centered className="login-new-modal">
        <Modal.Header>
          Please Log In
          <Close onClick={() => onhide(false)} />
        </Modal.Header>
        <Modal.Body>
          <p className="login-modal-text">
            In order to register for this auction you need to log in to your
            account
          </p>
        </Modal.Body>
        <Modal.Footer className="login-modal-footer">
          <Button
            className="login-butn"
            onClick={() => {
              dispatch(LopinPopUpPage(true));
              onhide(false);
            }}
          >
            Log In
          </Button>
          <Button
            className="signup-btn"
            onClick={() => {
              handleRedirect();
            }}
          >
            Sign up
          </Button>
          <Button className="not-now-btn" onClick={() => onhide(false)}>
            Not now
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LoginNewModal;
