import React, { useEffect, useState } from "react";
import MyBidCardRow from "./MyBidCardRow";
import { MyActiveBidsStyled } from "./MyActiveBids.styled";
import { getAllActiveBids } from "../../library/Requests";
import {
  autobidCallBroadCast,
  quickbidCallBroadCast,
} from "./../../library/Requests/PlaceBid";
import { getbidincrementCall } from "./../../library/Requests";
import CustomModal from "./../../components/CustomModel";
import Loader from "./../../components/Common/Loader";
import { triggerMixpanelEvent } from "./../../Scenes/common";

const MyActiveBids = (props: any) => {
  const {
    collectionData,
    userDetails,
    bannerTimeZone,
    currencyName,
    isLoadingParent,
  } = props;
  const [allMyActiveBids, setAllMyActiveBids] = useState([]);
  const [autoBidSuccessText, setAutoBidSuccessText] = useState("");
  const [autoBidSuccessPlaced, setAutoBidSuccessPlaced] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bidIncrementData, setBidIncrementData] = useState([] as any);
  useEffect(() => {
    if (collectionData?.id) {
      setIsLoading(true);
      getAllBids();

      hetDropDownValsData();
    }
  }, [collectionData?.id]);

  const getAllBids = async () => {
    const newJson = {
      CollectionId: collectionData?.id,
      CustomerId: userDetails?.id,
      Page: 1,
      PageSize: 100,
    };

    const respN = await getAllActiveBids(newJson);

    if (respN?.data?.length > 0) {
      setAllMyActiveBids(respN?.data);
    }
    setIsLoading(false);
  };
  const hetDropDownValsData = async () => {
    const jsonVal = await getbidincrementCall();
    setBidIncrementData(jsonVal?.increment);
  };
  const placeQuickBidCall = async (body: any) => {
    setIsLoading(true);
    triggerMixpanelEvent("quick_bid_click", {
      lotId: body?.itemId,
      myActiveBids: true,
    });
    const resp = await quickbidCallBroadCast(body);
    setIsLoading(false);
    if (resp?.success) {
      setAutoBidSuccessPlaced(true);
      setAutoBidSuccessText("Your Bid has been successfully placed");
      triggerMixpanelEvent("quick_bid_success", {
        lotId: body?.itemId,
        myActiveBids: true,
      });
      // getAllBids();
    } else if (resp?.errors.length > 0) {
      setAutoBidSuccessText(resp?.errors[0]);
      setAutoBidSuccessPlaced(true);
      triggerMixpanelEvent("quick_bid_error", {
        lotId: body?.itemId,
        error_message: resp?.errors[0],
        myActiveBids: true,
      });
    }
  };
  const autobidCallBroadCastCall = async (body: any) => {
    setIsLoading(true);
    triggerMixpanelEvent("auto_bid_click", {
      lotId: body?.itemId,
      myActiveBids: true,
    });
    const resp = await autobidCallBroadCast(body);

    if (resp?.success) {
      await triggerMixpanelEvent("auto_bid_success", {
        lotId: body?.itemId,
        myActiveBids: true,
      });

      setAutoBidSuccessPlaced(true);
      setAutoBidSuccessText("Your Bid has been successfully placed");
    } else if (resp?.errors?.length > 0) {
      setAutoBidSuccessText(resp?.errors[0]);
      setAutoBidSuccessPlaced(true);
      triggerMixpanelEvent("auto_bid_error", {
        lotId: body?.itemId,
        error_message: resp?.errors[0],
        myActiveBids: true,
      });
    } else if (resp?.message?.length > 0) {
      setAutoBidSuccessText(resp?.message);
      setAutoBidSuccessPlaced(true);
      triggerMixpanelEvent("auto_bid_error", {
        lotId: body?.itemId,
        error_message: resp?.message,
        myActiveBids: true,
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      <MyActiveBidsStyled>
        <div className="my_active_bids_sec">
          {(isLoading || props?.isLoadingParent) && <Loader />}

          <CustomModal
            show={autoBidSuccessPlaced}
            titleText={autoBidSuccessText}
            className={"edit-address-modal"}
            deleteText="OK"
            cancelText=""
            onCancel={() => {}}
            onDelete={() => {
              setAutoBidSuccessPlaced(false);
            }}
          />

          {!isLoading && !isLoadingParent && (
            <div className="my-active-bids-list filter_list_data_column_row">
              {allMyActiveBids?.length > 0 ? (
                <div className="my-active-bids-list-con-row">
                  {allMyActiveBids?.map((myLot: any, index: any) => {
                    return (
                      <>
                        {myLot?.outFromBid && (
                          <MyBidCardRow
                            myLot={myLot}
                            bannerTimeZone={bannerTimeZone}
                            currencyName={currencyName}
                            userDetails={userDetails}
                            placeQuickBidCall={placeQuickBidCall}
                            autobidCallBroadCastCall={autobidCallBroadCastCall}
                            bidIncrementData={bidIncrementData}
                            youHaveHighestBid={false}
                            collectionData={collectionData}
                          />
                        )}
                      </>
                    );
                  })}
                  {allMyActiveBids?.map((myLot: any) => {
                    return (
                      <>
                        {!myLot?.outFromBid && (
                          <MyBidCardRow
                            myLot={myLot}
                            bannerTimeZone={bannerTimeZone}
                            currencyName={currencyName}
                            userDetails={userDetails}
                            placeQuickBidCall={placeQuickBidCall}
                            autobidCallBroadCastCall={autobidCallBroadCastCall}
                            bidIncrementData={bidIncrementData}
                            youHaveHighestBid={true}
                            collectionData={collectionData}
                          />
                        )}
                      </>
                    );
                  })}
                </div>
              ) : (
                <div className="my-no-active-bids">
                  You don’t have any Active Bids in this auction
                </div>
              )}
            </div>
          )}
        </div>
      </MyActiveBidsStyled>{" "}
    </>
  );
};
export default MyActiveBids;
