import { Button, Modal } from "react-bootstrap";
import styled from "styled-components";

const SuccessModal = ({ successModal, setSuccessModal }: any) => {
  return (
    <>
      <Modal show={successModal} centered onHide={() => setSuccessModal(false)}>
        <SuccessModalStyled>
          <Modal.Body className="success-modal-body">
            <p>
              Thank you for your inquiry. A representative from customer service
              will respond shortly.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setSuccessModal(false)}>Okay</Button>
          </Modal.Footer>
        </SuccessModalStyled>
      </Modal>
    </>
  );
};

export default SuccessModal;

export const SuccessModalStyled = styled.div`
  .success-modal-body {
    p {
      color: #000;
      text-align: center;
      font-family: "BentonSansRegular";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
    padding: 24px 36px;
  }
  button {
    background: #000;
    min-width: 120px;
    border-color: #000;
    font-family: "BentonSansMedium";
  }
  button:hover,
  button:active,
  button:focus {
    background: #000;
    min-width: 120px;
    border-color: #000;
  }
`;
