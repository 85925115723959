import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Row, Col } from "react-bootstrap";
import "./css/ActionCalendarTabContent.css";
import moment from "moment";
import noImage from "../../assets/image/noImage.jpg";
import { isMobile } from "react-device-detect";
import ContentLoaderSection from "../../components/ContentLoaderSection/ContentLoaderSection";
import { convertSelectedCountryCurrency } from "../../utils";
import { useSelector } from "react-redux";

const ActionCalendarTabContent = (props: any) => {
  const {
    selectedTab,
    listDetails,
    listDetailsNew,
    isLoading,
    allCollFull,
    isResultsPage,
  } = props;

  const { selectedSecondaryCurrency } = useSelector((state: any) => state);

  const handleCheckLocalTime = (time: any) => {
    if (time) {
      // Assume the auctionStartDate is coming in ET (Eastern Time)
      const auctionStartLocal = moment
        .utc(time)
        .local()
        .format("YYYY-MM-DD HH:mm:ss a");

      const currentLocalTime = moment().format("YYYY-MM-DD HH:mm:ss a");

      // Compare the local times to check if the auction has started

      if (currentLocalTime > auctionStartLocal) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  console.log(selectedSecondaryCurrency, "selectedSecondaryCurrency");
  return (
    <>
      {/* <h6 className="d-md-block d-none calender-month-text">
          {selectedMonth} {selectedYear}
        </h6> */}
      {listDetails?.length === 0 && !isLoading && (
        <p className="noResults noResults-auctionpafw" id="errorPage">
          No calendar results
          <span>Please change your filter options</span>
        </p>
      )}
      <div className="filter_list_data_column">
        {!isLoading && (
          <>
            {(listDetails || []).map((i2: any, index: any) => {
              console.log("i2i2i2i2i2", i2);
              // let d: Date = new Date(i2.auctionStartDate);
              // let d1: Date = new Date(i2.auctionEndTime);
              // let t: Date = new Date(i2.auctionEndTime.split('T').join(' ').split('Z')[0]);
              return (
                <div
                  key={index}
                  className="data_column_box data_column_box_list_view m-0 position-relative"
                >
                  {!isLoading && (
                    <div className="date-wrap position-absolute bg-white text-center d-md-block d-flex">
                      <span
                        className={
                          !isMobile
                            ? "d-block dateLeft"
                            : "d-block mobile-date-text"
                        }
                      >
                        <div>
                          <div className="single-month-calender-text">
                            {moment(i2?.auctionStartDate).format("MMM") ==
                              moment(i2?.auctionEndDate).format("MMM") &&
                              moment(i2?.auctionStartDate).format("MMMM")}
                          </div>

                          {moment(i2?.auctionStartDate).format("MMM") ==
                          moment(i2?.auctionEndDate).format("MMM") ? (
                            <>
                              {moment(i2?.auctionStartDate).format("D ")}

                              {i2?.auctionEndDate &&
                                moment(i2?.auctionStartDate).format("D") !=
                                  moment(i2?.auctionEndDate).format("D") && (
                                  <>
                                    - {moment(i2?.auctionEndDate).format("D")}
                                  </>
                                )}
                            </>
                          ) : (
                            <div>
                              <div>
                                {moment(i2?.auctionStartDate).format("MMMM D")}
                              </div>
                              <span> - </span>
                              <div>
                                {i2?.auctionEndDate && (
                                  <>
                                    {moment(i2?.auctionEndDate).format(
                                      "MMMM D"
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </span>
                      {/* <span className="d-block">{moment(i2?.auctionStartDate).format("MMM")}</span> */}
                    </div>
                  )}

                  {!isLoading && (
                    <Row className="list_view_cont">
                      <Col lg={5} className="lots_slider_img lot-page-border">
                        <div className="list_view_img overflow-hiidden">
                          <Link
                            to={`/collectionDetails/${i2?.id}?tab=${selectedTab}`}
                          >
                            <img
                              // (i2?.coverImage !="string" || i2?.coverImage != null || i2?.coverImage != undefined || i2?.coverImage !="")
                              src={
                                i2?.coverImage != "string" ||
                                i2?.coverImage != null ||
                                i2?.coverImage != undefined ||
                                i2?.coverImage != ""
                                  ? i2?.coverImage
                                  : noImage
                              }
                              className="w-100"
                              alt="filter_list_img"
                            />
                          </Link>
                        </div>
                      </Col>
                      <Col
                        lg={7}
                        className="lots_slider_cont_details d-flex flex-column"
                      >
                        <div>
                          {isMobile ? (
                            <ul className="border-list m-0 p-0 ">
                              <li className="d-inline-block position-relative d-inline-block d-md-none">
                                {!isResultsPage
                                  ? i2.auctionType === "Online Auction"
                                    ? "Online Only Auction"
                                    : "Online Auction"
                                  : "Past Auction"}
                              </li>{" "}
                              {"|"}{" "}
                              <span className="auction-aid-text">{`AID ${i2?.id}`}</span>
                            </ul>
                          ) : (
                            ""
                          )}
                          <h6 className="d-md-block d-none">
                            {!isResultsPage
                              ? i2.auctionType === "Online Auction"
                                ? "Online Only Auction"
                                : "Online Auction"
                              : "Past Auction"}
                            <span className="dash-line-auction-aid">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="2"
                                height="12"
                                viewBox="0 0 2 12"
                                fill="none"
                              >
                                <path
                                  d="M1 0.5L1 11.5"
                                  stroke="black"
                                  stroke-opacity="0.12"
                                  stroke-linecap="round"
                                />
                              </svg>
                            </span>
                            <span className="auction-aid-text">{`AID ${i2?.id}`}</span>
                          </h6>

                          <h4 className="forMobileViewstyle mb-2 pb-1">
                            {i2.title}
                          </h4>

                          {handleCheckLocalTime(i2?.startOnUtc) &&
                            !isResultsPage && (
                              <div className="bidding-open-text">
                                Bidding Open
                              </div>
                            )}
                          {!handleCheckLocalTime(i2?.startOnUtc) &&
                          !isResultsPage ? (
                            <div className="bidding-not-started">
                              Bidding Not Started
                            </div>
                          ) : null}
                          {isResultsPage ? (
                            <div className="bidding-not-started">
                              Auction Closed
                            </div>
                          ) : null}
                          {isResultsPage && i2?.saleAmount !== 0 && (
                            <>
                              <div className="sale-amount-text">
                                <div className="mb-1">
                                  SALE TOTAL: {i2?.saleAmount.toLocaleString()}{" "}
                                  USD
                                </div>
                              </div>
                              <div className="sale-amount-text green-color-amount-text calender-text-green-text">
                                {convertSelectedCountryCurrency(
                                  i2?.saleAmount,
                                  selectedSecondaryCurrency
                                )}{" "}
                                {selectedSecondaryCurrency?.base_code !==
                                  "USD" && selectedSecondaryCurrency?.base_code}
                              </div>
                            </>
                          )}

                          {isMobile ? (
                            ""
                          ) : (
                            <>
                              <ul className="border-list m-0 p-0 forMobileViewstyle">
                                <li className="d-inline-block calender-date-text position-relative">
                                  <span
                                    className={
                                      handleCheckLocalTime(i2?.startOnUtc) &&
                                      !isResultsPage
                                        ? `state-date-time-bold1${isResultsPage}`
                                        : `state-date-time-bold${isResultsPage}`
                                    }
                                  >
                                    {!i2?.auctionEndDate
                                      ? moment(i2?.auctionStartDate).format(
                                          "MMMM D, YYYY"
                                        )
                                      : moment(i2?.auctionStartDate).format(
                                          "MMMM D "
                                        )}
                                  </span>
                                  <span
                                    className={
                                      !handleCheckLocalTime(i2?.startOnUtc) &&
                                      !isResultsPage
                                        ? `state-date-time-bold1${isResultsPage}`
                                        : `state-date-time-bold${isResultsPage}`
                                    }
                                  >
                                    {i2?.auctionEndDate && (
                                      <>
                                        {` `}-{" "}
                                        {moment(i2?.auctionEndDate).format(
                                          "MMMM D, YYYY"
                                        )}
                                      </>
                                    )}
                                  </span>
                                </li>
                              </ul>
                              <ul className="border-list m-0 p-0 forMobileViewstyle">
                                {i2?.auctionStartTime && (
                                  <>
                                    <li className="d-inline-block position-relative">
                                      {isResultsPage ? (
                                        <span className="bidding-not-started1">
                                          Auction Closed {` `}
                                        </span>
                                      ) : !handleCheckLocalTime(
                                          i2?.startOnUtc
                                        ) && !isResultsPage ? (
                                        <span className="bidding-not-started1">
                                          BIDDING STARTS {` `}
                                        </span>
                                      ) : handleCheckLocalTime(
                                          i2?.startOnUtc
                                        ) && !isResultsPage ? (
                                        <span className="bidding-not-started1">
                                          LOTS CLOSING {` `}
                                        </span>
                                      ) : null}
                                      {!isResultsPage ? (
                                        <>
                                          {handleCheckLocalTime(
                                            i2?.startOnUtc
                                          ) ? (
                                            <span className="">
                                              {moment
                                                .utc(i2?.endsOnUtc)
                                                .tz("America/New_York")
                                                .format("h:mm A")}{" "}
                                              {"ET"}
                                            </span>
                                          ) : (
                                            <span className="">
                                              {moment
                                                .utc(i2?.startOnUtc)
                                                .tz("America/New_York")
                                                .format("h:mm A")}{" "}
                                              {"ET"}
                                            </span>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <span className="">
                                            {moment
                                              .utc(i2?.endsOnUtc)
                                              .tz("America/New_York")
                                              .format("h:mm A")}{" "}
                                            {"ET"}
                                          </span>
                                        </>
                                      )}
                                    </li>
                                  </>
                                )}

                                <li className="d-inline-block position-relative d-inline-block d-md-none">
                                  {i2.type} Auction
                                </li>
                              </ul>
                            </>
                          )}
                          {isMobile && (
                            <>
                              <ul className="mobile-calender-il">
                                {" "}
                                <li className="d-inline-block calender-date-text position-relative">
                                  <span
                                    className={
                                      handleCheckLocalTime(i2?.startOnUtc) &&
                                      !isResultsPage
                                        ? `state-date-time-bold1${isResultsPage}`
                                        : `state-date-time-bold${isResultsPage}`
                                    }
                                  >
                                    {!i2?.auctionEndDate
                                      ? moment(i2?.auctionStartDate).format(
                                          "MMMM D, YYYY"
                                        )
                                      : moment(i2?.auctionStartDate).format(
                                          "MMMM D "
                                        )}
                                  </span>
                                  <span
                                    className={
                                      !handleCheckLocalTime(i2?.startOnUtc) &&
                                      !isResultsPage
                                        ? `state-date-time-bold1${isResultsPage}`
                                        : `state-date-time-bold${isResultsPage}`
                                    }
                                  >
                                    {i2?.auctionEndDate && (
                                      <>
                                        {` `}-{" "}
                                        {moment(i2?.auctionEndDate).format(
                                          "MMMM D, YYYY"
                                        )}
                                      </>
                                    )}
                                  </span>
                                </li>{" "}
                                {i2?.auctionStartTime && (
                                  <>
                                    {" "}
                                    <ul className="mobile-calender-il">
                                      <li className="d-inline-block calender-date-text position-relative">
                                        {isResultsPage ? (
                                          <span className="bidding-not-started1">
                                            Auction Closed {` `}
                                          </span>
                                        ) : !handleCheckLocalTime(
                                            i2?.startOnUtc
                                          ) && !isResultsPage ? (
                                          <span className="bidding-not-started1">
                                            BIDDING STARTS {` `}
                                          </span>
                                        ) : handleCheckLocalTime(
                                            i2?.startOnUtc
                                          ) && !isResultsPage ? (
                                          <span className="bidding-not-started1">
                                            LOTS CLOSING {` `}
                                          </span>
                                        ) : null}
                                        {!isResultsPage ? (
                                          <>
                                            {handleCheckLocalTime(
                                              i2?.startOnUtc
                                            ) ? (
                                              <span className="">
                                                {moment
                                                  .utc(i2?.endsOnUtc)
                                                  .tz("America/New_York")
                                                  .format("h:mm A")}{" "}
                                                {"ET"}
                                              </span>
                                            ) : (
                                              <span className="">
                                                {moment
                                                  .utc(i2?.startOnUtc)
                                                  .tz("America/New_York")
                                                  .format("h:mm A")}{" "}
                                                {"ET"}
                                              </span>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            <span className="">
                                              {moment
                                                .utc(i2?.endsOnUtc)
                                                .tz("America/New_York")
                                                .format("h:mm A")}{" "}
                                              {"ET"}
                                            </span>
                                          </>
                                        )}
                                      </li>
                                    </ul>
                                  </>
                                )}
                              </ul>
                            </>
                          )}
                        </div>
                        <Link
                          to={`/collectionDetails/${i2?.id}?tab=${selectedTab}`}
                          className="browse_btn btn-solid mt-auto-top text-white text-decoration-none text-center"
                        >
                          {isResultsPage
                            ? "View Results"
                            : !handleCheckLocalTime(i2?.startOnUtc)
                            ? "Preview Auction"
                            : "BROWSE AUCTION"}
                        </Link>
                      </Col>
                    </Row>
                  )}
                </div>
              );
            })}
          </>
        )}

        {isLoading && (
          <div className="spinner_Loading_iconCollectionDetails">
            <ContentLoaderSection section="auctioncalender" />
          </div>
        )}

        {props?.isLoadingLoadMore && (
          <div className="spinner_Loading_icon">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        {!isLoading &&
          !props?.isLoadingLoadMore &&
          allCollFull?.totalCount > listDetailsNew?.length &&
          allCollFull?.totalCount > 18 && (
            <div className="load_more_list_btn text-center">
              <Button
                onClick={props.viewMore}
                className="loadMore classViewMore text-white d-inline-block text-decoration-none"
              >
                LOAD MORE
              </Button>
            </div>
          )}
      </div>
      {/* {!isLoading &&
          !this.props?.isLoadingLoadMore &&
          allCollFull?.totalCount === listDetailsNew?.length &&
          isResultsPage && (
            <div className="calender-bottom-sec">
              <h6
                className="d-flex align-items-center"
                onClick={async (e: any) => {
                  setCurrentPage(1);

                  await setSelectedYears(seleectedpastYear);
                  await setSelectedMonths(seleectedpastMonth);
                  setPageLoadedState(true);
                  handleTop(e);
                }}
              >
                <LeftIcon />
                {seleectedpastMonth} {seleectedpastYear}
              </h6>
              <h6
                className="d-flex align-items-center left-side-callender"
                onClick={async (e: any) => {
                  setCurrentPage(1);
                  await setSelectedYears(String(seleectedupcomingYear));
                  await setSelectedMonths(selectedUpcomingMonth);
                  setPageLoadedState(true);
                  handleTop(e);
                }}
              >
                {selectedUpcomingMonth} {seleectedupcomingYear}
                <LeftIcon />
              </h6>
            </div>
          )} */}
    </>
  );
};

export default ActionCalendarTabContent;
