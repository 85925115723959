import React, { useEffect, useState } from "react";

import { AccountActivationStyled } from "./AccountActivation.styled";
import { useLocation } from "react-router-dom";
import { verifyemailConfirmCall } from "../../library/Requests/MyAccount";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AccountActivation = () => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const query = useQuery();
  const token = query.get("token");
  const guid = query.get("guid");

  useEffect(() => {
    checkAccountValid();
  }, []);

  const checkAccountValid = async () => {
    if (guid) {
      const jsonV = {
        token: token ? token : "",
        guid: guid,
      };
      const newResp = await verifyemailConfirmCall(jsonV);
      console.log("newResp", newResp);
      if (newResp?.success) {
        setShowSuccessMessage(true);
      } else if (newResp?.errors[0]) {
        setErrorMessage(newResp?.errors[0]);
      }
    }
  };
  return (
    <>
      <AccountActivationStyled>
        <div className="Create-New-password-sec">
          <div className="create-header">Account Activation</div>
          {showSuccessMessage && (
            <div className="create-sub-header">
              Your Account has beed verified Please click{" "}
              <a href="/login">here</a> to login.
            </div>
          )}
          <div className="create-sub-header"> {errorMessage}</div>
        </div>
      </AccountActivationStyled>{" "}
    </>
  );
};
export default AccountActivation;
