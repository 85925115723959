import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./css/UpcomingActionSlider.css";
import ItemCard from "./ItemCard";
import { isMobile } from "react-device-detect";
import MobileView from "./MobileView";
import BidRegistrationModal from "../BidRegistrationPopUp/BidRegisrationModal";

const UpcomingActionSlider = (props: any) => {
  const upCommingAuctions = props?.upCommingAuctions;
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [collectionId, setCollectionId]: any = useState({});
  const [registerButon, setRegisterButon] = useState(false);
  const [activeActions, setActiveActions] = useState(upCommingAuctions);

  const responsive = {
    desktop: {
      breakpoint: { max: 1920, min: 1400 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1399, min: 1100 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1.1,
      slidesToSlide: 1,
    },
  };

  const handleCloseModal = () => {
    setShowRegistrationModal(!showRegistrationModal);
  };

  useEffect(() => {
    if (registerButon) {
      // Update the activeActions array
      setActiveActions((prevActions: any) =>
        prevActions.map((item: any) =>
          item.id === collectionId?.id
            ? { ...item, isRegistredToAuction: true }
            : item
        )
      );

      // Reset the registerButton to avoid repeated updates
      setRegisterButon(false);
    }
  }, [registerButon, collectionId]);

  console.log(activeActions, upCommingAuctions, "upCommingAuctions");

  return (
    <>
      {showRegistrationModal && (
        <BidRegistrationModal
          showRegistrationModal={showRegistrationModal}
          setShowRegistrationModal={handleCloseModal}
          collectionId={collectionId}
          toPage={"upcoming-auction"}
          setRegisterButon={setRegisterButon}
        />
      )}
      <div className="custom_action_slider">
        {isMobile ? (
          <MobileView
            upCommingAuctions={activeActions}
            handleSucessafterRegister={props.handleSucessafterRegister}
            setShowRegistrationModal={setShowRegistrationModal}
            setCollectionId={setCollectionId}
          />
        ) : (
          <Carousel
            responsive={responsive}
            removeArrowOnDeviceType={["mobile"]}
          >
            {(activeActions || []).map((item: any, index: any) => {
              return (
                <ItemCard
                  item={item}
                  handleSucessafterRegister={props.handleSucessafterRegister}
                  setShowRegistrationModal={setShowRegistrationModal}
                  setCollectionId={setCollectionId}
                />
              );
            })}
          </Carousel>
        )}
      </div>
    </>
  );
};

export default UpcomingActionSlider;
