import { NumericFormat } from "react-number-format";

export const convertSelectedCountryCurrency = (
  itemCost: any,
  selectedCurrency: any
) => {
  let rateAmount;
  if (selectedCurrency?.base_code && selectedCurrency?.base_code != "USD") {
    rateAmount = selectedCurrency?.conversion_rates
      ? selectedCurrency?.conversion_rates[selectedCurrency?.base_code]
      : 1;

    const result = itemCost * rateAmount;

    console.log(result, "result");

    return result
      ? `${result.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
      : "";
    // <NumericFormat
    //   value={Number(itemCost * rateAmount)?.toFixed(2) || 0}
    //   displayType={"text"}
    //   thousandSeparator=","
    //   prefix={""}
    // />
  } else {
    return "";
  }
};
