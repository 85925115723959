import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import noImage from "../../assets/image/noImage.jpg";
import moment from "moment";
import { CardItemStyled } from "./CardItemStyled.styled";
import bidWhite from "../../assets/image/bid-white.png";
import CustomModal from "../CustomModel";

const CardItem = (props: any) => {
  const { item, setShowRegistrationModal, setCollectionId } = props;

  const history = useHistory();

  const [placeBidHover, setPlaceBidHover] = useState(false);
  const [warningModelPopup, setWarningModelPopup] = useState(false);
  const [userRejectedAddressPopup, setUserRejectedAddressPopup] =
    useState(false);
  const [registerBidSuccess, setRegisterBidSuccess] = useState(false);
  const [errorModelPopup, setErrorModelPopup] = useState(false);
  const [errorTextData, setErrorTextData] = useState("");
  const [userData, setUserData]: any = useState({});

  useEffect(() => {
    let userInfo: any = localStorage.getItem("customerInfoDetails");
    setUserData(JSON.parse(userInfo));
  }, []);

  const gotoUploadPhotoPage = () => {
    let userInfo = localStorage.getItem("customerInfoDetails");
    if (userInfo != undefined && userInfo != null && userInfo != "") {
      const userIn = JSON.parse(userInfo);
      if (!userIn?.billingAddressId) {
        window.location.href = `/sign-up/step2?returnUrl=/collectionDetails/${item.id}`;
      } else {
        window.location.href = `/sign-up/step3?returnUrl=/collectionDetails/${item.id}`;
      }
    }
  };

  return (
    <CardItemStyled>
      <div
        // to={`/collectionDetails/${item.id}`}
        key={item.id}
        className="action_slider_content Collection_action_slider_content text-decoration-none text-dark"
      >
        <div
          className="action_slider_img overflow-hidden"
          onClick={(e: any) => {
            e.stopPropagation();
            history.push(`/collectionDetails/${item.id}`);
          }}
        >
          <img
            src={
              item?.coverImage != "string" ||
              item?.coverImage != null ||
              item?.coverImage != undefined ||
              item?.coverImage != ""
                ? item.coverImage
                : noImage
            }
            className="img-fluid border-0"
            alt="cover-image"
          />
        </div>
        <div className="action_slider_body_content">
          <div className="bidding-text">Bidding Open</div>
          <h3
            className="makers-name"
            title={item?.artistName || "Collection of one Famous Artist"}
          >
            {item?.artistName || "Collection of one Famous..."}
          </h3>
          <h3 className="action_slider_heading home-page-title-coll overflow-hidden">
            {item.title}
          </h3>
          <ul className="d-flex align-items-center pl-0 flex-wrap">
            <li className="action_slider_date_text pr-2 border-0">
              {item?.auctionEndDate ? (
                moment(item?.auctionStartDate).format("D") ===
                moment(item?.auctionEndDate).format("D") ? (
                  <span>
                    {moment(item?.auctionStartDate).format("MMMM D, YYYY")}
                  </span>
                ) : (
                  <>
                    <span className="active-start-date">
                      {moment(item?.auctionStartDate).format("MMMM D")}
                    </span>{" "}
                    {"-"}{" "}
                    <span className="active-end-date">
                      {moment(item?.auctionEndDate).format("MMMM D, YYYY")}
                    </span>
                  </>
                )
              ) : (
                <span>
                  {moment(item?.auctionStartDate).format("MMMM D, YYYY")}
                </span>
              )}
            </li>
          </ul>
          <ul className="d-flex align-items-center pl-0 mb-4 flex-wrap">
            <li className="lot-date-closing-text pl-0">
              Lots Closing{" "}
              {moment
                .utc(item?.endsOnUtc)
                .tz("America/New_York")
                .format("h:mm A")}{" "}
              {item?.timeZoneAbbr === "EST" ? "ET" : item?.timeZoneAbbr}
            </li>
            <li className="lot-date-closing-text pr-0 auction-type-sec">
              <Link
                to={item.actionLink}
                className="text-decoration-none text-dark"
              >
                {item.auctionType === "Online Auction"
                  ? "Online Only Auction"
                  : item.auctionType}
              </Link>
            </li>
          </ul>

          <div className="active-auctions-brow-btns">
            <Link
              to={`/${
                item.auctionType === "Live Auction1"
                  ? `live-auction-landing/${item.id}`
                  : `collectionDetails/${item.id}`
              }`}
              className="active-auction-btn"
            >
              BROWSE AUCTION
            </Link>
            {item?.isRegistredToAuction ? (
              <div className="active-auction-btn registered-button">
                Registered
              </div>
            ) : (
              <div
                onClick={(e: any) => {
                  e.stopPropagation();
                  setShowRegistrationModal(true);
                  setCollectionId(item);
                }}
                id={`registered-button-active-${item?.id}`}
                className="active-auction-btn"
              >
                Register to Bid
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <RegisterToBid
        show={registerToBidPopUp}
        titleText=""
        deleteText="OK"
        cancelText=""
        onCancel={() => {}}
        onRegisterBid={registoBidValue}
      /> */}
      <CustomModal
        show={registerBidSuccess}
        titleText={`You have been successfully registered for the ${
          item?.auctionType === "Online Auction"
            ? "Online Only Auction"
            : "Live Auction"
        }`}
        deleteText="OK"
        cancelText=""
        onCancel={() => {}}
        onDelete={() => {
          setRegisterBidSuccess(false);
        }}
      />
      <CustomModal
        show={errorModelPopup}
        titleText={errorTextData}
        deleteText="OK"
        className={"edit-address-modal"}
        cancelText=""
        longText={true}
        onCancel={() => {}}
        onDelete={() => {
          setErrorModelPopup(false);
        }}
      />
      <CustomModal
        show={warningModelPopup}
        titleText="Submit your identification "
        subTitleText="In order to place a bid on a lot in this auction, please complete FAHH’s verification process."
        deleteText="CONTINUE"
        className={"edit-address-modal"}
        cancelText=""
        onCancel={() => {}}
        onDelete={() => {
          setWarningModelPopup(false);

          gotoUploadPhotoPage();
        }}
      />{" "}
      <CustomModal
        show={userRejectedAddressPopup}
        // titleText="Submit your identification "
        subTitleText="Your ID proof document has been rejected, It appears that there were issues with the provided ID. To proceed, please resubmit a clear and valid ID image."
        deleteText="REUPLOAD"
        className={"edit-address-modal"}
        cancelText="OK"
        showCancelBtn={true}
        onCancel={() => {
          setUserRejectedAddressPopup(false);
        }}
        onDelete={() => {
          setUserRejectedAddressPopup(false);
          gotoUploadPhotoPage();
        }}
      />
    </CardItemStyled>
  );
};
export default CardItem;
